import * as React from 'react';
import { GameItem } from './Game';

export type Action =
  | {
      type: 'SET_GAME_DATA';
      payload: GameItem[];
    }
  | {
      type: 'SET_CURRENT_ITEM';
      payload: number;
    }
  | {
      type: 'SET_ANSWER';
      payload: string;
    }
  | {
      type: 'SET_GAME_OVER';
      payload: boolean;
    }
  | {
      type: 'RESET_GAME';
    };

export type Dispatch = (action: Action) => void;

export type State = {
  gameData: GameItem[];
  gameOver: boolean;
  currentItem: number;
  currentAnswer: string | null;
  maxGameLength: number;
  progress: number;
  score: number;
};

const defaultState: State = {
  gameData: [],
  gameOver: false,
  currentItem: -1,
  currentAnswer: null,
  maxGameLength: 3,
  progress: -1,
  score: 0,
};

/** CONTEXT **/
export const GameContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

/** REDUCERS **/
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_GAME_DATA':
      return {
        ...state,
        gameData: action.payload,
        currentItem: action.payload.length ? 0 : -1,
        progress: 1,
      };

    case 'SET_CURRENT_ITEM': {
      if (state.progress === state.maxGameLength) {
        return { ...state, gameOver: true };
      }

      return {
        ...state,
        currentItem: action.payload,
        currentAnswer: null,
        progress: state.progress + 1,
      };
    }

    case 'SET_ANSWER':
      if (state.currentItem === -1) return { ...state };
      if (state.currentAnswer) return { ...state };

      const isCorrect =
        action.payload ===
        state.gameData[state.currentItem].answer.toLowerCase();

      return {
        ...state,
        currentAnswer: action.payload,
        score: isCorrect ? state.score + 1 : state.score,
      };

    case 'SET_GAME_OVER':
      return { ...state, gameOver: action.payload };

    case 'RESET_GAME':
      return {
        ...state,
        gameData: [],
        gameOver: false,
        currentItem: -1,
        currentAnswer: null,
        maxGameLength: 3,
        progress: 0,
        score: 0,
      };

    default:
      throw new Error(`Unhandled action type: ${(action as Action).type}`);
  }
}

/** PROVIDER **/
export const GameProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  return (
    <GameContext.Provider value={{ state, dispatch }}>
      {children}
    </GameContext.Provider>
  );
};

/** HOOKS */
export function useGame() {
  const context = React.useContext(GameContext);

  if (context === undefined) {
    throw new Error('useGame must be used within a GameProvider');
  }

  return context;
}
