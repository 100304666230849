import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type NavItemProps = {
  isActive?: boolean;
  itemIndex?: number;
  label?: string | number;
} & React.HTMLAttributes<HTMLElement>;

export const NavItem = ({
  className,
  isActive = false,
  itemIndex,
  label,
  style,
  title,
  onClick,
}: NavItemProps) => {
  return (
    <li className={clsx(scss.navItem, className)} style={style}>
      <button
        type="button"
        className={clsx(scss.navBtn, {
          'is-active': isActive,
          ring: !isActive,
        })}
        data-slide={itemIndex}
        onClick={onClick}>
        <span className="visually-hidden">{title}</span>
        {isActive && <span className="visually-hidden">(Current Item)</span>}
        <span className={clsx(scss.navLabel)}>{label}</span>
      </button>
    </li>
  );
};
