// extracted by mini-css-extract-plugin
export var btn_action = "Game-module--btn_action--7BMZN";
export var category = "Game-module--category--t+dCl";
export var categoryWheel = "Game-module--category-wheel--HP4qO";
export var categoryWheelInner = "Game-module--category-wheel-inner--zysd5";
export var categoryWheelItem = "Game-module--category-wheel-item--uNEtf";
export var categoryWheelItemActive = "Game-module--category-wheel-item-active--UXt1y";
export var categoryWheelItemDone = "Game-module--category-wheel-item-done--IWu0+";
export var choiceList = "Game-module--choiceList--Qct7I";
export var console = "Game-module--console--KHjrO";
export var container = "Game-module--container--nRjm+";
export var content = "Game-module--content--s28PQ";
export var controlList = "Game-module--control-list--Hr6q-";
export var cta = "Game-module--cta--Ry4KI";
export var heading = "Game-module--heading--vQGi9";
export var helper = "Game-module--helper--cRhc5";
export var progress = "Game-module--progress--SUlyZ";
export var question = "Game-module--question--iDWO9";
export var screenGame = "Game-module--screen-game--u0a1r";
export var screenGameGrid = "Game-module--screen-game-grid--iuWgE";
export var screenGameGridItem = "Game-module--screen-game-grid-item--TVRJj";
export var screenGameOver = "Game-module--screen-game-over--9UBRA";
export var wrapper = "Game-module--wrapper--HXJBV";