import * as React from 'react';
import { gameDataType } from './Game';

const isBrowser = typeof window !== 'undefined';
const hasDataLayer = isBrowser && window.hasOwnProperty('dataLayer');

// types
type StateType = {
  gameData: Array<gameDataType>;
  gameOver: boolean;
  currentItem: number;
  currentClue: number;
  currentChoice: string | null;
  score: number;
  usedClues: number;
  correctAnswers: number;
};

type ActionType = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

type DispatchType = (action: ActionType) => void;

// default state
const defaultState = {
  gameData: [],
  gameOver: false,
  currentItem: 0,
  currentClue: 0,
  currentChoice: null,
  score: 0,
  usedClues: 0,
  correctAnswers: 0,
};

/** CONTEXT **/
export const GameContext = React.createContext<{
  state: StateType;
  dispatch: DispatchType;
}>({
  state: defaultState,
  dispatch: (action) => {
    throw new Error('Game Provider not found.');
  },
});

// actions
const LOAD_DATA = 'LOAD_DATA';
const GAME_OVER_TRUE = 'GAME_OVER_TRUE';
const NEXT_ITEM = 'NEXT_ITEM';
const NEXT_CLUE = 'NEXT_CLUE';
const SUBMIT_ANSWER = 'SUBMIT_ANSWER';
const RESET_GAME = 'RESET_GAME';

/** REDUCERS **/
const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case LOAD_DATA:
      return {
        ...state,
        gameData: action.payload,
      };

    case GAME_OVER_TRUE:
      return {
        ...state,
        gameOver: true,
      };

    case NEXT_ITEM:
      if (state.currentChoice === null)
        return {
          ...state,
        };

      if (state.currentItem !== state.gameData.length - 1) {
        // GA_TAG_EVENT
        if (isBrowser && hasDataLayer) {
          window.dataLayer.push({
            event: 'Guess The Title Game',
            action: 'CONTINUE',
          });
        }

        return {
          ...state,
          currentItem: state.currentItem + 1,
          currentClue: 0,
          currentChoice: null,
        };
      } else {
        // GA_TAG_EVENT
        if (isBrowser && hasDataLayer) {
          window.dataLayer.push({
            event: 'Guess The Title Game',
            action: 'GAME_OVER',
            score: state.score,
          });
        }

        return {
          ...state,
          currentClue: 0,
          currentChoice: null,
          gameOver: true,
        };
      }

    case NEXT_CLUE:
      if (state.currentChoice) return { ...state };

      if (
        state.currentClue !==
        state.gameData[state.currentItem].clues.length - 1
      ) {
        // GA_TAG_EVENT
        if (isBrowser && hasDataLayer) {
          window.dataLayer.push({
            event: 'Guess The Title Game',
            action: 'NEXT_CLUE',
            prevClue:
              state.gameData[state.currentItem].clues[state.currentClue].clue,
            currentClue:
              state.gameData[state.currentItem].clues[state.currentClue + 1]
                .clue,
          });
        }

        return {
          ...state,
          currentClue: state.currentClue + 1,
          usedClues: state.usedClues + 1,
        };
      }

      return state;

    case SUBMIT_ANSWER:
      if (state.currentChoice) return { ...state };

      // GA_TAG_EVENT
      if (isBrowser && hasDataLayer) {
        window.dataLayer.push({
          event: 'Guess The Title Game',
          action: 'SUBMIT_ANSWER',
          currentClue:
            state.gameData[state.currentItem].clues[state.currentClue].clue,
          choice: action.payload,
          answer: state.gameData[state.currentItem].answer,
        });
      }

      if (action.payload === state.gameData[state.currentItem].answer) {
        const scorePoints = 3;
        const cluesUsed = state.currentClue;
        const totalScoreIncrease = scorePoints - cluesUsed;

        return {
          ...state,
          score: state.score + totalScoreIncrease,
          currentChoice: action.payload,
          correctAnswers: state.correctAnswers + 1,
        };
      } else {
        return {
          ...state,
          currentChoice: action.payload,
        };
      }

    case RESET_GAME:
      // GA_TAG_EVENT
      if (isBrowser && hasDataLayer) {
        window.dataLayer.push({
          event: 'Guess The Title Game',
          action: 'RESET_GAME',
        });
      }

      return {
        ...state,
        gameOver: false,
        currentItem: 0,
        currentClue: 0,
        currentChoice: null,
        score: 0,
        usedClues: 0,
        correctAnswers: 0,
      };

    default:
      return state;
  }
};

/** PROVIDER **/
export const GameProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  return (
    <GameContext.Provider value={{ state, dispatch }}>
      {children}
    </GameContext.Provider>
  );
};
