import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type ExcerptProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
};

export const Excerpt = ({ children, style }: ExcerptProps) => {
  return (
    <p className={clsx(scss.itemExcerpt)} style={style}>
      {children}
    </p>
  );
};
