import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type GenreProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const Genre = ({ children, className, style }: GenreProps) => {
  return (
    <span className={clsx(scss.itemGenre, className)} style={style}>
      {children}
    </span>
  );
};
