// extracted by mini-css-extract-plugin
export var body = "videoOverlay-module--body--6vkDi";
export var close = "videoOverlay-module--close--4eUCq";
export var closeIcon = "videoOverlay-module--close-icon--WAxz1";
export var content = "videoOverlay-module--content--tLBmY";
export var dialog = "videoOverlay-module--dialog--y6m5a";
export var player = "videoOverlay-module--player--WiQJ9";
export var title = "videoOverlay-module--title--BtpgA";
export var videoOverlayDialog = "videoOverlay-module--video-overlay-dialog--Yehsa";
export var videoOverlayPlayer = "videoOverlay-module--video-overlay-player--3vNeK";
export var wrapper = "videoOverlay-module--wrapper--luROJ";