// extracted by mini-css-extract-plugin
export var backgroundImage = "ARContestWinners-module--background-image--KSTpm";
export var headerLogoImage = "ARContestWinners-module--header-logo-image--EoScY";
export var inner = "ARContestWinners-module--inner--TzgQP";
export var innerText = "ARContestWinners-module--inner-text--8vgCc";
export var innerWinners = "ARContestWinners-module--inner-winners--0IaFV";
export var innerWinnersContainer = "ARContestWinners-module--inner-winners-container--5I3F1";
export var innerWinnersGraphic = "ARContestWinners-module--inner-winners-graphic--TF3sK";
export var innerWinnersLocation = "ARContestWinners-module--inner-winners-location--ph75A";
export var innerWinnersName = "ARContestWinners-module--inner-winners-name--IlyGq";
export var innerWinnersWinner = "ARContestWinners-module--inner-winners-winner--qkhqO";
export var noMargin = "ARContestWinners-module--no-margin--7Pfoi";
export var wrapper = "ARContestWinners-module--wrapper--cKRfc";