import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type ItemProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
} & React.HTMLAttributes<HTMLDivElement>;

export const Item = React.forwardRef<HTMLDivElement, ItemProps>(
  ({ children, className, style, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(scss.item, className)}
        style={style}
        {...props}>
        {children}
      </div>
    );
  }
);
