import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { canUseDOM } from 'exenv';
import * as types from 'src/types';
import useView from 'src/hooks/useView';
import { Button } from '../button/button';
import { VideoOverlay } from '../videoOverlay/videoOverlay';
import * as scss from './featureCard.module.scss';
import { getStrapiImageSource } from '../../../utils/strapiDataHelpers';

type FeatureItemGradientStyleColor = {
  colorId: string;
  transparency: number;
  percentage: number;
  gradientColor: { color: string };
};

type FeatureItemGradientStyle = {
  angle: number;
  colors: FeatureItemGradientStyleColor[];
};

export type Align = 'start' | 'end' | 'center' | 'baseline' | 'stretch';

export type FeatureItemType = {
  id: string;
  align?: Align;
  button?: types.ButtonFields;
  excerpt?: types.TextFields;
  image: types.ImageFields;
  logo: types.ImageFields;
  overlayStyle?: types.SizeFields<React.CSSProperties>;
  gradientStyle?: FeatureItemGradientStyle;
  size?: types.Size;
  style?: React.CSSProperties;
  subheadline?: types.TextFields;
  releaseDate?: string;
  releaseDateSubHeadline?: string;
  video?: types.VideoFields;
};

export type Props = {
  item: FeatureItemType;
  className?: string;
  tabIndex?: number;
};

export const FeatureCard = ({ className, item, tabIndex, ...props }: Props) => {
  // states
  const [overlayOpen, setOverlayOpen] = React.useState(false);

  // props
  const {
    button,
    excerpt,
    image,
    logo,
    overlayStyle,
    style,
    subheadline,
    gradientStyle,
    releaseDate = '',
    releaseDateSubHeadline = '',
    // video,
  } = item;

  // transform 'releaseDate' to date object
  const dateRelease = moment(releaseDate);

  // hooks
  const view = useView();

  // title data
  const subheadlineText = dateRelease.isValid()
    ? !dateRelease.isAfter()
      ? releaseDateSubHeadline || 'AVAILABLE NOW'
      : subheadline?.text.replace('{date}', dateRelease.format('M/D')) ||
        `PREMIERES ${dateRelease.format('M/D')}`
    : subheadline?.text || ' ';

  const getGradientAngle = (angle: number) =>
    types.Sizes.sm === view ? 0 : angle;

  const getAllColors = (colors: FeatureItemGradientStyleColor[]) =>
    colors
      .map(
        ({ gradientColor, transparency, percentage }) =>
          `${getColor(gradientColor.color, transparency)} ${percentage}%`
      )
      .join(', ');

  const getColor = (color: string, transparency: number) => {
    const transparencyHex = (
      '0' +
      Math.floor((255 * transparency) / 100)
        .toString(16)
        .toUpperCase()
    ).slice(-2);

    return color + transparencyHex;
  };

  const gradient: { backgroundImage: string } | undefined = gradientStyle && {
    backgroundImage: `linear-gradient(${getGradientAngle(
      gradientStyle.angle
    )}deg, ${getAllColors(gradientStyle.colors)})`,
  };

  const handleVideoOverlay = (e: React.MouseEvent) => {
    e.preventDefault();
    setOverlayOpen(true);
  };

  const renderButton = () => {
    if (types.ButtonTypes.overlay === button?.type) {
      return (
        <Button
          outline={button?.outline}
          tag="button"
          label={button?.text}
          href={button?.link}
          type={button?.type}
          icon={['far', 'play-circle']}
          mode={button?.mode}
          style={button?.style}
          onClick={handleVideoOverlay}
        />
      );
    }

    return (
      <Button
        outline={button?.outline}
        tag="a"
        label={button?.text}
        href={button?.link}
        type={button?.type}
        icon={['far', 'play-circle']}
        mode={button?.mode}
        style={button?.style}
      />
    );
  };

  const renderOverlay = (
    <VideoOverlay
      title={button?.overlayTitle ?? ''}
      url={button?.link ?? ''}
      open={overlayOpen}
      setOpen={setOverlayOpen}
    />
  );

  if (!canUseDOM) return null;

  return (
    <article
      className={clsx(
        scss.card,
        types.Sizes.sm === view
          ? className?.replace('end', 'start')
          : className,
        tabIndex != null && (tabIndex % 2 === 0 ? scss.left : scss.right)
      )}
      style={{
        backgroundImage:
          types.Sizes.lg === view
            ? `url(${getStrapiImageSource(image.src.lg as types.ImageSource)})`
            : '',
        backgroundColor:
          types.Sizes.sm === view && gradient?.backgroundImage
            ? gradientStyle?.colors[0].gradientColor.color
            : undefined,
        ...style,
      }}
      {...props}>
      <div
        className={clsx(scss.overlay)}
        style={
          gradient?.backgroundImage
            ? types.Sizes.sm === view
              ? { backgroundImage: 'none' }
              : gradient
            : overlayStyle && overlayStyle[view]
        }
      />

      {types.Sizes.sm === view && (
        <div className={clsx(scss.head)}>
          <img
            className={clsx(scss.featImg)}
            src={
              getStrapiImageSource(image.src[view] as types.ImageSource) ?? ''
            }
            alt={image.alt ?? ''}
            loading="lazy"
          />
          <div
            className={clsx(scss.overlay)}
            style={
              gradient?.backgroundImage
                ? { height: '100%', ...gradient }
                : overlayStyle && overlayStyle[view]
            }></div>
        </div>
      )}

      <div className={clsx(scss.content)}>
        <img
          className={clsx(
            scss.logo,
            logo?.alt.match(/wider$/) && scss.logowider
          )}
          src={
            getStrapiImageSource(logo?.src[view] as types.ImageSource) ??
            undefined
          }
          alt={logo?.alt}
          loading="lazy"
        />

        <div className={clsx(scss.body)}>
          <h3
            className={clsx(scss.subheadline)}
            style={subheadline?.style}
            dangerouslySetInnerHTML={{ __html: subheadlineText }}
          />

          <p className={clsx(scss.excerpt)} style={excerpt?.style}>
            {excerpt?.text}
          </p>
        </div>

        <div>
          {!!button && renderButton()}
          {types.ButtonTypes.overlay === button?.type && renderOverlay}
        </div>
      </div>
    </article>
  );
};
