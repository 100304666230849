import * as React from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as types from 'src/types';
import useCarousel from 'src/hooks/useCarousel';
import { Control } from '../../ui/carousel';
import { Title, TitlesType } from '../../Title/Title';
import * as scss from './Titles.module.scss';

export type Props = {
  children?: React.ReactNode;
  id?: string;
  headline?: {
    text: string;
    style: React.CSSProperties;
  };
  subheadline?: {
    text: string;
    style: React.CSSProperties;
  };
  items?: TitlesType[];
  style?: React.CSSProperties;
};

export const Titles = ({
  id,
  items: propsItems = [],
  headline,
  subheadline,
  style,
}: Props) => {
  // states
  const [items, setItems] = React.useState(propsItems);

  // hooks
  const {
    activeItemOverlay,
    isOverflowing,
    position,
    handleControl,
    onResize,
    onReachEnd,
    onSlideChange,
    onSwiper,
    onOverlayToggle,
  } = useCarousel();

  React.useEffect(() => {
    setItems(propsItems);
  }, [propsItems]);

  /**
   * Do loop separately for better readability
   */
  const renderItems = items.map((item, index) => (
    <SwiperSlide
      key={`new-titles-${item.id}`}
      tag="li"
      className={clsx(scss.item, index === items.length - 1 && scss.itemLast)}>
      <Title
        toggle={
          activeItemOverlay === index
            ? types.ToggleStates.open
            : types.ToggleStates.close
        }
        data={item}
        onToggle={onOverlayToggle(index)}
      />
    </SwiperSlide>
  ));

  return (
    <section id={id} className={clsx(scss.wrapper)} style={style}>
      <div className={clsx(scss.bg)}></div>
      <div className={clsx(scss.head, 'container container--fhd')}>
        <h2 className={clsx(scss.headline)} style={headline?.style}>
          {headline?.text}
        </h2>
        <p className={clsx(scss.subheadline)} style={subheadline?.style}>
          {subheadline?.text}
        </p>
      </div>

      <div className={clsx(scss.items, 'container container--fhd')}>
        <Swiper
          watchSlidesVisibility
          allowTouchMove={false}
          className={clsx(scss.list)}
          slidesPerView="auto"
          spaceBetween={12}
          speed={500}
          wrapperTag="ul"
          preventClicks={false}
          onInit={onSwiper}
          onResize={onResize}
          onReachEnd={onReachEnd}
          onSlideChange={onSlideChange}>
          {renderItems}
        </Swiper>
        {isOverflowing && (
          <Control.Control>
            <Control.Prev
              className={clsx({
                'is-disabled': 'start' === position,
              })}
              disabled={'start' === position}
              onClick={handleControl}
            />
            <Control.Next
              className={clsx({
                'is-disabled': 'end' === position,
              })}
              disabled={'end' === position}
              onClick={handleControl}
            />
          </Control.Control>
        )}
      </div>
    </section>
  );
};
