import * as React from 'react';
import { gameQuestionType } from './Game';

export type newGameQuestionType = {
  category: string;
  question: string;
  choices: string[];
  answer: string;
};

// types
type StateType = {
  gameData: newGameQuestionType[];
  gameOver: boolean;
  selectedItems: number[];
  currentItem: undefined | number;
  currentChoice: string | null;
  maxGameLength: number;
  progress: number;
  score: number;
};

type ActionType = {
  type: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

type DispatchType = (action: ActionType) => void;

// default state
const defaultState = {
  gameData: [],
  gameOver: false,
  selectedItems: [],
  currentItem: undefined,
  currentChoice: null,
  maxGameLength: 3,
  progress: 0,
  score: 0,
};

/** CONTEXT **/
export const GameContext = React.createContext<{
  state: StateType;
  dispatch: DispatchType;
}>({
  state: defaultState,
  dispatch: (action) => {
    throw new Error('Game Provider not found.');
  },
});

// actions
const LOAD_DATA = 'LOAD_DATA';
const CURRENT_ITEM = 'CURRENT_ITEM';
const SUBMIT_ANSWER = 'SUBMIT_ANSWER';
const GAME_OVER_TRUE = 'GAME_OVER_TRUE';
const RESET_GAME = 'RESET_GAME';

/** REDUCERS **/
const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case LOAD_DATA:
      // "Flatten" each question's choices so it's an array of strings, not objects
      const newGameData = action.payload.map((item: gameQuestionType) => {
        const newChoices = item.choices.map((choice) => choice.text);

        return {
          ...item,
          choices: newChoices,
        };
      });

      return {
        ...state,
        gameData: newGameData,
      };

    case CURRENT_ITEM:
      if (state.progress === state.maxGameLength) {
        return {
          ...state,
          gameOver: true,
        };
      } else {
        return {
          ...state,
          selectedItems: [...state.selectedItems, action.payload],
          currentItem: action.payload,
          currentChoice: null,
          progress: state.progress + 1,
        };
      }

    case GAME_OVER_TRUE:
      return {
        ...state,
        gameOver: true,
      };

    case SUBMIT_ANSWER:
      if (state.currentItem === undefined) return { ...state };

      if (state.currentChoice) return { ...state };

      if (action.payload === state.gameData[state.currentItem].answer) {
        const scorePoints = 3;
        const totalScoreIncrease = scorePoints;

        return {
          ...state,
          score: state.score + totalScoreIncrease,
          currentChoice: action.payload,
        };
      } else {
        return {
          ...state,
          currentChoice: action.payload,
        };
      }

    case RESET_GAME:
      return {
        ...state,
        gameOver: false,
        selectedItems: [],
        currentItem: undefined,
        currentChoice: null,
        progress: 0,
        score: 0,
      };

    default:
      return state;
  }
};

/** PROVIDER **/
export const GameProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  return (
    <GameContext.Provider value={{ state, dispatch }}>
      {children}
    </GameContext.Provider>
  );
};
