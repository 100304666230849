import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import * as scss from './Step.module.scss';
import { getStrapiImageSource } from '../../../utils/strapiDataHelpers';

export type StepType = {
  id: string;
  thumbnail: types.ImageFields;
  headline: types.TextFields;
  description: types.TextFields;
};

export type StepProps = {
  data: StepType;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Single carousel item for How To Access component.
 */
export const Step = ({ className, style, data }: StepProps) => {
  return (
    <div className={clsx(scss.step, className)} style={style}>
      <img
        src={getStrapiImageSource(data.thumbnail.src.sm as types.ImageSource)}
        alt={data.thumbnail.alt}
      />
      <h3 style={data.headline.style}>{data.headline.text}</h3>
      <div
        className={clsx(scss.description)}
        style={data.description.style}
        dangerouslySetInnerHTML={{ __html: data.description.text }}></div>
    </div>
  );
};
