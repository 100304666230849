import React from 'react';
import clsx from 'clsx';

import { stateType } from '../GameContext';

import * as scss from '../Game.module.scss';

const GameQuestion = (state: stateType) => (
  <div
    className={clsx(scss.gameQuestion)}
    dangerouslySetInnerHTML={{ __html: state.currentQuestion }}
  />
);

export default GameQuestion;
