import * as React from 'react';
import clsx from 'clsx';
import * as scss from './styledTextBox.module.scss';

export type Props = {
  children?: React.ReactNode;
  tag?: keyof JSX.IntrinsicElements;
};

export const GradientLinesContainer = ({
  children,
  tag: Component = 'header',
}: Props) => {
  return (
    <Component className={clsx(scss.wrapper)}>
      <div className={clsx(scss.wrapperLineTop)} />
      {children}
      <div className={clsx(scss.wrapperLineBottom)} />
    </Component>
  );
};
