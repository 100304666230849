import * as React from 'react';
import clsx from 'clsx';
import { ReactComponent as ChevLtIcon } from 'src/assets/media/icons/chev-lt.svg';
import * as scss from './carousel.module.scss';

export type PrevProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  rootClassName?: string;
  rootStyle?: React.CSSProperties;
  style?: React.CSSProperties;

  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const Prev = ({
  className,
  disabled,
  rootClassName,
  rootStyle,
  style,
  onClick,
}: PrevProps) => {
  return (
    <li className={clsx(rootClassName)} style={rootStyle}>
      <button
        className={clsx(scss.control, scss.controlPrev, 'ring', className)}
        style={style}
        disabled={disabled}
        data-action="prev"
        arial-label="Previous Item"
        onClick={onClick}>
        <span className="visually-hidden">Previous Item</span>
        <span className={clsx(scss.controlIcon)}>
          <ChevLtIcon className="i" />
        </span>
      </button>
    </li>
  );
};
