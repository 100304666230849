import React from 'react';
import clsx from 'clsx';

import { gameDataType } from '../Game';
import { stateType } from '../GameContext';

import * as scss from '../Game.module.scss';

type Props = {
  data: gameDataType;
  state: stateType;
};

const GameProgress = ({ data, state }: Props) => {
  const progress = data.questionProgressText
    .replace('{currentQuestion}', `${state.currentQuestionNumber + 1}`)
    .replace('{totalQuestions}', `${data.questions.length}`);

  return <div className={clsx(scss.gameProgress)}>{progress}</div>;
};

export default GameProgress;
