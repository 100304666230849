import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import { HowToAccessSlider } from './HowToAccessSlider';
import * as scss from './HowToAccess.module.scss';
import { StepType } from './Step';

// import * as dataPhoneComputer from './dataPhoneComputer';
// import * as dataTV from './dataTV';

export type Props = {
  footer?: types.TextFields;
  headline?: types.TextFields;
  dataPhoneComputer: {
    headline: types.TextFields;
    items: StepType[];
  };
  dataTV: {
    headline: types.TextFields;
    items: StepType[];
  };
} & React.HTMLAttributes<HTMLElement>;

export const HowToAccess = ({
  className,
  footer,
  headline,
  dataPhoneComputer,
  dataTV,
  id,
  style,
}: Props) => {
  return (
    <section id={id} className={clsx(scss.wrapper, className)} style={style}>
      <div className={clsx(scss.header, 'container')}>
        <h2 style={headline?.style}>{headline?.text}</h2>
      </div>
      <HowToAccessSlider
        items={dataPhoneComputer.items}
        headline={dataPhoneComputer.headline.text}
      />
      <HowToAccessSlider items={dataTV.items} headline={dataTV.headline.text} />
      <div
        className={clsx(scss.footer, 'container')}
        dangerouslySetInnerHTML={{ __html: footer?.text ?? '' }}></div>
    </section>
  );
};
