import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import useView from 'src/hooks/useView';
import * as scss from './DeviceAvailability.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

export type DeviceAvailabilityItemType = {
  id: string;
  title: types.TextFields;
  content: { text: string };
  image: types.ImageFields;
};

export type Props = {
  data: {
    backgroundImage: types.ImageFields;
    headline: types.TextFields;
    subheadline?: types.TextFields;
    footerContent: string;
    content: DeviceAvailabilityItemType[];
  };
  id?: string;
};

export const DeviceAvailability = ({ data, id }: Props) => {
  // hooks
  const view = useView();

  // render
  return (
    <section id={id} className={clsx(scss.section)}>
      {data.backgroundImage.src[view] && (
        <div className={clsx(scss.sectionBg)}>
          <img
            src={getStrapiImageSource(
              data.backgroundImage.src[view] as types.ImageSource
            )}
            alt={data.backgroundImage.alt}
          />
        </div>
      )}
      <div className={clsx(scss.container)}>
        <div className={clsx(scss.header)}>
          <h2>{data.headline.text}</h2>
        </div>
        {data.subheadline && (
          <p className={clsx(scss.subheadline)}>{data.subheadline.text}</p>
        )}
        <div className={clsx(scss.row)}>
          {data.content.map((item, i) => (
            <div key={i} className={clsx(scss.col)}>
              <div className={clsx(scss.content)}>
                <div className={clsx(scss.contentImage)}>
                  <img
                    src={getStrapiImageSource(
                      item.image.src.lg as types.ImageSource
                    )}
                    alt={item.image.alt}
                  />
                </div>
                <div
                  className={clsx(scss.contentTitle)}
                  dangerouslySetInnerHTML={{ __html: item.title.text }}
                />
                <span className={clsx(scss.contentHorizontalRule)}></span>
                <div
                  className={clsx(scss.contentPrint)}
                  dangerouslySetInnerHTML={{ __html: item.content.text }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={clsx(scss.footer)}>
          <div dangerouslySetInnerHTML={{ __html: data.footerContent }} />
        </div>
      </div>
    </section>
  );
};
