import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import * as scss from './SeasonalPromo.module.scss';
import { Button } from '../ui/button/button';
import CarouselVideoOverlayButton from '../Carousel/CarouselVideoOverlayButton';
import { FormOverlay } from '../ui/formOverlay/formOverlay';

export type Props = {
  id: string;
  debug: boolean;

  backgroundImage: types.ImageFields;
  overflowBackgroundColor?: { color?: string };

  textAboveButtons?: types.TextFields;
  mobileTextAboveButtons?: types.TextFields;
  maxTextWidthPercent?: number;
  maxMobileTextWidthPercent?: number;

  CTAButtons: types.ButtonFields[];
  maxButtonsWidthPercent?: number;
  maxMobileButtonsWidthPercent?: number;

  leftOffsetPercent: number;
  leftMobileOffsetPercent: number;
  rightOffsetPercent: number;
  rightMobileOffsetPercent: number;
  topOffsetPercent: number;
  topMobileOffsetPercent: number;
  bottomOffsetPercent: number;
  bottomMobileOffsetPercent: number;
};

export const SeasonalPromo = ({
  id,
  debug,

  backgroundImage,
  overflowBackgroundColor,

  textAboveButtons,
  mobileTextAboveButtons,
  maxTextWidthPercent,
  maxMobileTextWidthPercent,

  CTAButtons,
  maxButtonsWidthPercent,
  maxMobileButtonsWidthPercent,

  leftOffsetPercent,
  leftMobileOffsetPercent,
  rightOffsetPercent,
  rightMobileOffsetPercent,
  topOffsetPercent,
  topMobileOffsetPercent,
  bottomOffsetPercent,
  bottomMobileOffsetPercent,
}: Props) => {
  // states
  const [overlayOpen, setOverlayOpen] = React.useState(false);
  const [overlayFormOpen, setOverlayFormOpen] = React.useState(false);

  const sectionRef = React.useRef<HTMLElement | null>(null);

  const [imageAspectRatio, setImageAspectRatio] = React.useState<number | null>(
    null
  );
  const [mobileImageAspectRatio, setMobileImageAspectRatio] = React.useState<
    number | null
  >(null);

  React.useEffect(() => {
    const setAspectRatio = (
      imageUrl: string | undefined,
      setAspectRatio: React.Dispatch<React.SetStateAction<number | null>>
    ) => {
      if (!imageUrl) return;

      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setAspectRatio(img.width / img.height);
      };
    };

    setAspectRatio(backgroundImage.src.lg?.url, setImageAspectRatio);
    setAspectRatio(backgroundImage.src.sm?.url, setMobileImageAspectRatio);
  }, [backgroundImage.src.lg?.url, backgroundImage.src.sm?.url]);

  const [
    backgroundImageHeight,
    setBackgroundImageHeight,
  ] = React.useState<number>();

  const [view, setView] = React.useState<types.Size>(types.Sizes.sm);

  const marginTop = React.useMemo(
    () =>
      view === types.Sizes.lg
        ? backgroundImageHeight * ((topOffsetPercent - 100) / 100)
        : backgroundImageHeight * ((topMobileOffsetPercent - 100) / 100),
    [backgroundImageHeight, topOffsetPercent, topMobileOffsetPercent, view]
  );
  const paddingBottom = React.useMemo(
    () =>
      view === types.Sizes.lg
        ? backgroundImageHeight * (bottomOffsetPercent / 100)
        : backgroundImageHeight * (bottomMobileOffsetPercent / 100),
    [
      backgroundImageHeight,
      bottomOffsetPercent,
      bottomMobileOffsetPercent,
      view,
    ]
  );
  const left = React.useMemo(
    () =>
      view === types.Sizes.lg
        ? leftOffsetPercent
          ? `${
              leftOffsetPercent +
              (100 - leftOffsetPercent - rightOffsetPercent ?? 0) / 2
            }%`
          : undefined
        : leftMobileOffsetPercent
        ? `${
            leftMobileOffsetPercent +
            (100 - leftMobileOffsetPercent - rightMobileOffsetPercent ?? 0) / 2
          }%`
        : undefined,
    [
      leftMobileOffsetPercent,
      leftOffsetPercent,
      rightMobileOffsetPercent,
      rightOffsetPercent,
      view,
    ]
  );
  const transform = React.useMemo(
    () =>
      view === types.Sizes.lg
        ? leftOffsetPercent
          ? 'translateX(-50%)'
          : undefined
        : leftMobileOffsetPercent
        ? 'translateX(-50%)'
        : undefined,
    [leftMobileOffsetPercent, leftOffsetPercent, view]
  );
  const maxWidth = React.useMemo(
    () =>
      view === types.Sizes.lg
        ? maxTextWidthPercent
          ? `${maxTextWidthPercent}%`
          : undefined
        : maxMobileTextWidthPercent
        ? `${maxMobileTextWidthPercent}%`
        : undefined,
    [maxMobileTextWidthPercent, maxTextWidthPercent, view]
  );
  const maxButtonWidth = React.useMemo(
    () =>
      view === types.Sizes.lg
        ? maxButtonsWidthPercent
          ? `${maxButtonsWidthPercent}%`
          : undefined
        : maxMobileButtonsWidthPercent
        ? `${maxMobileButtonsWidthPercent}%`
        : undefined,
    [maxMobileButtonsWidthPercent, maxButtonsWidthPercent, view]
  );

  const currentInnerText = React.useMemo(
    () =>
      view === types.Sizes.lg
        ? textAboveButtons?.text ?? ''
        : mobileTextAboveButtons?.text ?? '',
    [view, textAboveButtons, mobileTextAboveButtons]
  );

  React.useEffect(() => {
    if (!window) return;

    const handleViewMode = () => {
      if (!window.innerWidth) return;

      setBackgroundImageHeight(() => {
        const width = sectionRef.current?.getBoundingClientRect().width;

        if (!width) return 0;

        if (view === types.Sizes.lg)
          return imageAspectRatio ? width / imageAspectRatio : 0;

        if (backgroundImage.src.sm?.url)
          return mobileImageAspectRatio ? width / mobileImageAspectRatio : 0;

        return imageAspectRatio ? width / imageAspectRatio : 0;
      });

      setView(() => {
        return window.innerWidth >= types.Breakpoints.md
          ? types.Sizes.lg
          : types.Sizes.sm;
      });
    };

    handleViewMode();
    window.addEventListener('resize', handleViewMode);
    return () => window.removeEventListener('resize', handleViewMode);
  }, [
    backgroundImage.src.sm?.url,
    imageAspectRatio,
    mobileImageAspectRatio,
    view,
  ]);

  return (
    <section
      ref={sectionRef}
      id={id}
      className={clsx(scss.wrapper)}
      style={{
        background: overflowBackgroundColor?.color,
        minHeight: `${backgroundImageHeight}px`,
      }}>
      <picture>
        {backgroundImage.src.lg ? (
          <>
            {backgroundImage.src.sm ? (
              <source
                media="(min-width: 768px)"
                srcSet={backgroundImage.src.lg.url}
              />
            ) : (
              <></>
            )}
            <img
              className={clsx(scss.backgroundImage)}
              src={backgroundImage.src.sm?.url ?? backgroundImage.src.lg.url}
              alt=""
            />
          </>
        ) : (
          <></>
        )}
      </picture>

      <div
        className={clsx(scss.newElement, debug && scss.debug)}
        style={
          {
            left,
            transform,

            marginTop: topOffsetPercent ? `${marginTop}px` : undefined,
            paddingBottom: bottomOffsetPercent
              ? `${paddingBottom}px`
              : undefined,

            maxWidth,
            '--max-button-width': maxButtonWidth,
          } as React.CSSProperties
        }>
        {currentInnerText && (
          <p
            className={clsx(scss.innerText)}
            dangerouslySetInnerHTML={{ __html: currentInnerText }}
          />
        )}

        {CTAButtons.map((ctaButton, index) =>
          ctaButton.text.length ? (
            ctaButton.type === 'overlay' ? (
              ctaButton.link === 'sweepstakes' ? (
                <React.Fragment
                  key={`seasonal-promo-button-${index}-${ctaButton.text}`}>
                  <Button
                    className={clsx(scss.button)}
                    tag="a"
                    outline={ctaButton?.outline}
                    label={ctaButton?.text}
                    href={ctaButton?.link}
                    type={ctaButton?.type}
                    mode={ctaButton?.mode}
                    style={ctaButton?.style}
                    onClick={(e) => {
                      e.preventDefault();
                      setOverlayFormOpen(true);
                    }}
                  />

                  <FormOverlay
                    open={overlayFormOpen}
                    setOpen={setOverlayFormOpen}
                  />
                </React.Fragment>
              ) : (
                <CarouselVideoOverlayButton
                  key={`seasonal-promo-button-${index}-${ctaButton.text}`}
                  showIcon={false}
                  className={clsx(scss.button)}
                  buttonTitle={ctaButton.text}
                  videoTitle={ctaButton.overlayTitle ?? ''}
                  videoURL={ctaButton.link}
                  buttonStyle={ctaButton.style}
                  videoOverlayOpen={overlayOpen}
                  setVideoOverlayOpen={setOverlayOpen}
                  mode={ctaButton?.mode}
                  outline={ctaButton?.outline}
                />
              )
            ) : (
              <Button
                key={`seasonal-promo-button-${index}-${ctaButton.text}`}
                className={clsx(scss.button)}
                tag="a"
                outline={ctaButton?.outline}
                label={ctaButton?.text}
                href={ctaButton?.link}
                type={ctaButton?.type}
                mode={ctaButton?.mode}
                style={ctaButton?.style}
              />
            )
          ) : (
            <></>
          )
        )}
      </div>
    </section>
  );
};
