import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import * as scss from './DownloadsWallpaperItem.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

export type DownloadLinkType = {
  label: string;
  url: string;
  altTitle?: string;
};

export type DownloadsWallpaperItemType = {
  id: string;
  title: types.TextFields;
  image: types.ImageFields;
  category?: {
    text: string;
  };
  links: DownloadLinkType[];
};

export type Props = {
  data: DownloadsWallpaperItemType;
};

export const DownloadLink: React.FC<DownloadLinkType> = ({
  url,
  label,
  altTitle,
}) => {
  function handleClick() {
    const isBrowser = typeof window !== 'undefined';

    const canvasEl = document.createElement('canvas');
    const canvasContext = canvasEl.getContext('2d');
    const imgEl = document.createElement('img');
    const downloadLinkEl = document.createElement('a');

    imgEl.src = url;

    imgEl.crossOrigin = 'anonymous';

    imgEl.onload = function () {
      canvasEl.height = imgEl.height;
      canvasEl.width = imgEl.width;

      canvasContext?.drawImage(imgEl, 0, 0);

      const dataURL = canvasEl.toDataURL('image/jpeg');

      downloadLinkEl.href = dataURL;
      downloadLinkEl.download = label;

      downloadLinkEl.click();

      // GA_TAG_EVENT
      if (isBrowser) {
        window.dataLayer.push({
          event: 'Wallpaper Download',
          data: `${altTitle} - ${label}`,
        });
      }
    };
  }

  return (
    <button
      title={`Wallpaper Download - ${altTitle} - ${label}`}
      onClick={handleClick}>
      {label}
    </button>
  );
};

export const DownloadsWallpaperItem: React.FC<Props> = ({ data }) => {
  // render
  return (
    <article className={clsx(scss.content)}>
      <header>
        <div className={clsx(scss.contentImage)}>
          <div className={clsx(scss.contentImageInner)}>
            {data.category?.text && (
              <span className={clsx(scss.contentImageRibbon)}>
                {data.category.text}
              </span>
            )}
            <img
              src={getStrapiImageSource(data.image.src.lg as types.ImageSource)}
              alt={data.image.alt}
            />
          </div>
        </div>
        <h2 className={clsx(scss.contentTitle)}>{data.title.text}</h2>
      </header>
      <div className={clsx(scss.contentSubTitle)}>Download</div>
      <ul className={clsx(scss.contentLinks)}>
        {data.links.map((link, i) => (
          <li key={i}>
            <DownloadLink
              label={link.label}
              url={link.url}
              altTitle={data.title.text}
            />
          </li>
        ))}
      </ul>
    </article>
  );
};
