import * as React from 'react';
import clsx from 'clsx';

import { useGame } from './GameContext';
import { StyledHeading } from 'src/components/ui/styledHeading/styledHeading';
import { Button } from 'src/components/ui/button/button';

import * as scss from './Game.module.scss';

import { ImageFields } from '../../../types';
import { getStrapiImageSource } from './../../../utils/strapiDataHelpers';

export type GameItem = {
  idOverUnder?: string;
  question: string;
  answer: string;
  trivia: string;
  overButtonText: string;
  underButtonText: string;
  poster: ImageFields;
};

export type Data = {
  id: number;
  heading: string;
  questionLabel: string;
  continueButtonLabel: string;
  submitButtonLabel: string;
  thankYouLabel: string;
  yourScoreLabel: string;
  finishGameLabel: string;
  playAgainLabel: string;
  overUnderQuestions: GameItem[];
};

export type Props = {
  data: Data;
  id?: string;
};

const Game = ({ data, id = 'activity-section' }: Props) => {
  // misc
  const isBrowser = typeof window !== 'undefined';

  // refs
  const gameRef = React.useRef<HTMLDivElement>(null);

  // context
  const { state, dispatch } = useGame();

  // states
  const [lockedInChoice, setLockedInChoice] = React.useState('');

  // Effect - Load game data
  React.useEffect(() => {
    if (state.gameData.length) return;

    // Get random set of questions.
    const payload = data.overUnderQuestions
      .sort(() => 0.5 - Math.random())
      .slice(0, state.maxGameLength);

    dispatch({ type: 'SET_GAME_DATA', payload });
  }, [state, data, dispatch]);

  // Effect - Lock in choice
  React.useEffect(() => {
    setLockedInChoice('');
  }, [state.currentItem]);

  // Effect - Analytics - Game Start
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      window?.dataLayer?.push({
        event: 'Over or Under Game',
        action: 'gameStart',
      });
    }
  }, [isBrowser]);

  // Effect - Analytics - Game Over
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      if (state.gameOver) {
        window?.dataLayer?.push({
          event: 'Over or Under Game',
          action: 'gameOver',
          data: {
            score: state.score,
          },
        });
      }
    }
  }, [isBrowser, state.gameOver, state.score]);

  // Effect - Analytics - Question
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      if (state.currentItem !== undefined) {
        window?.dataLayer?.push({
          event: 'Over or Under Game',
          action: 'quizQuestion',
          data: {
            progress: `${state.progress}`,
            question: state.gameData[state?.currentItem]?.question,
          },
        });
      }
    }
  }, [isBrowser, state.gameData, state.currentItem, state.progress]);

  // Effect - Analytics - Choice
  React.useEffect(() => {
    // GA_TAG_EVENT
    if (isBrowser) {
      if (state.currentItem !== -1) {
        if (state.currentAnswer) {
          window?.dataLayer?.push({
            event: 'Over or Under Game',
            action: 'quizChoice',
            data: {
              answer: state.currentAnswer,
              correctAnswer: state.gameData[state.currentItem].answer,
            },
          });
        }
      }
    }
  }, [isBrowser, state.gameData, state.currentAnswer, state.currentItem]);

  if (state.gameData.length === 0) {
    return null;
  }

  return (
    <div ref={gameRef} id={id} className={scss.wrapper}>
      {/* Aria Live */}
      <div className="visually-hidden" aria-live="polite">
        {!state.gameOver &&
          !lockedInChoice &&
          `Please select one of the two answer buttons to choose your answer.`}

        {!state.gameOver &&
          !state.currentAnswer &&
          !!lockedInChoice &&
          `You selected ${lockedInChoice}. Select the "${data.submitButtonLabel}" button below to lock in your choice, or select another answer.`}

        {!state.gameOver &&
          state.currentAnswer &&
          state.currentAnswer !==
            state.gameData[state.currentItem].answer.toLowerCase() && (
            <>
              Sorry, that's incorrect. The correct answer is
              {state.gameData[state.currentItem].answer}.
              {state.progress !== state.maxGameLength
                ? `Please select the ${data.continueButtonLabel} button for the next question.`
                : 'Please select the "FINISH GAME" button to finish.'}
            </>
          )}

        {!state.gameOver &&
          state.currentAnswer &&
          state.currentAnswer ===
            state.gameData[state.currentItem].answer.toLowerCase() && (
            <>
              That's correct!
              {state.progress !== state.maxGameLength
                ? `Please select the "${data.continueButtonLabel}" button for the next question.`
                : 'Please select the "FINISH GAME" button to finish.'}
            </>
          )}

        {state.gameOver &&
          `Thank you for playing! Your score is ${state.score}. Select the "${data.playAgainLabel}" button to get ${state.maxGameLength} more random questions.`}
      </div>

      <div className={scss.container}>
        {/* heading */}
        <div className={scss.heading}>
          <StyledHeading>{data.heading}</StyledHeading>
        </div>

        {/* Screen - Game Over */}
        <ScreenGameOver show={state.gameOver} data={data} />

        {/* Screen - Game Single */}
        <ScreenGameItem
          data={data}
          gameRef={gameRef}
          isBrowser={isBrowser}
          lockedInChoice={lockedInChoice}
          show={!state.gameOver || !state.currentItem}
          setLockedInChoice={setLockedInChoice}
        />
      </div>
    </div>
  );
};

export default Game;

type ScreenProps = {
  data: Data;
  show?: boolean;
};

export const ScreenGameOver = ({ show, data }: ScreenProps) => {
  const { state, dispatch } = useGame();

  const reset = () => {
    dispatch({ type: 'RESET_GAME' });
  };

  /**
   * Get the game score text, replacing score token
   */
  function getScore() {
    return data.yourScoreLabel.replace('{{SCORE}}', '' + state.score);
  }

  if (!show) return null;

  return (
    <div className={scss.screenGameOver}>
      <div className={scss.content}>
        <p dangerouslySetInnerHTML={{ __html: data.thankYouLabel }}></p>
        <p dangerouslySetInnerHTML={{ __html: getScore() }}></p>
      </div>

      <div className={clsx(scss.cta)}>
        <Button
          className={clsx(scss.btnAction)}
          tag="button"
          label={data.playAgainLabel}
          onClick={reset}
        />
      </div>
    </div>
  );
};

export type ScreenGameItemProps = ScreenProps & {
  gameRef: React.RefObject<HTMLDivElement>;
  isBrowser?: boolean;
  lockedInChoice: string;
  setLockedInChoice: React.Dispatch<React.SetStateAction<string>>;
};

export const ScreenGameItem = ({
  data,
  gameRef,
  isBrowser,
  show,
  ...props
}: ScreenGameItemProps) => {
  const { state, dispatch } = useGame();

  const item = state.gameData[state.currentItem];
  const itemAnswer = item.answer.toLowerCase();
  const currentAnswer = state.currentAnswer?.toLowerCase() ?? null;
  const overButtonText = item.overButtonText?.toLowerCase() ?? 'over';
  const underButtonText = item.underButtonText?.toLowerCase() ?? 'under';
  const answerButtons = [overButtonText, underButtonText];

  /**
   * Get the question status text, replacing current and total numbers
   */
  function getQuestionStatus() {
    return data.questionLabel
      .replace('{{CURRENT}}', '' + state.progress)
      .replace('{{TOTAL}}', '' + state.maxGameLength);
  }

  /**
   * Submit locked in answer
   */
  function submitLockedInAnswer() {
    dispatch({
      type: 'SET_ANSWER',
      payload: props.lockedInChoice,
    });
  }

  /**
   * Proceed to next Item
   */
  function proceed() {
    if (gameRef.current) {
      gameRef.current.scrollIntoView();
    }

    props.setLockedInChoice('');

    dispatch({ type: 'SET_CURRENT_ITEM', payload: state.currentItem + 1 });

    // GA_TAG_EVENT
    if (isBrowser) {
      window?.dataLayer?.push({
        event: 'Over or Under Game',
        action: 'quizContinue',
      });
    }
  }

  if (!show) return null;

  return (
    <div className={scss.screenGame}>
      <div className={scss.screenGameGrid}>
        {/* column 1 */}
        <div className={clsx(scss.col, scss.colLeft)}>
          <span className={scss.featImgSpacer}>{'\u200B'}</span>
          <img
            className={scss.featImg}
            src={getStrapiImageSource(item.poster.src.lg)}
            alt={item.poster.alt}
          />
          <p>{item.poster.alt}</p>
        </div>

        {/* column 2 */}
        <div className={clsx(scss.col, scss.colRight)}>
          {/* progress */}
          <div
            className={scss.progress}
            dangerouslySetInnerHTML={{ __html: getQuestionStatus() }}></div>

          {/* question */}
          <div
            className={scss.question}
            dangerouslySetInnerHTML={{ __html: item.question }}></div>

          {/* choices */}
          {/* TODO: MOVE THIS TEXT INTO STRAPI CMS */}
          <div className={scss.choiceHeading}>
            Is the number in this statement “over” or “under” the real answer...
          </div>

          <ul className={scss.choiceList} arial-label="Over or Under buttons">
            {answerButtons.map((choice, index) => (
              <li
                key={choice}
                className={clsx({
                  'is-selected': props.lockedInChoice.toLowerCase() === choice,
                  'is-error':
                    currentAnswer && currentAnswer !== itemAnswer
                      ? choice !== itemAnswer
                      : false,
                  'is-success': currentAnswer && itemAnswer === choice,
                })}>
                <button
                  aria-label={
                    currentAnswer && item !== undefined
                      ? itemAnswer === choice
                        ? `Correct Answer: ${choice}`
                        : `Incorrect Answer: ${choice}`
                      : `Answer ${index + 1}: ${choice}`
                  }
                  dangerouslySetInnerHTML={{ __html: choice.toUpperCase() }}
                  onClick={() => {
                    if (!currentAnswer) {
                      props.setLockedInChoice(choice);
                    }
                  }}></button>
              </li>
            ))}
          </ul>

          {/* round feedback */}
          <div className={scss.clue}>
            <span>
              {currentAnswer
                ? currentAnswer === itemAnswer
                  ? 'Way to go! '
                  : 'Nice try. '
                : ''}
            </span>
            <span
              dangerouslySetInnerHTML={{
                __html: currentAnswer ? item.trivia : '\u200B',
              }}></span>
            <span>
              {currentAnswer
                ? currentAnswer !== itemAnswer
                  ? ' Better luck next time.'
                  : ''
                : ''}
            </span>
          </div>

          {/* controls */}
          <ul className={scss.controlList} aria-label="Quiz Action Buttons">
            <li>
              <Button
                className={clsx(scss.btnAction)}
                tag="button"
                label={data.submitButtonLabel}
                disabled={!props.lockedInChoice || state.currentAnswer !== null}
                onClick={submitLockedInAnswer}
              />
            </li>
            <li>
              <Button
                className={clsx(scss.btnAction)}
                tag="button"
                label={
                  state.progress !== state.maxGameLength
                    ? data.continueButtonLabel
                    : data.finishGameLabel
                }
                disabled={!state.currentAnswer}
                onClick={proceed}
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
