import * as React from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as types from 'src/types';
import useCarousel from 'src/hooks/useCarousel';
import useView from 'src/hooks/useView';
import { Control } from '../../ui/carousel';
import { Title, TitlesType } from '../../Title/Title';
import * as scss from './Spotlight.module.scss';
import { getStrapiImageSource } from '../../../utils/strapiDataHelpers';

export type SpotlightType = {
  spotExcerpt?: types.TextFields;
  spotImage?: types.ImageFields;
  spotTitle?: types.TextFields;
} & TitlesType;

export type SectionStyle = {
  background?: types.SizeFields<string>;
};

export type Props = {
  children?: React.ReactNode;
  id?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  items?: SpotlightType[];
  sectionStyle?: SectionStyle;
  style?: React.CSSProperties;
};

export const Spotlight = ({
  id,
  items: propsItems = [],
  data,
  style,
  sectionStyle,
}: Props) => {
  // states
  const [items, setItems] = React.useState(propsItems);

  // hooks
  const view = useView();
  const {
    activeItemOverlay,
    isOverflowing,
    position,
    handleControl,
    onResize,
    onSlideChange,
    onSwiper,
    onOverlayToggle,
  } = useCarousel();

  React.useEffect(() => {
    setItems(propsItems);
  }, [propsItems]);

  /**
   * Do loop separately for better readability
   */
  const renderItems = items.map((item, index) => (
    <SwiperSlide
      key={`titles-${item.id}`}
      tag="li"
      className={clsx(scss.item, index === items.length - 1 && scss.itemLast)}>
      <Title
        headerClassName={clsx(scss.itemHeader)}
        data={item}
        toggle={
          activeItemOverlay === index
            ? types.ToggleStates.open
            : types.ToggleStates.close
        }
        onToggle={onOverlayToggle(index)}
      />
    </SwiperSlide>
  ));

  return (
    <section id={id} className={clsx(scss.wrapper)}>
      <div
        className={clsx(scss.container, 'container container--fhd')}
        style={{
          ...style,
          backgroundImage:
            sectionStyle?.background &&
            sectionStyle?.background[view] &&
            `url(${sectionStyle?.background[view]})`,
        }}>
        <div className={clsx(scss.items)}>
          <div className={clsx(scss.spot)}>
            <div className={clsx(scss.spotHead)}>
              {data.spotImage && (
                <img
                  className={clsx(scss.spotImage)}
                  src={getStrapiImageSource(data.spotImage.src.lg)}
                  alt={data.spotImage.alt}
                  loading="lazy"
                />
              )}
            </div>
            <div className={clsx(scss.spotBody)}>
              {data.spotTitle && (
                <h2
                  className={clsx(scss.spotTitle)}
                  style={data.spotTitle.style}>
                  {data.spotTitle.text}
                </h2>
              )}
              {data.spotExcerpt && (
                <p
                  className={clsx(scss.spotExcerpt)}
                  style={data.spotExcerpt.style}>
                  {data.spotExcerpt.text}
                </p>
              )}
            </div>
          </div>

          <div className={clsx(scss.listWrapper)}>
            <Swiper
              watchSlidesVisibility
              allowTouchMove={false}
              className={clsx(scss.list)}
              slidesPerView="auto"
              spaceBetween={12}
              speed={500}
              wrapperTag="ul"
              preventClicks={false}
              onInit={onSwiper}
              onResize={onResize}
              onSlideChange={onSlideChange}>
              {renderItems}
            </Swiper>

            {isOverflowing && (
              <Control.Control>
                <Control.Prev
                  className={clsx({
                    'is-disabled': 'start' === position,
                  })}
                  disabled={'start' === position}
                  onClick={handleControl}
                />
                <Control.Next
                  className={clsx({
                    'is-disabled': 'end' === position,
                  })}
                  disabled={'end' === position}
                  onClick={handleControl}
                />
              </Control.Control>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
