import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type WrapperProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const Wrapper = ({ children, className, style }: WrapperProps) => {
  return (
    <article className={clsx(scss.itemWrapper, className)} style={style}>
      {children}
    </article>
  );
};
