import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import * as scss from './DownloadsImagesItem.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

export type DownloadImageType = {
  label: string;
  image: {
    src: {
      sm: {
        url: string;
      };
      lg: {
        url: string;
      };
    };
  };
};

export type DownloadsImagesItemType = {
  id: string;
  title?: types.TextFields;
  image: types.ImageFields;
  category?: {
    text: string;
  };
  links: DownloadImageType[];
};

export type Props = {
  data: DownloadsImagesItemType;
};

export const DownloadsImagesItem: React.FC<Props> = ({ data }) => {
  const isBrowser = typeof window !== 'undefined';

  // Analytics - Right Click to Save
  const handleRightClick = (e: React.MouseEvent<HTMLImageElement>) => {
    if (isBrowser)
      window.dataLayer.push({
        event: 'Download Images Component',
        action: 'rightClickToSave',
        data: {
          image: e.target.alt,
        },
      });
  };

  // Analytics - Drag to Save
  const handleDrag = (e: React.MouseEvent<HTMLImageElement>) => {
    if (isBrowser)
      window.dataLayer.push({
        event: 'Download Images Component',
        action: 'dragToSave',
        data: {
          image: e.target.alt,
        },
      });
  };

  // render
  return (
    <article className={clsx(scss.content)}>
      <header>
        {/* <div className={clsx(scss.contentImage)}>
          <div className={clsx(scss.contentImageInner)}>
            {data.category?.text && (
              <span className={clsx(scss.contentImageRibbon)}>
                {data.category.text}
              </span>
            )}
            <img
              src={getStrapiImageSource(data.image.src.lg as types.ImageSource)}
              alt={data.image.alt}
            />
          </div>
        </div> */}
        {data.title ? (
          <h2 className={clsx(scss.contentTitle)}>{data.title.text}</h2>
        ) : (
          <></>
        )}
      </header>
      {/* <div className={clsx(scss.contentSubTitle)}>Right-click to Download</div> */}
      <ul className={clsx(scss.contentLinks)}>
        {data.links.map((link, i) => (
          <li key={i}>
            {/* <div className={clsx(scss.contentImage)}>
              <div className={clsx(scss.contentImageInner)}>
                {data.category?.text && (
                  <span className={clsx(scss.contentImageRibbon)}>
                    {data.category.text}
                  </span>
                )}
                <img
                  src={getStrapiImageSource(link.image?.src?.lg as types.ImageSource)}
                  alt={link.label}
                />
              </div>
            </div> */}
            <img
              src={getStrapiImageSource(
                link.image?.src?.lg as types.ImageSource
              )}
              alt={link.label}
              onContextMenu={handleRightClick}
              onDragStart={handleDrag}
            />
          </li>
        ))}
      </ul>
    </article>
  );
};
