// extracted by mini-css-extract-plugin
export var container = "Spotlight-module--container--YkBlE";
export var item = "Spotlight-module--item--xvbZx";
export var itemHeader = "Spotlight-module--item-header--SR-r4";
export var itemLast = "Spotlight-module--item-last--rZmo7";
export var items = "Spotlight-module--items--AYAec";
export var list = "Spotlight-module--list---Vpqa";
export var listWrapper = "Spotlight-module--list-wrapper--QLSSP";
export var slideWrapper = "Spotlight-module--slide-wrapper--+iFuY";
export var spot = "Spotlight-module--spot--QEYO6";
export var spotBody = "Spotlight-module--spot-body--Jstgz";
export var spotExcerpt = "Spotlight-module--spot-excerpt--E1oKS";
export var spotHead = "Spotlight-module--spot-head--2aZFI";
export var spotImage = "Spotlight-module--spot-image--HSnpu";
export var spotTitle = "Spotlight-module--spot-title--6R4EE";
export var wrapper = "Spotlight-module--wrapper--Cahd7";