import * as React from 'react';
import clsx from 'clsx';
import * as scss from './drawer.module.scss';

export type DrawerProps = React.HTMLAttributes<HTMLDivElement>;

export const Drawer = React.forwardRef<HTMLDivElement, DrawerProps>(
  ({ children, className, style }, ref) => {
    return (
      <div ref={ref} className={clsx(scss.drawer, className)} style={style}>
        {children}
      </div>
    );
  }
);
