import * as React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as types from 'src/types';
import * as scss from './accordion.module.scss';

export type AccordionItemType = {
  id: string;
  headline: types.TextFields;
  content: types.TextFields;
};

export type AccordionItemProps = {
  isOpen?: boolean;
  onClick: (ref: HTMLDivElement | null) => void;
} & AccordionItemType;

export const AccordionItem = ({
  headline,
  content,
  id,
  isOpen,
  onClick,
}: AccordionItemProps) => {
  const isBrowser =
    typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined';

  // refs
  const ref = React.useRef(null);

  const labelledBy = `item-${id}`;
  const controlFor = `item-detail-${id}`;

  const handleClick = () => {
    // GA_TAG_EVENT
    if (isBrowser && !isOpen)
      window.dataLayer.push({
        event: 'faqOpened',
        action: 'OPEN',
        headline: headline.text,
        content: content.text,
      });

    onClick(ref.current);
  };

  return (
    <div className={clsx(scss.item, { 'is-open': isOpen })}>
      <h3 className={clsx(scss.title)}>
        <button
          id={labelledBy}
          className={clsx(scss.toggle, 'ring')}
          aria-controls={controlFor}
          aria-expanded={isOpen}
          title={`FAQ: ${headline.text}`}
          onClick={handleClick}>
          <span className={clsx(scss.titleText)}>{headline.text}</span>
          <span className={clsx(scss.toggleIcon)}>
            <FontAwesomeIcon className="i" icon="plus" />
          </span>
        </button>
      </h3>

      <div
        ref={ref}
        id={controlFor}
        className={clsx(scss.detail)}
        role="region"
        aria-labelledby={labelledBy}>
        <article
          className={clsx(scss.container)}
          dangerouslySetInnerHTML={{ __html: content.text }}></article>
      </div>
    </div>
  );
};
