import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type ThumbnailProps = {
  alt: string;
  children?: React.ReactNode;
  className?: string;
  src: string;
  style?: React.CSSProperties;
};

export const Thumbnail = ({ alt, className, src, style }: ThumbnailProps) => {
  return (
    <img
      className={clsx(scss.itemThumb, className)}
      src={src}
      alt={alt}
      style={style}
      loading="lazy"
    />
  );
};
