import * as React from 'react';
import clsx from 'clsx';

import { GameContext, GAME_OVER, NEW_QUESTION } from './GameContext';

// Components
import AriaLive from './components/AriaLive';
import Header from './components/Header';
import GameProgress from './components/GameProgress';
import GameQuestion from './components/GameQuestion';
import GameOptions from './components/GameOptions';
import GameButtons from './components/GameButtons';
import PlayAgain from './components/PlayAgain';

import * as scss from './Game.module.scss';

// Types
export type movedMatcheeType = {
  matchee: string;
  sourceIndex: number;
  destinationIndex: number;
} | null;

export type optionType = {
  optionId: string;
  matcher: string;
  matchee: string;
};

export type questionType = {
  questionId: string;
  title: string;
  options: optionType[];
};

export type gameDataType = {
  idMatchGame: string;
  heading: string;
  subheading: string;
  questionProgressText: string;
  confirmButtonText: string;
  continueButtonText: string;
  congratulationsText: string;
  tryAgainButtonText: string;
  questions: questionType[];
};

export type Props = {
  data: gameDataType;
  id?: string;
};

const Game = ({ data, id }: Props) => {
  const gameRef = React.useRef<HTMLDivElement | null>(null);

  const { state, dispatch } = React.useContext(GameContext);

  const [confirmed, setConfirmed] = React.useState(false);
  const [movedMatchee, setMovedMatchee] = React.useState<movedMatcheeType>(
    null
  );

  // Loading new question data
  React.useEffect(() => {
    const { currentQuestion, currentQuestionNumber, gameOver } = state;
    const { questions } = data;

    // Do nothing on game over
    if (gameOver) return;

    // Check if questions are done
    if (currentQuestionNumber >= questions.length)
      return dispatch({ type: GAME_OVER });

    // Check if current question is up to date
    if (currentQuestion === questions[currentQuestionNumber].title) return;

    // Get question and options
    const payload = {
      question: questions[currentQuestionNumber].title,
      options: questions[currentQuestionNumber].options,
    };

    setMovedMatchee(null);
    setConfirmed(false);
    dispatch({ type: NEW_QUESTION, payload });
  }, [state, data, dispatch]);

  React.useEffect(() => {
    if (state.gameOver && gameRef.current) {
      gameRef.current.scrollIntoView();
    }
  }, [state.gameOver]);

  return !data.questions.length ? (
    <></>
  ) : (
    <section
      ref={gameRef}
      id={id || 'activity-section'}
      className={clsx(scss.game, scss.wrapper)}>
      <AriaLive
        data={data}
        state={state}
        confirmed={confirmed}
        movedMatchee={movedMatchee}
        numberOfQuestions={data.questions.reduce(
          (amount, question) => amount + question.options.length,
          0
        )}
      />
      <div className={clsx(scss.gameContainer)}>
        <Header
          data={data}
          state={state}
          numberOfQuestions={data.questions.reduce(
            (amount, question) => amount + question.options.length,
            0
          )}
        />
        {state.currentQuestionNumber >= data.questions.length ||
        state.gameOver ? (
          <PlayAgain data={data} dispatch={dispatch} />
        ) : (
          <>
            <GameProgress data={data} state={state} />
            <GameQuestion {...state} />
            <GameOptions
              setMovedMatchee={setMovedMatchee}
              data={data}
              state={state}
              dispatch={dispatch}
              confirmed={confirmed}
            />
            <GameButtons
              data={data}
              dispatch={dispatch}
              confirmed={confirmed}
              setConfirmed={setConfirmed}
            />
          </>
        )}
      </div>
    </section>
  );
};

export default Game;
