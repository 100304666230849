import * as React from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as types from 'src/types';
import useCarousel from 'src/hooks/useCarousel';
import useView from 'src/hooks/useView';
import { Control, Nav } from '../../ui/carousel';
import { Step, StepType } from './Step';
import * as scss from './HowToAccessSlider.module.scss';

export type Props = {
  headline?: string;
  items?: StepType[];
} & React.HTMLAttributes<HTMLElement>;

export const HowToAccessSlider = ({ headline, items = [], style }: Props) => {
  // hooks
  const view = useView();
  const {
    currentItem,
    isOverflowing,
    position,
    handleControl,
    handleItemBullet,
    onSlideChange,
    onSwiper,
  } = useCarousel();

  const renderItems = items.map((item) => (
    <SwiperSlide key={`step-${item.id}`} tag="li">
      <Step data={item} />
    </SwiperSlide>
  ));

  const renderNav = items.map((item, index) => (
    <Nav.NavItem
      key={`nav-item-${item.id}`}
      className={clsx(scss.navItem)}
      isActive={currentItem === index}
      itemIndex={index}
      label={index + 1}
      title={item.headline.text}
      onClick={handleItemBullet}
    />
  ));

  return (
    <section className={clsx(scss.wrapper)} style={style}>
      <div className={clsx(scss.items, 'container container--fhd')}>
        <h3 className={clsx(scss.headline)}>{headline}</h3>

        <Swiper
          watchSlidesVisibility
          allowTouchMove
          className={clsx(scss.list)}
          slidesPerView="auto"
          speed={500}
          wrapperTag="ul"
          preventClicks={false}
          onInit={onSwiper}
          onSlideChange={onSlideChange}>
          {renderItems}
        </Swiper>

        {isOverflowing && (
          <Control.Control>
            <Control.Prev
              className={clsx({
                'is-disabled': 'start' === position,
              })}
              disabled={'start' === position}
              onClick={handleControl}
            />
            <Control.Next
              className={clsx({
                'is-disabled': 'end' === position,
              })}
              disabled={'end' === position}
              onClick={handleControl}
            />
          </Control.Control>
        )}

        {types.Sizes.sm === view && (
          <Nav.Nav className={clsx(scss.nav)}>{renderNav}</Nav.Nav>
        )}
      </div>
    </section>
  );
};
