// extracted by mini-css-extract-plugin
export var colLeft = "Game-module--col-left--Qzeu4";
export var colRight = "Game-module--col-right--e9Pvb";
export var featImg = "Game-module--feat-img--zstLI";
export var featImgSpacer = "Game-module--feat-img-spacer--tYU8V";
export var game = "Game-module--game--z4xgS";
export var gameChoices = "Game-module--game-choices--glDXq";
export var gameContainer = "Game-module--game-container--uUc6o";
export var gameContinue = "Game-module--game-continue--fd11V";
export var gameGameOver = "Game-module--game-game-over--5o0ie";
export var gameGameOverMessage = "Game-module--game-game-over-message--R-EAh";
export var gameGrid = "Game-module--game-grid--k2ZC4";
export var gameGridItem = "Game-module--game-grid-item--Hwvs8";
export var gameHeader = "Game-module--game-header--LYfq5";
export var gamePersonalityTypeImage = "Game-module--game-personality-type-image--k+Kgf";
export var gameProgress = "Game-module--game-progress--rkuat";
export var gameQuestion = "Game-module--game-question--UA98l";
export var gameSubheader = "Game-module--game-subheader---KW0y";
export var wrapper = "Game-module--wrapper--4P8zn";