import * as React from 'react';
import { useCookies } from 'react-cookie';
import { types } from 'util';
import { VideoFields } from '../../../types';
import { VideoOverlay } from './videoOverlay';

export type VideoOverlayOnLoadType = {
  title: string;
  video: VideoFields;
  open: boolean;
  type?: string;
  muted?: boolean;
  cookiePath?: string;
  cookieMaxAge?: number;
};

const defaultVideoOverlayOnLoadType = {
  title: 'Intro Video',
  open: true,
  type: 'large',
  muted: true,
  cookiePath: '/',
  cookieMaxAge: 1209600, // 2 weeks
};

export type Props = {
  data: VideoOverlayOnLoadType;
  id?: string;
};

const VideoOverlayOnLoad: React.FC<Props> = ({
  data,
  id = 'video-onload-0',
}) => {
  data = {
    ...defaultVideoOverlayOnLoadType,
    ...data,
  };

  const urlParams = new URLSearchParams(window.location.search);
  const hasOpenSweepstakes = urlParams.get('openSweepstakes');
  const hasOpenRules = urlParams.get('openRules');

  // replace dashes with underscores to avoid weird error
  const cookieName = `firstVisit-${id}`.replaceAll('-', '-');

  const [isOpen, setIsOpen] = React.useState(true);
  const [cookie, setCookie] = useCookies([cookieName]);

  const url = React.useMemo<null | string>(() => {
    if (data.video.src.lg == null) return null;

    if (data.video.src.lg.url.endsWith('.m3u8')) {
      return data.video.src.lg.name;
    }

    return data.video.src.lg.url;
  }, [data.video.src.lg]);

  React.useEffect(() => {
    if (hasOpenSweepstakes || hasOpenRules) return;
    if (cookie[cookieName] == null || cookie[cookieName] === 0) {
      setCookie(cookieName, 0, {
        path: data.cookiePath,
        maxAge: data.cookieMaxAge, // 2 weeks
      });
    }
  }, [setCookie, cookie]);

  React.useEffect(() => {
    if (hasOpenSweepstakes || hasOpenRules) return;
    if (!isOpen) {
      setCookie(cookieName, 1, {
        path: data.cookiePath,
        maxAge: data.cookieMaxAge, // 2 weeks
      });
    }
  }, [isOpen, setCookie]);

  if (hasOpenSweepstakes || hasOpenRules || url == null) return <></>;

  return (
    <>
      {cookie[cookieName] === '0' && (
        <VideoOverlay
          title={data.title}
          url={url}
          open={data.open}
          type={data.type}
          muted={data.muted}
          setOpen={setIsOpen}
        />
      )}
    </>
  );
};

export default VideoOverlayOnLoad;
