import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type OverlayToggleProps = {
  href?: string;
  label?: string;
} & React.HTMLAttributes<HTMLAnchorElement>;

export const OverlayToggle = React.forwardRef<
  HTMLAnchorElement,
  OverlayToggleProps
>(
  (
    { className, href = '#', label = 'Show Description', style, onClick },
    ref
  ) => {
    return (
      <a
        ref={ref}
        href={href}
        className={clsx(scss.itemToggle, 'ring', className)}
        style={style}
        onClick={onClick}>
        <span className="visually-hidden">{label}</span>
      </a>
    );
  }
);
