import React from 'react';

import { gameDataType, movedMatcheeType } from '../Game';
import { stateType } from '../GameContext';

type correctAnswerType = {
  matcher: string;
  currentMatchee: string;
  correctMatchee: string;
  isCorrect: boolean;
};

type Props = {
  data: gameDataType;
  state: stateType;
  confirmed: boolean;
  movedMatchee: movedMatcheeType;
  numberOfQuestions: number;
};

const AriaLive = ({
  data,
  state,
  confirmed,
  movedMatchee,
  numberOfQuestions,
}: Props) => {
  const [correctAnswers, setCorrectAnswers] = React.useState<
    correctAnswerType[] | null
  >(null);

  React.useEffect(() => {
    if (!confirmed) return setCorrectAnswers(null);

    setCorrectAnswers(
      state.currentMatchees.map((matchee, matcheeIndex) => {
        const { matcher, matchee: correctMatchee } = state.currentOptions[
          matcheeIndex
        ];

        return {
          matcher,
          currentMatchee: matchee,
          correctMatchee,
          isCorrect: correctMatchee === matchee,
        };
      })
    );
  }, [confirmed, state]);

  const getNumberOfCorrectAnswers = () =>
    correctAnswers?.reduce(
      (amount, answer) => amount + Number(answer.isCorrect),
      0
    ) || 0;

  return (
    <div className="visually-hidden" aria-live={`polite`}>
      {/* First Load */}
      {`${data.heading}. ${data.subheading}`}

      {/* Reading new question and instructions */}
      {!state.gameOver &&
        state.currentQuestionNumber < data.questions.length &&
        `${data.questionProgressText
          .replace('{currentQuestion}', `${state.currentQuestionNumber + 1}`)
          .replace(
            '{totalQuestions}',
            `${data.questions.length}`
          )}. ${data.questions[state.currentQuestionNumber].title
          .replace('<br />', '')
          .replace('<br/>', '')}. There are ${
          data.questions[state.currentQuestionNumber].options.length
        } matcher items and ${
          data.questions[state.currentQuestionNumber].options.length
        } matchee items below. Please rearrange the matchee items to the same order as their corresponding matcher item. When done, click the ${
          data.confirmButtonText
        } button to check your matches.`}

      {/* Indicating that matchee has been moved */}
      {!state.gameOver &&
        state.currentQuestionNumber < data.questions.length &&
        movedMatchee &&
        movedMatchee.sourceIndex !== movedMatchee.destinationIndex &&
        `Matchee ${movedMatchee.matchee} now matched with ${
          data.questions[state.currentQuestionNumber].options[
            movedMatchee.destinationIndex
          ].matcher
        }.`}

      {/* Indicating that matchee stayed in the same place */}
      {!state.gameOver &&
        state.currentQuestionNumber < data.questions.length &&
        movedMatchee &&
        movedMatchee.sourceIndex === movedMatchee.destinationIndex &&
        `Matchee ${movedMatchee.matchee} remaining matched with ${
          data.questions[state.currentQuestionNumber].options[
            movedMatchee.destinationIndex
          ].matcher
        }.`}

      {/* If all the answers are correct */}
      {!state.gameOver &&
        confirmed &&
        `${
          getNumberOfCorrectAnswers() >= state.currentOptions.length - 1
            ? `Great job! You got all matches correct. `
            : `Here are the matches you missed. ${correctAnswers
                ?.filter((answer) => !answer.isCorrect)
                .map(
                  ({ matcher, correctMatchee }) =>
                    `${matcher} should be matched with ${correctMatchee}`
                )
                .join('. ')}`
        }Click the ${data.continueButtonText} button to ${
          data.questions.length - 1 >= state.currentQuestionNumber
            ? 'continue'
            : 'finish the game'
        }`}

      {/* End Game Message */}
      {state.gameOver &&
        `${data.congratulationsText.replace(
          '{score}',
          `${state.score}/${numberOfQuestions}`
        )}. If you'd like to try again, please click the ${
          data.tryAgainButtonText
        } button below`}
    </div>
  );
};

export default AriaLive;
