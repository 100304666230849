import * as React from 'react';
import clsx from 'clsx';
import * as scss from './styledHeading.module.scss';

export type Props = {
  children?: React.ReactNode;
  tag?: keyof JSX.IntrinsicElements;
};

export const StyledHeading = ({ children, tag: Component = 'h2' }: Props) => {
  return (
    <div className={clsx(scss.wrapper)}>
      <div className={clsx(scss.inner)}>
        <Component>{children}</Component>
      </div>
    </div>
  );
};
