import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import * as scss from './WaterCoolerItem.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

export type WaterCoolerItemType = {
  id: string;
  title: { text: string };
  image: types.ImageFields;
};

export type Props = {
  data: WaterCoolerItemType;
  isOdd: boolean;
};

export const WaterCoolerItem: React.FC<Props> = ({ data, isOdd }) => {
  // render
  return (
    <div className={clsx(scss.content)}>
      <div className={clsx(scss.contentImage)}>
        <div className={clsx(scss.contentImageInner)}>
          <div className={clsx(scss.contentImageInnerGlow)} />
          <img
            src={getStrapiImageSource(data.image.src.lg as types.ImageSource)}
            alt={data.image.alt}
          />
        </div>
      </div>
      <div
        className={clsx(scss.contentTitle)}
        dangerouslySetInnerHTML={{ __html: data.title.text }}
      />
    </div>
  );
};
