// extracted by mini-css-extract-plugin
export var col = "FunHeader-module--col--BLTxS";
export var container = "FunHeader-module--container--90McR";
export var errorMessage = "FunHeader-module--error-message--xKNLp";
export var featured = "FunHeader-module--featured--ozmqn";
export var featuredImage = "FunHeader-module--featured-image--5sreD";
export var featuredImageGlow = "FunHeader-module--featured-image-glow--wySgQ";
export var featuredText = "FunHeader-module--featured-text--MD0AJ";
export var featuredTextExtraLarge = "FunHeader-module--featured-text-extra-large--FHkg8";
export var featuredTextLarge = "FunHeader-module--featured-text-large--okBNR";
export var featuredTextMedium = "FunHeader-module--featured-text-medium--DrZXB";
export var playNow = "FunHeader-module--play-now--pqTWC";
export var playNowDescription = "FunHeader-module--play-now-description--+8Pxd";
export var row = "FunHeader-module--row--DLbft";
export var scan = "FunHeader-module--scan--4G1TG";
export var scanBottomLabel = "FunHeader-module--scan-bottom-label--TbDV+";
export var scanContent = "FunHeader-module--scan-content--kT5KD";
export var scanContentBody = "FunHeader-module--scan-content-body--zGgv4";
export var scanContentHead = "FunHeader-module--scan-content-head--ptaKq";
export var scanDecorativeLine = "FunHeader-module--scan-decorative-line--iODxA";
export var scanHeadline = "FunHeader-module--scan-headline--Rn6Qe";
export var scanQrCode = "FunHeader-module--scan-qr-code--oYLno";
export var section = "FunHeader-module--section--wDoBy";
export var sectionBg = "FunHeader-module--section-bg--Hcj6-";
export var sectionInner = "FunHeader-module--section-inner--9lvfw";