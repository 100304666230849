import * as React from 'react';
import clsx from 'clsx';
import useView from 'src/hooks/useView';
import * as types from 'src/types';
import { Button } from '../ui/button/button';
import { GradientLinesContainer } from '../ui/styledTextBox/styledTextBox';
import * as scss from './FunHeader.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

export type Props = {
  id?: string;
  data: {
    backgroundImage: types.ImageFields;
    featuredImage: types.ImageFields;
    featuredHeadline: types.TextFields;
    featuredSubheadline: types.TextFields;
    featuredDescription: types.TextFields;
    scanHeadline: types.TextFields;
    scanQRCode: types.ImageFields;
    scanContentHead: types.TextFields;
    scanContentBody: string;
    scanBottomLabel: types.TextFields;
  };
};

export const FunHeader: React.FC<Props> = ({ id, data }) => {
  // states
  // const [showQR, setShowQR] = React.useState(true);

  // hooks
  const view = useView();

  // resize effects
  // React.useEffect(() => {
  //   // set showQR
  //   setShowQR(() => {
  //     return types.Sizes.lg === view;
  //   });
  // }, [view]);

  return (
    <section id={id} className={clsx(scss.section)}>
      {data.backgroundImage.src[view] && (
        <div
          className={clsx(scss.sectionBg)}
          style={{
            backgroundImage: `url(${getStrapiImageSource(
              data.backgroundImage.src[view] as types.ImageSource
            )}`,
          }}
        />
      )}
      <div className={clsx(scss.sectionInner)}>
        <div className={clsx(scss.container)}>
          <div className={clsx(scss.row)}>
            {/* left */}
            <div className={clsx(scss.col)}>
              <div className={clsx(scss.featured)}>
                <div className={clsx(scss.featuredImage)}>
                  <span className={clsx(scss.featuredImageGlow)}></span>
                  <img
                    src={getStrapiImageSource(
                      data.featuredImage.src[view] as types.ImageSource
                    )}
                    alt={data.featuredImage.alt}
                  />
                </div>
                <GradientLinesContainer>
                  <div className={clsx(scss.featuredText)}>
                    <h2 className={clsx(scss.featuredTextLarge)}>
                      {data.featuredHeadline.text}
                    </h2>
                    <h3 className={clsx(scss.featuredTextMedium)}>
                      {data.featuredDescription.text}
                    </h3>
                    <h2 className={clsx(scss.featuredTextExtraLarge)}>
                      {data.featuredSubheadline.text}
                    </h2>
                  </div>
                </GradientLinesContainer>
              </div>
            </div>
            {/* right */}
            <div className={clsx(scss.col)}>
              <article className={clsx(scss.scan)}>
                <h3 className={clsx(scss.scanHeadline)}>
                  {data.scanHeadline.text}
                </h3>
                <div className={clsx(scss.scanQrCode)}>
                  <img
                    src={getStrapiImageSource(
                      data.scanQRCode.src[view] as types.ImageSource
                    )}
                    alt={data.scanQRCode.alt}
                  />
                </div>
                <div className={clsx(scss.scanContent)}>
                  <div className={clsx(scss.scanContentHead)}>
                    {data.scanContentHead?.text}
                  </div>
                  <div
                    className={clsx(scss.scanContentBody)}
                    dangerouslySetInnerHTML={{ __html: data.scanContentBody }}
                  />
                  <div className={clsx(scss.scanDecorativeLine)} />
                  <div className={clsx(scss.scanBottomLabel)}>
                    {data.scanBottomLabel.text}
                  </div>
                </div>
              </article>

              {/* <div className={clsx(scss.errorMessage)}>
                <img
                  src="/media/device-incompatible.png"
                  alt="Incompatible device"
                />
                <h4>We’re Sorry</h4>
                <p>
                  In order to complete this experience, you must view this page
                  on a desktop device so you can use your mobile device to
                  experience the AR.
                </p>
              </div> */}

              <div className={clsx(scss.playNow)}>
                <div className={clsx(scss.playNowDescription)}>
                  A PINBALL GAME FOR YOUR PHONE
                </div>
                <Button
                  outline
                  href={'https://pinball.spacejam.com/'}
                  label={'CLICK TO PLAY NOW'}
                  mode={'light'}
                  tag="a"
                  type={'external'}
                  variant="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
