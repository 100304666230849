import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import { FeatureCard, FeatureItemType } from '../ui/featureCard/featureCard';
import * as scss from './Feature.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

type imageSpecsType = {
  imageWidth?: number;
  imageHeight?: number;
} | null;

export type Props = {
  id?: string;
  overlapPreviousComponent?: boolean;
  overlapPortionHeight?: number;
  overlapMobilePortionHeight?: number;
  headline?: types.TextFields;
  hero?: types.ImageFields;
  items?: FeatureItemType[];
  subheadline?: types.TextFields;
  tagline?: types.TextFields;
};

export const Feature = ({
  id,
  overlapPreviousComponent,
  overlapPortionHeight,
  overlapMobilePortionHeight,
  headline,
  hero,
  items = [],
  subheadline,
  tagline,
}: Props) => {
  // hooks
  const [view, setView] = React.useState<types.Size>(types.Sizes.sm);

  // Image specs: needed to calculate image width and height for css calculations
  const [imageSpecs, setImageSpecs] = React.useState<imageSpecsType>(null);
  const [
    imageMobileSpecs,
    setImageMobileSpecs,
  ] = React.useState<imageSpecsType>(null);

  React.useEffect(() => {
    if (imageSpecs?.imageWidth || imageSpecs?.imageHeight) return;
    if (imageMobileSpecs?.imageWidth || imageMobileSpecs?.imageHeight) return;

    // Loading a desktop and mobile image to determine image width and height

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const img: any = new Image();
    img.onload = function () {
      setImageSpecs({
        imageWidth: this.width,
        imageHeight: this.height,
      });
    };
    img.src = getStrapiImageSource(
      hero?.src[types.Sizes.lg] as types.ImageSource
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const imgMobile: any = new Image();
    imgMobile.onload = function () {
      setImageMobileSpecs({
        imageWidth: this.width,
        imageHeight: this.height,
      });
    };
    imgMobile.src = getStrapiImageSource(
      hero?.src[types.Sizes.sm] as types.ImageSource
    );
  }, [hero?.src, imageMobileSpecs, imageSpecs]);

  const handleViewMode = React.useCallback(() => {
    if (!window.innerWidth) return;

    setView(() => {
      return window.innerWidth >= types.Breakpoints.md
        ? types.Sizes.lg
        : types.Sizes.sm;
    });
  }, []);

  React.useEffect(() => {
    if (!window) return;

    handleViewMode();
    window.addEventListener('resize', handleViewMode);
    return () => window.removeEventListener('resize', handleViewMode);
  }, [handleViewMode]);

  const renderItems = items.map((item, index) => (
    <FeatureCard
      key={item.id}
      className={clsx({
        [`content-${item.align}`]: !!item.align,
        [`size-${item.size}`]: !!item.size,
      })}
      item={item}
      tabIndex={index}
    />
  ));

  return (
    <section
      id={id}
      className={clsx(
        scss.wrapper,
        overlapPreviousComponent && scss.overlapPreviousComponent
      )}
      style={
        {
          '--image-width': `${imageSpecs?.imageWidth}`,
          '--image-height': `${imageSpecs?.imageHeight}`,
          '--overlap-height': `${overlapPortionHeight}`,
          '--image-mobile-width': `${imageMobileSpecs?.imageWidth}`,
          '--image-mobile-height': `${imageMobileSpecs?.imageHeight}`,
          '--overlap-mobile-height': `${
            overlapMobilePortionHeight ?? overlapPortionHeight
          }`,
        } as React.CSSProperties
      }>
      <div
        className={clsx(scss.header)}
        style={{
          backgroundImage:
            hero?.src[view] &&
            `url(${getStrapiImageSource(
              hero?.src[view] as types.ImageSource
            )})`,
        }}>
        <div className={clsx(scss.content, 'container')}>
          <h2
            className={clsx(scss.headline)}
            dangerouslySetInnerHTML={{ __html: headline?.text || '' }}
          />
          <h3 className={clsx(scss.subheadline)}>{subheadline?.text}</h3>
          <p className={clsx(scss.tagline)}>{tagline?.text}</p>{' '}
        </div>
      </div>

      <div className={clsx('container')}>
        <div className={clsx(scss.grid)}>{renderItems}</div>
      </div>
    </section>
  );
};
