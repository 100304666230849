import * as React from 'react';
import clsx from 'clsx';
import SwiperCore from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as types from 'src/types';
import useCarousel from 'src/hooks/useCarousel';
import { CategoryFilterBlurb } from './CategoryFilterBlurb';
import { Control } from '../../ui/carousel';
import * as scss from './CategoryFilter.module.scss';

export type CategoryFilterItemType = {
  id: string;
  idCategoryFilterItem: string;
  title: types.TextFields;
  excerpt: types.TextFields;
  backgroundImage: types.ImageFields;
  icon: types.ImageFields;
};

export type Props = {
  data: CategoryFilterItemType[];
  selected: string;
  setSelected: (id: string) => void;
};

export const CategoryFilter: React.FC<Props> = ({
  data,
  selected,
  setSelected,
}) => {
  // hooks
  const {
    isOverflowing,
    position,
    handleControl,
    onResize,
    onSlideChange,
    onSwiper,
  } = useCarousel();

  /**
   * Make active filter item the very first item on left if
   * possible when resizing.
   */
  const onCustomResize = React.useCallback(
    (swiper: SwiperCore) => {
      if (!swiper) return;

      onResize(swiper);

      // slide to current active filter
      const index = swiper.slides
        .map((obj) => obj.getAttribute('data-slide-id'))
        .indexOf(selected);

      swiper.slideTo(index);
    },
    [selected, onResize]
  );

  return (
    <div className={clsx(scss.categoryFilter)}>
      <div className={scss.categoryFilterInner}>
        <Swiper
          allowTouchMove={true}
          freeMode={true}
          slidesPerView={'auto'}
          onInit={onSwiper}
          onResize={onCustomResize}
          onSlideChange={onSlideChange}>
          {data.map(({ idCategoryFilterItem, title, icon }) => {
            const id = idCategoryFilterItem;
            return (
              <SwiperSlide key={`slide-${id}`} data-slide-id={id}>
                <div key={id} className={scss.categoryFilterItem}>
                  <CategoryFilterBlurb
                    id={id}
                    title={title.text}
                    icon={icon}
                    selected={id === selected}
                    setSelected={setSelected}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      {isOverflowing && (
        <Control.Control>
          <Control.Prev
            className={clsx('swiper-control-prev')}
            disabled={'start' === position}
            onClick={handleControl}
          />
          <Control.Next
            className={clsx('swiper-control-next')}
            disabled={'end' === position}
            onClick={handleControl}
          />
        </Control.Control>
      )}
    </div>
  );
};
