import * as React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import * as types from 'src/types';
import useCarousel from 'src/hooks/useCarousel';
import useView from 'src/hooks/useView';
import { TitlesType } from '../../Title/Title';
import { CategoryFilter, CategoryFilterItemType } from './CategoryFilter';
import { FilteredItems } from './FilteredItems';
import * as scss from './Categories.module.scss';
import { getStrapiImageSource } from '../../../utils/strapiDataHelpers';

export type Props = {
  children?: React.ReactNode;
  filters?: CategoryFilterItemType[];
  headline?: types.TextFields;
  id?: string;
  items?: TitlesType[];
  style?: React.CSSProperties;
  subheadline?: types.TextFields;
};

export const Categories = ({
  filters = [],
  headline,
  id,
  items: propsItems = [],
  style,
  subheadline,
}: Props) => {
  /**
   * Lazily initialize selected filter on mount and return filter id.
   */
  const [selectedFilter, setSelectedFilter] = React.useState(() => {
    if (
      filters.find(
        (filter) =>
          filter.idCategoryFilterItem ===
          window.location.hash.replace('#cat-', '')
      )
    )
      return window.location.hash.replace('#cat-', '');

    if (!filters.length) return '';
    return filters[0].idCategoryFilterItem;
  });

  // hooks
  const view = useView();
  const {
    activeItemOverlay,
    isOverflowing,
    position,
    swiperRef,
    handleControl,
    onResize,
    onSlideChange,
    onSwiper,
    onOverlayToggle,
  } = useCarousel();

  /**
   * Get current filter object.
   */
  const currentFilter = filters.find((filter) => {
    return filter.idCategoryFilterItem === selectedFilter;
  });

  React.useEffect(() => {
    if (swiperRef.current) onResize(swiperRef.current);
  }, [selectedFilter, onResize, swiperRef]);

  /**
   * Get items based on current filter.
   */
  const items = propsItems
    .filter((obj) => {
      if (!selectedFilter) return true;

      const matches = obj.categoryIDs?.filter((item) => {
        return item.categoryId === selectedFilter;
      });

      return matches && matches.length > 0;
    })
    .sort((a: TitlesType, b: TitlesType) => {
      const getOrder = (title: TitlesType) =>
        title.categoryIDs?.find(
          (category) => category.categoryId === selectedFilter
        )?.order || 0;

      const aOrder = getOrder(a);
      const bOrder = getOrder(b);

      // When equal keep the order the same
      if (aOrder === bOrder) return 0;

      // Order looks like 1, 3, 6, 6.2, 7, 0, 0, 0, -1, -4, -4.3, -5
      if (aOrder > 0 && bOrder > 0) return aOrder >= bOrder ? 1 : -1;
      if (aOrder > 0) return -1;
      if (bOrder > 0) return 1;

      return aOrder < bOrder ? 1 : -1;
    });

  /**
   * Update titles by it's 'categoryID'
   */
  React.useEffect(() => {
    if (!swiperRef.current) return;

    swiperRef.current?.slideTo(0, 0);
  }, [selectedFilter, swiperRef]);

  /**
   * Category excerpt layout.
   */
  const renderCategoryShowcase = (
    <div className={clsx(scss.showcaseWrapper)}>
      {types.Sizes.sm === view && (
        <TransitionGroup>
          <CSSTransition
            key={currentFilter?.id}
            {...{ in: true }}
            classNames="anim"
            timeout={1000}>
            <div
              className={clsx(scss.showcaseBg)}
              style={{
                backgroundImage: `url(${getStrapiImageSource(
                  currentFilter?.backgroundImage?.src?.lg as types.ImageSource
                )})`,
              }}></div>
          </CSSTransition>
        </TransitionGroup>
      )}

      <h2
        className={clsx(scss.showcaseTitle)}
        style={currentFilter?.title.style}>
        {currentFilter?.title.text}
      </h2>
      <p
        className={clsx(scss.showcaseExcerpt)}
        style={currentFilter?.excerpt.style}>
        {currentFilter?.excerpt.text}
      </p>
    </div>
  );

  return (
    <section id={id} className={clsx(scss.wrapper)} style={style}>
      {/* Preload images */}
      <Helmet>
        {filters.map((item) => (
          <link
            key={item.id}
            rel="preload"
            as="image"
            href={getStrapiImageSource(
              item.backgroundImage?.src?.lg as types.ImageSource
            )}
          />
        ))}
      </Helmet>

      <div className={clsx(scss.head, 'container container--fhd')}>
        <h2 className={clsx(scss.headline)} style={headline?.style}>
          {headline?.text}
        </h2>
        <p className={clsx(scss.subheadline)} style={subheadline?.style}>
          {subheadline?.text}
        </p>
      </div>

      <div className={clsx(scss.filters, 'container container--fhd')}>
        <CategoryFilter
          data={filters}
          selected={selectedFilter}
          setSelected={setSelectedFilter}
        />
      </div>

      <div className="container">
        {types.Sizes.sm === view && <div>{renderCategoryShowcase}</div>}
      </div>

      <div>
        <FilteredItems
          activeItemOverlay={activeItemOverlay}
          categoryShowcase={renderCategoryShowcase}
          currentFilter={currentFilter}
          isOverflowing={isOverflowing}
          items={items}
          position={position}
          handleControl={handleControl}
          onOverlayToggle={onOverlayToggle}
          onResize={onResize}
          onSlideChange={onSlideChange}
          onSwiper={onSwiper}
        />
      </div>
    </section>
  );
};
