// extracted by mini-css-extract-plugin
export var filters = "Categories-module--filters--m8plf";
export var head = "Categories-module--head--KavHt";
export var headline = "Categories-module--headline--vjv1M";
export var item = "Categories-module--item--rLiIB";
export var itemHeader = "Categories-module--item-header--kf6Ng";
export var itemInner = "Categories-module--item-inner--vuOKZ";
export var itemLast = "Categories-module--item-last--EhSRR";
export var list = "Categories-module--list--dioxa";
export var results = "Categories-module--results--3Nt-S";
export var resultsBg = "Categories-module--results-bg--sn1sj";
export var showcase = "Categories-module--showcase--gTHGC";
export var showcaseBg = "Categories-module--showcase-bg--mLsuo";
export var showcaseExcerpt = "Categories-module--showcase-excerpt--NFxVB";
export var showcaseTitle = "Categories-module--showcase-title--kp0X-";
export var showcaseWrapper = "Categories-module--showcase-wrapper--GyuP+";
export var subheadline = "Categories-module--subheadline--sttTa";
export var wrapper = "Categories-module--wrapper--2uJX9";