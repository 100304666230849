import * as React from 'react';
import clsx from 'clsx';
import useView from 'src/hooks/useView';
import * as types from 'src/types';
import {
  DownloadsWallpaperItem,
  DownloadsWallpaperItemType,
} from './DownloadsWallpaperItem';
import { StyledHeading } from '../ui/styledHeading/styledHeading';
import * as scss from './DownloadsWallpaper.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

export type Props = {
  data: {
    backgroundImage: types.ImageFields;
    headline: types.TextFields;
    content: DownloadsWallpaperItemType[];
  };
  id?: string;
};

export const DownloadsWallpaper = ({ data, id }: Props) => {
  // hooks
  const view = useView();

  const bkgdImg = getStrapiImageSource(
    data.backgroundImage.src[view] as types.ImageSource
  );
  const hasBkgdImg = bkgdImg !== '' && bkgdImg != null && bkgdImg !== undefined;

  // render
  return (
    <section id={id} className={clsx(scss.section)}>
      {hasBkgdImg && (
        <div className={clsx(scss.sectionBg)}>
          <img
            src={getStrapiImageSource(
              data.backgroundImage.src[view] as types.ImageSource
            )}
            alt={data.backgroundImage.alt}
          />
        </div>
      )}
      <div className={clsx(scss.container)}>
        <div className={clsx(scss.header)}>
          <StyledHeading>{data.headline.text}</StyledHeading>
        </div>
        <div className={clsx(scss.row)}>
          {data.content.map((item, i) => (
            <div key={i} className={clsx(scss.col)}>
              <DownloadsWallpaperItem data={item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
