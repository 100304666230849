import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type ContentProps = React.HTMLAttributes<HTMLDivElement>;

export const Content = ({ children, className, style }: ContentProps) => {
  return (
    <div className={clsx(scss.itemContent, className)} style={style}>
      {children}
    </div>
  );
};
