import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { canUseDOM } from 'exenv';
import * as types from 'src/types';
import { Button } from '../ui/button/button';
import { Item } from '../ui/carousel';
import { VideoOverlay } from '../ui/videoOverlay/videoOverlay';
import * as scss from './Title.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

type CategoryIDsType = {
  categoryId: string;
  order?: number;
};

export type TitlesType = {
  id: string;
  categoryIDs?: Array<CategoryIDsType>;
  button?: types.ButtonFields;
  description: types.TextFields;
  image: types.ImageFields;
  title: types.TextFields;
  genre?: types.TextFields;
  releaseDate?: string;
  releaseDateTitle?: string;
};

export type Props = {
  className?: string;
  headerClassName?: string;
  headerStyle?: React.CSSProperties;
  data: TitlesType;
  toggle: types.ToggleState;
  onToggle?: React.Dispatch<HTMLDivElement | null>;
};

export const Title = ({
  className,
  headerClassName,
  headerStyle,
  data,
  toggle: propsToggle = types.ToggleStates.close,
  onToggle,
}: Props) => {
  // states
  const [modalOpen, setModalOpen] = React.useState(false);
  const [overlayOpen, setOverlayOpen] = React.useState<types.ToggleState>(
    propsToggle
  );

  // props
  const {
    button,
    title,
    description,
    image,
    genre,
    releaseDate = '',
    releaseDateTitle = '',
  } = data;

  // transform 'releaseDate' to date object
  const dateRelease = moment(releaseDate);

  // title data
  const headerText = dateRelease.isValid()
    ? !dateRelease.isAfter()
      ? releaseDateTitle || 'AVAILABLE NOW'
      : title.text.replace('{date}', dateRelease.format('M/D')) ||
        `PREMIERES ${dateRelease.format('M/D')}`
    : title.text || ' ';

  // refs
  const ref = React.createRef<HTMLDivElement>();
  const timerId = React.useRef<number>();

  /**
   * Handle item overlay toggling on click
   */
  const handleOnClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setOverlayOpen(types.ToggleStates.open);

      onToggle?.(ref.current);
    },
    [onToggle, ref]
  );

  /**
   * Handle item overlay when clicked outside
   */
  const handleBlur = React.useCallback(() => {
    timerId.current = window.setTimeout(() => {
      if (types.ToggleStates.open === overlayOpen) {
        setOverlayOpen(types.ToggleStates.close);
        onToggle?.(ref.current);
      }
    }, 0);
  }, [onToggle, ref, overlayOpen]);

  /**
   * Toggle overlay when inner element is focused
   */
  const handleFocus = () => {
    window.clearTimeout(timerId.current);
  };

  /**
   * Remain item active state when focus is inside the component
   */
  const handleKeyboardNavigation = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== 'Escape') return;

      setOverlayOpen(types.ToggleStates.close);
      onToggle?.(ref.current);
    },
    [onToggle, ref]
  );

  /**
   * Bind navigation event to `window`
   */
  React.useEffect(() => {
    if (!canUseDOM) return;

    window.addEventListener('keydown', handleKeyboardNavigation);
    return () =>
      window.removeEventListener('keydown', handleKeyboardNavigation);
  }, [handleKeyboardNavigation]);

  /**
   * Toggle video overlay
   */
  const handleVideoOverlay = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();

    setOverlayOpen(types.ToggleStates.close);
    onToggle?.(ref.current);
    setModalOpen(true);
  };

  React.useEffect(() => {
    setOverlayOpen(propsToggle);
  }, [propsToggle]);

  const renderButton = () => {
    if (!button) return null;

    if (button.type === types.ButtonTypes.overlay) {
      return (
        <Button
          outline
          className={clsx(scss.itemBtn)}
          icon={['far', 'play-circle']}
          label={button.text}
          mode={button.mode}
          style={button.style}
          tag="button"
          type={button.type}
          onClick={handleVideoOverlay}
        />
      );
    }

    return (
      <Button
        outline
        className={clsx(scss.itemBtn)}
        href={button.link}
        label={button.text}
        mode={button.mode}
        style={button.style}
        tag="a"
        type={button.type}
        variant="primary"
      />
    );
  };

  return (
    <Item.Item
      ref={ref}
      className={clsx(
        scss.item,
        { 'is-active': types.ToggleStates.open === overlayOpen },
        className
      )}
      onMouseOver={() => setOverlayOpen(types.ToggleStates.open)}
      onMouseOut={() => setOverlayOpen(types.ToggleStates.close)}
      onBlur={handleBlur}
      onFocus={handleFocus}>
      <Item.Wrapper>
        <Item.OverlayToggle onClick={handleOnClick} />
        <Item.Header className={clsx(headerClassName)} style={headerStyle}>
          <Item.Title>{headerText}</Item.Title>
        </Item.Header>
        <Item.Content>
          <Item.Thumbnail
            src={getStrapiImageSource(image.src.lg as types.ImageSource) ?? ''}
            alt={image.alt}
          />
          <Item.OverlayContent>
            {genre && <Item.Genre>{genre.text}</Item.Genre>}
            <Item.Excerpt style={description.style}>
              {description.text}
            </Item.Excerpt>

            {renderButton()}

            {button && button.type === types.ButtonTypes.overlay && (
              <VideoOverlay
                title={button.overlayTitle ?? ''}
                url={button.link}
                open={modalOpen}
                setOpen={setModalOpen}
              />
            )}
          </Item.OverlayContent>
        </Item.Content>
      </Item.Wrapper>
    </Item.Item>
  );
};
