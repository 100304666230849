// extracted by mini-css-extract-plugin
export var btn_action = "Game-module--btn_action--h9aBC";
export var category = "Game-module--category--BAOi+";
export var categoryWheel = "Game-module--category-wheel--mjhqY";
export var categoryWheelInner = "Game-module--category-wheel-inner--P0XB5";
export var categoryWheelItem = "Game-module--category-wheel-item--bibcP";
export var categoryWheelItemActive = "Game-module--category-wheel-item-active--ncMo-";
export var categoryWheelItemDone = "Game-module--category-wheel-item-done--wC4K9";
export var choiceHeading = "Game-module--choice-heading--lRROo";
export var choiceList = "Game-module--choice-list--4thOb";
export var clue = "Game-module--clue--58bvz";
export var colLeft = "Game-module--col-left--VTMrd";
export var colRight = "Game-module--col-right--hu9Y1";
export var console = "Game-module--console--1M1BD";
export var container = "Game-module--container--mmVFt";
export var content = "Game-module--content--HbrY4";
export var controlList = "Game-module--control-list--qRBLD";
export var cta = "Game-module--cta--LyYRM";
export var featImgSpacer = "Game-module--feat-img-spacer--66l0L";
export var heading = "Game-module--heading--mYVXP";
export var helper = "Game-module--helper--X-8Iz";
export var progress = "Game-module--progress--pbS+Z";
export var question = "Game-module--question--z5uzs";
export var screenGame = "Game-module--screen-game--j96-I";
export var screenGameGrid = "Game-module--screen-game-grid--2rR3w";
export var screenGameGridItem = "Game-module--screen-game-grid-item--IDGqT";
export var screenGameOver = "Game-module--screen-game-over--9KZwD";
export var wrapper = "Game-module--wrapper--aiGOl";