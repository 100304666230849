import * as React from 'react';
import * as ReactDOM from 'react-dom';
import clsx from 'clsx';

import ReactPlayer from 'react-player/lazy';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as style from './videoOverlay.module.scss';

export type Props = {
  title: string;
  url: string;
  open: boolean;
  setOpen: (boolean: boolean) => void;
  type?: string;
  muted?: boolean;
};

export const VideoOverlay: React.FC<Props> = ({
  open,
  title,
  url,
  setOpen,
  type,
  muted = false,
}) => {
  // state
  const [mounted, setMounted] = React.useState(false);

  // refs
  const videoOverlayRef = React.useRef(null);
  const playerRef = React.useRef(null);

  // hooks
  React.useEffect(() => {
    setMounted(true);
  }, []);

  React.useEffect(() => {
    if (open) {
      document.body.classList.add('body-overflow-hidden');
    } else {
      document.body.classList.remove('body-overflow-hidden');
    }
  }, [open]);

  // functions
  function handleOutsideClick(evt: React.MouseEvent) {
    if (evt.target === videoOverlayRef.current) {
      setOpen(false);
    }
  }

  function sendEvent(action: string) {
    const isBrowser = typeof window !== 'undefined';

    // GA_TAG_EVENT
    if (isBrowser) {
      window.dataLayer.push({
        event: 'gtm.custom_event',
        eventInfo: {
          title: title,
          url: url,
          category: 'Video',
          action: action,
        },
      });
    }
  }

  React.useEffect(() => {
    const onEscKey = (event: KeyboardEvent) =>
      event.key === 'Escape' && setOpen(false);

    window.addEventListener('keydown', onEscKey);

    return () => {
      window.removeEventListener('keydown', onEscKey);
    };
  }, [setOpen]);

  return mounted
    ? ReactDOM.createPortal(
        <CSSTransition {...{ in: open }} timeout={300} unmountOnExit={true}>
          <dialog
            ref={videoOverlayRef}
            className={clsx(style.wrapper, type === 'large' && 'is_large')}
            tabIndex={-1}
            aria-labelledby={title}
            onClick={handleOutsideClick}>
            <div className={clsx(style.dialog)} role="document">
              <div className={clsx(style.content)}>
                <div className={clsx(style.body)}>
                  <button
                    className={clsx(style.close)}
                    onClick={() => setOpen(false)}>
                    <FontAwesomeIcon
                      className={clsx(style.closeIcon)}
                      icon={['fas', 'times']}
                    />
                  </button>
                  <h5 className={clsx(style.title)}>{title}</h5>
                  <div className={clsx(style.player)}>
                    {url && (
                      <ReactPlayer
                        ref={playerRef}
                        url={url.replace(
                          /(https?:\/\/)(www\.)?youtube\.com\/watch\?v=|https?:\/\/youtu\.be\//g,
                          'https://www.youtube-nocookie.com/embed/'
                        )}
                        width="100%"
                        height="auto"
                        controls={true}
                        playing={true}
                        playsinline={true}
                        muted={muted}
                        config={{
                          youtube: {
                            playerVars: {
                              enablejsapi: 1,
                              noCookie: true,
                            },
                            embedOptions: {
                              host: 'https://www.youtube-nocookie.com',
                            },
                          },
                        }}
                        onPlay={() => {
                          sendEvent('Play');
                        }}
                        onPause={() => {
                          sendEvent('Pause');
                        }}
                        onEnded={() => {
                          sendEvent('Ended');
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </dialog>
        </CSSTransition>,
        document.querySelector('#modal-root') as Element
      )
    : null;
};
