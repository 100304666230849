/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

// Home Page Components
import { Carousel } from './Carousel/Carousel';
import { Titles } from './Carousels/Titles/Titles';
import { Promo as PromoSeparated } from './Promos/Separated/Promo';
import PromoLocales from './Promos/Separated/PromoLocales';
import { PromoCombined } from './Promos/PromoCombined/PromoCombined';
import { Feature } from './Feature/Feature';
import { Categories } from './Carousels/Categories/Categories';
import { Spotlight } from './Carousels/Spotlight/Spotlight';
import { CoachsCorner } from './CoachsCorner/CoachsCorner';
import { HBOPagePromo } from './HBOPagePromo/HBOPagePromo';
import { HBOPromo } from './HBOPromo/HBOPromo';

// Sell Page Components
import { SellHeader } from './SellHeader/SellHeader';
import { DeviceAvailability } from './DeviceAvailability/DeviceAvailability';
import { HowToAccess } from './Carousels/HowToAccess/HowToAccess';
import { FaqSell as FAQ } from './Faq/FaqSell/FaqSell';

// Training Page Components
import { VideoPromos } from './Promos/VideoPromos/VideoPromos';

// Fun Page Components
import { FunHeader } from './FunHeader/FunHeader';
import { WordScramble } from './Games/WordScramble/WordScramble';
import WhatsTheTitle from './Games/WhatsTheTitle/WhatsTheTitle';
import PersonalityQuiz from './Games/PersonalityQuiz/PersonalityQuiz';
import MatchGame from './Games/MatchGame/MatchGame';
import RandomTrivia from './Games/RandomTrivia/RandomTrivia';
import OverOrUnder from './Games/OverOrUnder/OverOrUnder';
import SceneIt from './Games/SceneIt/SceneIt';
import { DownloadsWallpaper } from './DownloadsWallpaper/DownloadsWallpaper';
import { DownloadsImages } from './DownloadsImages/DownloadsImages';

// Generic Components
import CTAButton from './CTAButton/CTAButton';
import VideoOverlayOnLoad from './ui/videoOverlay/VideoOverlayOnLoad';
import { ARPromo } from './ARPromo/ARPromo';
import { ARContestWinners } from './ARContestWinners/ARContestWinners';
import { SeasonalPromo } from './SeasonalPromo/SeasonalPromo';
import { WaterCooler } from './WaterCooler/WaterCooler';

type Props = {
  pageData: any;
};

const PageContentHandler: React.FC<Props> = ({ pageData }) => {
  return (
    <>
      {pageData &&
        pageData.content &&
        pageData?.content?.map((itemData: any) => {
          const itemType = itemData.__typename;
          switch (itemType) {
            // HOME PAGE COMPONENTS

            case 'ComponentComponentsSeasonalPromo':
              return (
                <SeasonalPromo
                  key={'seasonal-promo-' + itemData.idSeasonalPromo}
                  id={'seasonal-promo-' + itemData.idSeasonalPromo}
                  {...itemData}
                />
              );
              break;

            // HOME PAGE COMPONENTS

            case 'ComponentComponentsArPromo':
              return (
                <ARPromo
                  key={'carousel-ar-promo-' + itemData.idARPromo}
                  id={itemData.idARPromo}
                  backgroundImage={itemData.backgroundImage}
                  headerLogo={itemData.headerLogo}
                  innerText={itemData.innerText}
                  innerMobileText={itemData.innerMobileText}
                  offset={itemData.offset}
                  mobileOffset={itemData.mobileOffset}
                  mobileCTAButton={itemData.mobileCTAButton}
                />
              );
              break;

            case 'ComponentComponentsArContestWinners':
              return (
                <ARContestWinners
                  key={
                    'carousel-ar-contest-winners-' + itemData.idARContestWinners
                  }
                  id={itemData.idARContestWinners}
                  backgroundImage={itemData.backgroundImage}
                  headerImage={itemData.headerImage}
                  winners={itemData.winners}
                  innerText={itemData.innerText}
                  innerMobileText={itemData.innerMobileText}
                />
              );
              break;

            case 'ComponentComponentsFeatureCarousel':
              return (
                <Carousel
                  key={'carousel-' + itemData.id}
                  items={itemData.items}
                  duration={itemData.duration}
                />
              );
              break;

            case 'ComponentComponentsTitles':
              return (
                <Titles
                  key={'titles-' + itemData.id}
                  id="new-titles"
                  headline={itemData.headline}
                  items={itemData.items}
                  subheadline={itemData.subheadline}
                />
              );
              break;

            case 'ComponentComponentsPromoSeparated':
              return (
                <PromoSeparated
                  key={'promo-separated-' + itemData.id}
                  id={`featured-promo-${itemData.id}`}
                  data={itemData.data}
                />
              );
              break;

            case 'ComponentComponentsPromoLocales':
              return (
                <PromoLocales
                  key={'promo-locales-' + itemData.id}
                  data={itemData}
                />
              );
              break;

            case 'ComponentComponentsPromoCombined':
              return (
                <PromoCombined
                  key={'promo-combined-' + itemData.id}
                  id={`featured-promo-${itemData.id}`}
                  data={itemData.data}
                />
              );
              break;

            case 'ComponentComponentsFeature':
              return (
                <Feature
                  key={'feature-' + itemData.id}
                  id="featured-titles"
                  overlapPreviousComponent={itemData.overlapPreviousComponent}
                  overlapPortionHeight={itemData.overlapPortionHeight}
                  overlapMobilePortionHeight={
                    itemData.overlapMobilePortionHeight
                  }
                  headline={itemData.headline}
                  hero={itemData.hero}
                  items={itemData.items}
                  subheadline={itemData.subheadline}
                  tagline={itemData.tagline}
                />
              );
              break;

            case 'ComponentComponentsCategories':
              return (
                <Categories
                  key={'categories-' + itemData.id}
                  id="selected-categories"
                  filters={itemData.filters}
                  headline={itemData.headline}
                  items={itemData.items}
                  subheadline={itemData.subheadline}
                />
              );
              break;

            case 'ComponentComponentsSpotlight':
              return (
                <Spotlight
                  key={'spotlight-' + itemData.id}
                  id="spotlight"
                  data={itemData}
                  items={itemData.items}
                  sectionStyle={{
                    background: {
                      sm: itemData.backgroundImage?.src.sm.url,
                      lg: itemData.backgroundImage?.src.lg.url,
                    },
                  }}
                />
              );
              break;

            case 'ComponentComponentsCoachsCorner':
              return (
                <CoachsCorner
                  key={'coachs-corner-' + itemData.id}
                  id="coach-corner"
                  data={itemData.data}
                />
              );
              break;

            case 'ComponentComponentsWaterCooler':
              return (
                <WaterCooler
                  key={'water-cooler-' + itemData.id}
                  id="water-cooler"
                  data={itemData.data}
                />
              );
              break;

            case 'ComponentComponentsHboPromo':
              return (
                <HBOPromo key={'hbo-promo-' + itemData.id} {...itemData} />
              );
              break;

            case 'ComponentComponentsHboPagePromo':
              return (
                <HBOPagePromo
                  key={'hbo-page-promo-' + itemData.id}
                  {...itemData}
                />
              );
              break;

            // SELL PAGE COMPONENTS

            case 'ComponentComponentsSellHeader':
              return (
                <SellHeader
                  key={'sell-header' + itemData.id}
                  id="how-to-sign-up"
                  description={itemData.description}
                  featuredImage={itemData.featuredImage}
                  headline={itemData.headline}
                  subheadline={itemData.subheadline}
                  // drawer={genericData.drawer}
                />
              );
              break;

            case 'ComponentComponentsHowToAccessCarousel':
              return (
                <HowToAccess
                  key={'how-to-access-' + itemData.id}
                  id="how-to-access"
                  headline={itemData.headline}
                  footer={itemData.footer}
                  dataPhoneComputer={itemData.items[0]}
                  dataTV={itemData.items[1]}
                />
              );
              break;

            case 'ComponentComponentsDeviceAvailability':
              return (
                <DeviceAvailability
                  key={'device-availability-' + itemData.id}
                  id="available-devices"
                  data={itemData}
                />
              );
              break;

            case 'ComponentComponentsFaq':
              return (
                <FAQ
                  key={'faq-' + itemData.id}
                  id={itemData.idFAQ || 'faq'}
                  items={itemData.items}
                  headline={itemData.headline}
                />
              );
              break;

            // TRAINING PAGE COMPONENTS

            case 'ComponentComponentsVideoPromosList':
              return (
                <VideoPromos
                  key={'video-promos-' + itemData.id}
                  {...(itemData as any)}
                />
              );

            // FUN PAGE COMPONENTS

            case 'ComponentComponentsFunHeader':
              return (
                <FunHeader
                  key={'fun-header-' + itemData.id}
                  data={itemData as any}
                />
              );
              break;

            case 'ComponentComponentsWordScramble':
              return (
                <WordScramble
                  key={'word-scramble-' + itemData.id}
                  id="activity-section"
                  {...(itemData as any)}
                />
              );
              break;

            case 'ComponentComponentsGuessTitle':
              return (
                <WhatsTheTitle
                  key={'guess-the-title-' + itemData.id}
                  data={itemData}
                />
              );

            case 'ComponentComponentsPersonalityQuiz':
              return (
                <PersonalityQuiz
                  key={'personality-quiz-' + itemData.id}
                  data={itemData}
                />
              );

            case 'ComponentComponentsRandomTrivia':
              return (
                <RandomTrivia
                  key={'random-trivia-' + itemData.id}
                  data={itemData as any}
                />
              );
              break;

            case 'ComponentComponentsOverUnderGame':
              return (
                <OverOrUnder
                  key={'over-or-under-' + itemData.id}
                  id="activity-section"
                  data={itemData}
                />
              );
              break;

            case 'ComponentComponentsSceneItGame':
              return (
                <SceneIt
                  key={'scene-it-' + itemData.id}
                  id="activity-section"
                  data={itemData}
                />
              );
              break;

            case 'ComponentComponentsMatchGame':
              return (
                <MatchGame
                  key={'match-game-' + itemData.id}
                  id="activity-section"
                  data={itemData}
                />
              );
              break;

            case 'ComponentComponentsDownloadsWallpaper':
              return (
                <DownloadsWallpaper
                  key={'wallpaper-' + itemData.id}
                  id="downloads"
                  data={itemData as any}
                />
              );
              break;

            case 'ComponentComponentsDownloadsImages':
              return (
                <DownloadsImages
                  key={'download-images-' + itemData.id}
                  id="downloads"
                  data={itemData as any}
                />
              );
              break;

            // GENERIC COMPONENTS
            case 'ComponentComponentsCtaButton':
              return (
                <CTAButton
                  key={'cta-button-' + itemData.id}
                  id={'cta-button-' + itemData.id}
                  data={itemData as any}
                />
              );
              break;

            case 'ComponentComponentsVideoOnload':
              return (
                <VideoOverlayOnLoad
                  key={'video-onload-' + itemData.id}
                  id={'video-onload-' + itemData.id}
                  data={itemData as any}
                />
              );
          }
        })}
    </>
  );
};

export default PageContentHandler;
