import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import * as Accordion from '../../ui/accordion';
import * as scss from './FaqSell.module.scss';

export type Props = {
  id?: string;
  headline?: types.TextFields;
  items: Accordion.ItemType[];
};

export const FaqSell = ({ id, headline, items = [] }: Props) => {
  return (
    <section id={id} className={clsx(scss.wrapper)}>
      <div className="container">
        <h2 className={clsx(scss.headline)} style={headline?.style}>
          {headline?.text}
        </h2>
      </div>

      <div className={clsx(scss.grid, 'container')}>
        <Accordion.Accordion light className={clsx(scss.col)} items={items} />
      </div>
    </section>
  );
};
