import React from 'react';

import * as scss from './Rules.module.scss';

type Props = {
  scrollIntoView?: boolean;
  setScrollIntoView?: (scrollIntoView: boolean) => void;
};

const Rules = ({ scrollIntoView, setScrollIntoView }: Props) => {
  const titleRef = React.useRef<HTMLHeadingElement>(null);

  React.useEffect(() => {
    if (scrollIntoView && setScrollIntoView) {
      titleRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      setScrollIntoView(false);
    }
  }, [scrollIntoView, setScrollIntoView]);

  return (
    <div className={scss.container} id="rules">
      <h2 ref={titleRef} className={scss.title}>
        WONKA SWEET-STAKES
        <br />
        OFFICIAL RULES
        <br />
        NO PURCHASE OR SALE NECESSARY
      </h2>

      <ol>
        <li>
          <h3 className={scss.subtitle}>SWEEPSTAKES TIMING:</h3> The Wonka
          Sweet-Stakes (the “Sweepstakes”) begins at 12:00 AM Eastern Time
          (“ET”) on 3/8/24 and continues through 11:59 PM ET on 4/8/24 (the
          “Sweepstakes Period”).
        </li>
        <li>
          <h3 className={scss.subtitle}>ELIGIBILITY:</h3> To be eligible for the
          Sweepstakes, you must be: <b>{'a)'}</b> directly employed or
          contracted by a Max distributor/partner in a point-of-sale capacity -
          including but not limited to Customer Service, Sales, Retention,
          Retail, Telemarketing, Tech Support, and Billing from date of entry
          through date of prize notification, <b>{'b)'}</b> an original intended
          recipient of the Sweepstakes invitation (via email or your employer’s
          Intranet), <b>{'c)'}</b> a legal resident of the 50 United States or
          Washington, D.C., and <b>{'d)'}</b> 18 years of age or older at time
          of entry. Void where prohibited or restricted by law. All applicable
          laws and regulations apply.
        </li>
        <li>
          <h3 className={scss.subtitle}>HERE'S HOW IT WORKS:</h3> Visit the
          Sweepstakes website indicated within the Sweepstakes invitation you
          received from Sponsor (via email or your employer’s Intranet) and
          follow the on-screen directions to open Max Resources on your computer
          and enter your name, city, state, work email address, company name and
          supervisor name to complete the Sweepstakes registration form. All
          entries must be received by 11:59 PM ET on 4/8/24. Limit one (1) entry
          per person/email address per day.
        </li>
        <li>
          <h3 className={scss.subtitle}>RANDOM DRAWING:</h3> A random drawing
          will be conducted on or about 4/12/24 from among all eligible entries
          received, by Sponsor’s designee, Don Jagoda Associates, Inc., an
          independent judging organization whose decisions are final and binding
          in all respects relating to the drawing. Odds of winning will depend
          on the number of eligible entries received. Limit one (1) prize per
          household.
        </li>
        <li>
          <h3 className={scss.subtitle}>VERIFICATION:</h3> Potential winners
          will be notified by email using the email address submitted with their
          entry. If required for prize fulfillment, a response to such email
          must be received within ten (10) business days after initial email was
          sent or potential winner may be disqualified and an alternate
          potential winner may be selected. Noncompliance within the
          aforementioned time periods may result in disqualification and an
          alternate potential winner may be selected. Noncompliance within this
          time period may result in disqualification without further notice and
          an alternate may be selected. Sponsor assumes no responsibility for
          undeliverable email resulting from any form of active or passive
          filtering by email service providers or for insufficient space in
          user’s account to receive email. Return of any email or prize as
          undeliverable will result in disqualification and an alternate will be
          selected.
        </li>

        <li>
          <h3 className={scss.subtitle}>
            PRIZES AND APPROXIMATE RETAIL VALUES (“ARV”): Ten (10) Grand Prizes:
          </h3>{' '}
          – A Max Wonka Pack, consisting of the following Wonka branded items:
          an apron, a beanie, a chocolate bomb, a lapel pin, a mug, an oven
          mitt, a pair of socks, a sweatshirt (specific size to be specified by
          Sponsor), and two Wonka chocolate bars. (ARV: $100 per prize)
        </li>

        <li>
          <h3 className={scss.subtitle}>PRIZE CONDITIONS:</h3> Prizes consist of
          only the items specifically listed as part of the prize. All
          applicable income taxes are the sole responsibility of prize winners.
          No substitution or transfer of prize by winner permitted. Sponsor
          reserves the right to substitute prize or prize component for one of
          equal or greater value, in their sole discretion. Acceptance of prize
          constitutes permission to the Sponsor to use winners’ names and
          likenesses for purposes of advertising and trade without further
          compensation, unless prohibited by law. By participating in this
          Sweepstakes, entrants agree to be bound by these Official Rules and
          the decisions of Sponsor and Don Jagoda Associates, Inc. By accepting
          a prize, winners agree to hold the Sponsor harmless against any and
          all claims and liability arising out of participation in this
          Sweepstakes or use/misuse of the prize, including without limitation
          any claims for personal injury (including death), property damage, or
          violation of any intellectual property, privacy, publicity and/or
          other proprietary rights. Winners assume all liability for any injury
          or damage caused, or claimed to be caused by participation in this
          Sweepstakes or use or redemption of prize.{' '}
          <b>CLAIMS MAY NOT BE RESOLVED THROUGH ANY FORM OF CLASS ACTION.</b>{' '}
          Sponsor not responsible for typographical or other error in the
          printing of the offer, administration of the Sweepstakes or in the
          announcement of any prize. In the event there is a discrepancy or
          inconsistency between disclosures or other statements contained in any
          sweepstakes materials and the terms and conditions of the Official
          Rules, the Official Rules shall prevail, govern and control. In no
          event will more than the number of prizes stated in these Official
          Rules be awarded.
        </li>

        <li>
          <h3 className={scss.subtitle}>GENERAL RULES:</h3> Proof of submission
          is not proof of receipt. Sponsor not responsible for incomplete, lost,
          late, damaged, or misdirected mail/e-mail or for any technical
          problems, malfunctions of any telephone lines, computer systems,
          servers, providers, hardware/software, lost or unavailable network
          connections or failed, incomplete, garbled or delayed computer
          transmission or any combination thereof. Sponsor not responsible for
          any damage to any participant’s computer system/software related to or
          resulting from participation or downloading any materials in this
          Sweepstakes. CAUTION: ANY ATTEMPT BY AN ENTRANT TO DELIBERATELY DAMAGE
          ANY WEB SITE OR UNDERMINE THE LEGITIMATE OPERATION OF THIS PROMOTION
          IS A VIOLATION OF CRIMINAL AND CIVIL LAW. SHOULD SUCH AN ATTEMPT BE
          MADE, SPONSOR RESERVES THE RIGHT TO DISQUALIFY AND SEEK DAMAGES FROM
          ANY SUCH INDIVIDUAL TO THE FULLEST EXTENT PERMITTED BY LAW. If an
          entrant submits more than the stated number of entries permitted,
          and/or if the Sponsor suspects that an entrant attempted to obtain
          additional entries by using multiple email addresses, identities or
          any other method, all entries submitted by the entrant will be
          declared null and void. Any suspected use of robotic, automatic,
          programmed, or the like methods of participation will void all such
          submissions by such methods. Sweepstakes may be cancelled, modified,
          terminated or suspended if infected by computer virus, bugs,
          tampering, unauthorized intervention, fraud, technical failures or any
          other causes outside control of Sponsor which impairs or affects the
          administration, security, fairness, integrity, or proper conduct of
          the Sweepstakes. Sponsor reserves the right at its sole discretion to
          disqualify any individual who tampers with any entry process or who
          harasses any other entrant or any Sponsor representative and to
          cancel, modify, terminate or suspend the Sweepstakes. In the event of
          termination, the random drawing will be held to select the winners
          from all eligible entries received prior to the time of termination.
          Sponsor is WarnerMedia Direct, LLC, 30 Hudson Yards, New York, NY
          10001.
        </li>

        <li>
          <h3 className={scss.subtitle}>WINNERS LIST:</h3> For the names of the
          winners, available after 5/24/24, visit{' '}
          <a href="/sweepstakes/winners" target="_blank">
            https://maxresources.com/sweepstakes/winners
          </a>
          .
        </li>

        <li>
          <h3 className={scss.subtitle}>PRIVACY POLICY:</h3> Sponsor will be
          collecting personal data about entrants online, in accordance with its
          privacy policy. Please review the Sponsor's privacy policy at{' '}
          <a
            href="https://www.max.com/privacy"
            target="_blank"
            rel="noreferrer">
            https://www.max.com/privacy
          </a>
          . By participating in the Sweepstakes, entrants hereby agree to
          Sponsor's collection and usage of their personal information and
          acknowledge that they have read and accepted Sponsor's privacy policy.
        </li>
      </ol>

      <p>© 2024 WarnerMedia Direct LLC. All rights reserved</p>
    </div>
  );
};

export default Rules;
