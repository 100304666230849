import * as React from 'react';
import * as scss from './pageTitle.module.scss';

export const PageTitle: React.FC<Props> = (props) => {
  const { title } = props;

  return <h1 className={scss.title}>{title}</h1>;
};

interface Props {
  title: string;
}
