// extracted by mini-css-extract-plugin
export var control = "carousel-module--control--rwjxd";
export var controlIcon = "carousel-module--control-icon--G3KM2";
export var controlNext = "carousel-module--control-next--6W3wh";
export var controlPrev = "carousel-module--control-prev--vCsYE";
export var controls = "carousel-module--controls--2JpBz";
export var item = "carousel-module--item--BhQQM";
export var itemContent = "carousel-module--item-content--m3fYO";
export var itemExcerpt = "carousel-module--item-excerpt--TDuQk";
export var itemGenre = "carousel-module--item-genre--z-rLB";
export var itemHeader = "carousel-module--item-header--mfX2x";
export var itemOverlayContent = "carousel-module--item-overlay-content--YKEqT";
export var itemThumb = "carousel-module--item-thumb--vEyaj";
export var itemTitle = "carousel-module--item-title--4iBpd";
export var itemToggle = "carousel-module--item-toggle--aZYyb";
export var itemToggler = "carousel-module--item-toggler--0POo+";
export var itemWrapper = "carousel-module--item-wrapper--0CO3z";
export var list = "carousel-module--list--8iBWa";
export var nav = "carousel-module--nav--LxQRO";
export var navBtn = "carousel-module--nav-btn--VZ-OK";
export var navItem = "carousel-module--nav-item--24apE";
export var navLabel = "carousel-module--nav-label--AYttJ";
export var track = "carousel-module--track--klsqK";