import * as types from '../types';

export const getStrapiImageSource = (
  srcItem?: types.ImageSource | types.ImageSource[]
) => {
  if (!srcItem) return;

  if (Array.isArray(srcItem)) {
    return srcItem[0]?.url;
  } else {
    return srcItem?.url;
  }
};
