import * as React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import * as types from 'src/types';
import useView from 'src/hooks/useView';
import {
  WordScrambleItem,
  WordScrambleItemType,
  FormData,
  Status,
  Where,
} from './WordScrambleItem';
import { WordScrambleFinal } from './WordScrambleFinal';
import * as scss from './WordScramble.module.scss';
import { getStrapiImageSource } from '../../../utils/strapiDataHelpers';
import { shuffleString } from 'src/utils/shuffleString';

type ItemType = { status?: Status } & WordScrambleItemType;

export type Promotion = {
  headline: types.TextFields;
  image: types.ImageFields;
  subheadline: types.TextFields;
};

export type Props = {
  headline: types.TextFields;
  id?: string;
  items: ItemType[];
  promotion: Promotion;
};

export const WordScramble = ({
  headline,
  id,
  items: itemProps = [],
  promotion,
}: Props) => {
  const [viewport, setViewport] = React.useState<types.Size>('lg');

  /**
   * Set new item property `status` for each question item.
   */
  const [items, setItems] = React.useState<ItemType[]>(() =>
    itemProps.map((item) => ({
      ...item,
      idWordScrambleItem: shuffleString(item.keyword.text).toUpperCase(),
      status: 'ask',
    }))
  );

  /**
   * Show question base on current index.
   */
  const [currentIndex, setCurrentIndex] = React.useState(0);

  /**
   * Determine whenever all questions are answered/skipped then show last view.
   */
  const [isFinal, setIsFinal] = React.useState(false);

  const [ariaLive, setAriaLive] = React.useState('');

  // hooks
  const view = useView();

  /**
   * Get current question item base on `currentIndex`.
   */
  const currentItem = React.useMemo(() => items[currentIndex], [
    currentIndex,
    items,
  ]);

  /**
   * Enable back or forward navigation on questions.
   * Each call sets multiple states that updates each item status and when last
   * item is reached, should show another view that shows the results or
   * could be something else.
   */
  const handleNavigate = React.useCallback(
    (where: Where) => {
      /**
       * Sets currently active item index.
       */
      setCurrentIndex((prevState) => {
        if ('skip' === where) return prevState;

        if ('prev' === where) {
          return prevState > 0 ? prevState - 1 : 0;
        }

        return prevState !== items.length - 1 ? prevState + 1 : prevState;
      });

      /**
       * Add or update each item status.
       */
      setItems((items) =>
        items.map((item, index) => {
          if (currentIndex !== index) return item;
          if ('next' === where) return item;

          if ('skip' === where) {
            return { ...item, status: 'skip' };
          }

          return { ...item, status: 'correct' };
        })
      );

      /**
       * Skip showing final view when maximum item length is not met.
       */
      if ('next' !== where) return;
      if (currentIndex !== items.length - 1) return;

      setIsFinal(true);
    },
    [currentIndex, items.length]
  );

  /**
   * Validate and set item status on form submission.
   */
  const handleSubmit = React.useCallback(
    (data: FormData) => {
      setItems((items) =>
        items.map((item, index) => {
          if (currentIndex !== index) return item;
          return { ...item, status: 'correct' };
        })
      );
    },
    [currentIndex]
  );

  /**
   * Provide the ability to reset the game.
   */
  const handleReset = () => {
    setIsFinal(false);
    setCurrentIndex(0);
    setItems(() => {
      return itemProps.map((item) => ({
        ...item,
        idWordScrambleItem: shuffleString(item.keyword.text).toUpperCase(),
        status: 'ask',
      }));
    });
  };

  React.useEffect(() => {
    // GA_TAG_EVENT
    const isBrowser = typeof window !== 'undefined';

    if (!isBrowser) return;

    if (isFinal) {
      window?.dataLayer?.push({
        event: 'Word Scramble Game',
        question: 'Game Over',
      });
    }
  }, [isFinal]);

  React.useEffect(() => {
    if (!isFinal) {
      if (currentItem.status === 'ask') {
        setAriaLive(`Question ${currentIndex + 1} of ${items.length}: ${
          currentItem.question.text
        }. HINT: The answer uses these
    letters: ${currentItem.idWordScrambleItem}.`);
      } else if (currentItem.status === 'correct') {
        setAriaLive('Correct! Click Next to continue');
      } else if (currentItem.status === 'incorrect') {
        setAriaLive('Incorrect! Please try again, or click Skip');
      } else if (currentItem.status === 'skip') {
        setAriaLive(`The correct answer is ${currentItem.keyword.text}`);
      }
    } else {
      setAriaLive(
        'Excellent! You’ve completed the magical word scramble. If you want to see more from the Wizarding World of Harry Potter, all 8 movies are available to stream on HBO Max! Click PLAY AGAIN for another round!'
      );
    }
  }, [items, currentIndex, currentItem, isFinal]);

  React.useEffect(() => {
    setViewport(view);
  }, [view]);

  return (
    <section id={id} className={clsx(scss.wrapper)}>
      <Helmet>
        {items.map((item, idx) => (
          <link
            key={'link-' + idx}
            rel="preload"
            as="image"
            href={getStrapiImageSource(
              item.featuredImg.src[view] as types.ImageSource
            )}
          />
        ))}
        <link
          rel="preload"
          as="image"
          href={getStrapiImageSource(
            promotion.image.src[view] as types.ImageSource
          )}
        />
      </Helmet>

      {/* Aria Live */}
      <div className="visually-hidden" aria-live={`polite`}>
        {ariaLive}
      </div>

      <div className="container">
        <h2 className={clsx(scss.headline)} style={headline.style}>
          {headline.text}
        </h2>
      </div>

      <div className={clsx(scss.grid, 'container')}>
        <div className={clsx(scss.col, scss.colLeft)}>
          <SwitchTransition>
            <CSSTransition
              key={
                isFinal
                  ? `final-featured-img`
                  : `feat-img-${items[currentIndex].id}`
              }
              mountOnEnter
              unmountOnExit
              timeout={300}
              classNames="fade">
              <>
                {!isFinal && (
                  <img
                    src={getStrapiImageSource(
                      currentItem.featuredImg.src[viewport] as types.ImageSource
                    )}
                    alt={currentItem.featuredImg.alt}
                  />
                )}

                {isFinal && (
                  <img
                    src={getStrapiImageSource(
                      promotion.image.src[viewport] as types.ImageSource
                    )}
                    alt={promotion.image.alt}
                  />
                )}
              </>
            </CSSTransition>
          </SwitchTransition>
        </div>

        <div className={clsx(scss.col, scss.colRight)}>
          <SwitchTransition>
            <CSSTransition
              key={isFinal ? 'final' : `scramble-${currentItem.id}`}
              mountOnEnter
              unmountOnExit
              timeout={300}
              classNames="fade-slide">
              {isFinal ? (
                <WordScrambleFinal
                  headline={promotion.headline}
                  subheadline={promotion.subheadline}
                  onReset={handleReset}
                />
              ) : (
                <WordScrambleItem
                  title={`Question ${currentIndex + 1} of ${items.length}`}
                  keyword={currentItem.keyword}
                  jumbledKeyword={currentItem.idWordScrambleItem}
                  question={currentItem.question}
                  status={currentItem.status}
                  featuredImg={currentItem.featuredImg}
                  setAriaLive={setAriaLive}
                  onNavigate={handleNavigate}
                  onSubmit={handleSubmit}
                />
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </section>
  );
};
