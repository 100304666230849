import clsx from 'clsx';
import React from 'react';
import useView from 'src/hooks/useView';
import * as types from 'src/types';
import { getStrapiImageSource } from 'src/utils/strapiDataHelpers';
import CTAButton from '../CTAButton/CTAButton';

import * as styles from './HBOPromo.module.scss';

type Props = {
  id: string;
  text?: types.TextFields;
  button?: types.ButtonFields;
  backgroundImage?: types.ImageFields;
  graphic?: types.ImageFields;
};

export const HBOPromo = ({
  id,
  text,
  button,
  backgroundImage,
  graphic,
}: Props) => {
  const view = useView();

  return (
    <section id={`hbo-promo-${id}`} className={clsx(styles.section)}>
      {backgroundImage && (
        <img
          className={clsx(styles.backgroundImage)}
          alt={backgroundImage.alt}
          src={getStrapiImageSource(
            backgroundImage.src[view]
              ? (backgroundImage.src[view] as types.ImageSource)
              : backgroundImage.src.lg
          )}
        />
      )}
      <div className={clsx(styles.container)}>
        {graphic && (
          <img
            className={clsx(styles.graphic)}
            alt={graphic.alt}
            src={getStrapiImageSource(
              graphic.src[view]
                ? (graphic.src[view] as types.ImageSource)
                : graphic.src.lg
            )}
          />
        )}
        {text?.text && (
          <p
            className={clsx(styles.text)}
            dangerouslySetInnerHTML={{
              __html: text.text,
            }}
          />
        )}

        {button && <CTAButton data={{ button }} />}
      </div>
    </section>
  );
};
