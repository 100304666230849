// extracted by mini-css-extract-plugin
export var body = "featureCard-module--body--kq9yi";
export var card = "featureCard-module--card--yv5XM";
export var content = "featureCard-module--content--N3Ezg";
export var excerpt = "featureCard-module--excerpt--zKEut";
export var featImg = "featureCard-module--feat-img--lmXho";
export var head = "featureCard-module--head--ZhRzJ";
export var left = "featureCard-module--left--GSfRa";
export var logo = "featureCard-module--logo--BX-ZV";
export var logowider = "featureCard-module--logowider--T+bNv";
export var overlay = "featureCard-module--overlay--TJea2";
export var subheadline = "featureCard-module--subheadline--C886t";