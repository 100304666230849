import * as React from 'react';
import * as ReactDOM from 'react-dom';
import clsx from 'clsx';

import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as style from './formOverlay.module.scss';
import Form from './Form/Form';

import specialBackground from './assets/max_pos_wonka_sweeps_form_top.jpg';

export type Props = {
  open: boolean;
  setOpen: (boolean: boolean) => void;
};

export const FormOverlay: React.FC<Props> = ({ open, setOpen }) => {
  // state
  const [mounted, setMounted] = React.useState(false);
  const [scrollIntoView, setScrollIntoView] = React.useState(false);

  // refs
  const overlayRef = React.useRef(null);

  // hooks
  React.useEffect(() => {
    setMounted(true);
  }, []);

  React.useEffect(() => {
    if (!window?.location) return;

    const urlParams = new URLSearchParams(window.location.search);
    const hasOpenSweepstakes = urlParams.get('openSweepstakes');
    const hasOpenRules = urlParams.get('openRules');

    if (hasOpenSweepstakes || hasOpenRules) setOpen(true);
    if (hasOpenRules) setScrollIntoView(true);
  }, [mounted, setOpen]);

  React.useEffect(() => {
    if (open) {
      document.body.classList.add('body-overflow-hidden');
    } else {
      document.body.classList.remove('body-overflow-hidden');
    }
  }, [open]);

  // functions
  function handleOutsideClick(evt: React.MouseEvent) {
    if (evt.target === overlayRef.current) {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    const onEscKey = (event: KeyboardEvent) =>
      event.key === 'Escape' && setOpen(false);

    window.addEventListener('keydown', onEscKey);

    return () => {
      window.removeEventListener('keydown', onEscKey);
    };
  }, [setOpen]);

  return mounted
    ? ReactDOM.createPortal(
        <CSSTransition {...{ in: open }} timeout={300} unmountOnExit={true}>
          <dialog
            ref={overlayRef}
            className={clsx(style.wrapper)}
            tabIndex={-1}
            onClick={handleOutsideClick}>
            <div className={clsx(style.dialog)} role="document">
              <div className={clsx(style.content)}>
                <button
                  className={clsx(style.close)}
                  onClick={() => setOpen(false)}>
                  <FontAwesomeIcon
                    className={clsx(style.closeIcon)}
                    icon={['fas', 'times']}
                  />
                </button>

                <div className={clsx(style.body)}>
                  <div className={clsx(style.specialBackgroundContainer)}>
                    <img
                      src={specialBackground}
                      alt="ENTER THE WONKA SWEET-STAKES"
                      className={clsx(style.specialBackground)}
                    />
                  </div>
                  <Form {...{ scrollIntoView, setScrollIntoView }} />
                </div>
              </div>
            </div>
          </dialog>
        </CSSTransition>,
        document.querySelector('#modal-root') as Element
      )
    : null;
};
