import * as React from 'react';
import clsx from 'clsx';
import * as scss from './drawer.module.scss';

export type DrawerContentProps = React.HTMLAttributes<HTMLDivElement>;

export const DrawerContent = React.forwardRef<
  HTMLDivElement,
  DrawerContentProps
>(({ className, children }, ref) => {
  return (
    <div ref={ref} className={clsx(scss.content, className)}>
      {children}
    </div>
  );
});
