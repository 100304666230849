import * as React from 'react';
import clsx from 'clsx';
import { ReactComponent as ChevGtIcon } from 'src/assets/media/icons/chev-gt.svg';
import * as scss from './carousel.module.scss';

export type NextProps = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  rootClassName?: string;
  rootStyle?: React.CSSProperties;
  style?: React.CSSProperties;

  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const Next = ({
  className,
  disabled,
  rootClassName,
  rootStyle,
  style,
  onClick,
}: NextProps) => {
  return (
    <li className={clsx(rootClassName)} style={rootStyle}>
      <button
        className={clsx(scss.control, scss.controlNext, 'ring', className)}
        style={style}
        disabled={disabled}
        data-action="next"
        arial-label="Next Item"
        onClick={onClick}>
        <span className="visually-hidden">Next Item</span>
        <span className={clsx(scss.controlIcon)}>
          <ChevGtIcon className="i" />
        </span>
      </button>
    </li>
  );
};
