export function shuffleString(str: string) {
  return str.split(' ').reduce((shuffledString, word) => {
    const shuffle = [...word];

    const getRandomValue = (i: number, N: number) =>
      Math.floor(Math.random() * (N - i) + i);

    shuffle.forEach(
      (_, index, array, j = getRandomValue(index, array.length)) =>
        ([array[index], array[j]] = [array[j], array[index]])
    );

    return `${shuffledString} ${shuffle.join('')}`.trim();
  }, '');
}
