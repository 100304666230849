import * as React from 'react';
import clsx from 'clsx';
import useView from 'src/hooks/useView';
import { Button } from 'src/components/ui/button/button';
import * as scss from './PromoCombined.module.scss';
import * as types from 'src/types';

import { getStrapiImageSource } from './../../../utils/strapiDataHelpers';

export type PromoItemType = {
  backgroundColor?: { color?: string };
  extendBackgroundColor?: boolean;
  transparentBackgroundColor?: boolean;
  margins?: types.MarginsFields;
  paddings?: types.PaddingsFields;
  headline: types.TextFields;
  subheadline: types.TextFields;
  image: types.ImageFields;
  button: types.ButtonFields;
};

export type Props = {
  data: PromoItemType;
  id?: string;
};

export const PromoCombined: React.FC<Props> = ({ data, id }) => {
  const [viewport, setViewport] = React.useState<types.Size>('lg');

  const view = useView();

  React.useEffect(() => {
    setViewport(view);
  }, [view]);

  return (
    <section
      id={id}
      className={clsx(
        scss.section,
        data.extendBackgroundColor && scss.extendBackgroundColor
      )}
      style={{
        backgroundColor: data.transparentBackgroundColor
          ? 'transparent'
          : data.backgroundColor?.color ?? '#110636',
        ...data.margins,
      }}>
      <div className={clsx(scss.container)}>
        <div
          className={clsx(scss.row)}
          style={{
            ...data.paddings,
          }}>
          <div className={clsx(scss.col)}>
            <div className={clsx(scss.featuredImage)}>
              <img
                src={getStrapiImageSource(
                  data.image.src[viewport] as types.ImageSource
                )}
                alt={data.image.alt}
              />
            </div>
          </div>
          <div className={clsx(scss.col)}>
            <div className={clsx(scss.content)}>
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.headline.text,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: data.subheadline.text,
                }}
              />
              {data.button && data.button.link && (
                <div className={clsx(scss.contentCta)}>
                  <Button
                    tag="a"
                    href={data.button.link}
                    label={data.button.text}
                    type={data.button.type}
                    mode={data.button.mode}
                    outline={data.button.outline}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
