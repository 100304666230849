import { Drawer as DrawerComponent } from './drawer';
import { DrawerButton as Button } from './drawerButton';
import { DrawerContent as Content } from './drawerContent';
import { DrawerWrapper as Wrapper } from './drawerWrapper';
import * as types from 'src/types';

export type DrawerType = {
  headline?: types.TextFields;
  subheadline?: types.TextFields;
  content?: types.TextFields;
};

export const Drawer = {
  Button,
  Content,
  Drawer: DrawerComponent,
  Wrapper,
};
