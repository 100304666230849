// extracted by mini-css-extract-plugin
export var btn_action = "Game-module--btn_action--YuuZb";
export var category = "Game-module--category--s5KEO";
export var categoryWheel = "Game-module--category-wheel--o1txF";
export var categoryWheelInner = "Game-module--category-wheel-inner--yEvJB";
export var categoryWheelItem = "Game-module--category-wheel-item--L54XV";
export var categoryWheelItemActive = "Game-module--category-wheel-item-active--1a1sZ";
export var categoryWheelItemDone = "Game-module--category-wheel-item-done--j+YTp";
export var choiceHeading = "Game-module--choice-heading--jSEKT";
export var choiceList = "Game-module--choice-list--bQwZQ";
export var clue = "Game-module--clue--DOS+m";
export var colLeft = "Game-module--col-left--2+7Wv";
export var colRight = "Game-module--col-right--N7G7L";
export var console = "Game-module--console--tqON-";
export var container = "Game-module--container--OrCjl";
export var content = "Game-module--content--1FHgM";
export var controlList = "Game-module--control-list--94-Fv";
export var cta = "Game-module--cta--tskUn";
export var featImgSpacer = "Game-module--feat-img-spacer--tFu1U";
export var heading = "Game-module--heading--NKk2n";
export var helper = "Game-module--helper--KFko8";
export var progress = "Game-module--progress--SyuFo";
export var question = "Game-module--question--FXAT+";
export var screenGame = "Game-module--screen-game---1f+3";
export var screenGameGrid = "Game-module--screen-game-grid--LHCP7";
export var screenGameGridItem = "Game-module--screen-game-grid-item--p0F-n";
export var screenGameOver = "Game-module--screen-game-over--x-3cB";
export var subheader = "Game-module--subheader--LNLFt";
export var wrapper = "Game-module--wrapper--eaIhz";