import * as React from 'react';
import { Button } from '../ui/button/button';
import * as types from 'src/types';
import * as scss from './CTAButton.module.scss';
import { VideoOverlay } from '../ui/videoOverlay/videoOverlay';

export const CTAButton: React.FC<Props> = (props) => {
  // props
  const { data } = props;

  // states
  const [videoOverlayVisible, setVideoOverlayVisible] = React.useState(false);

  // methods
  const openVideoOverlayHandler = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();

    setVideoOverlayVisible(true);
  };

  // render cta
  const CTA = () => {
    if (!data.button) return null;

    if (data.button.type === types.ButtonTypes.overlay) {
      return (
        <Button
          outline={data.button.outline}
          icon={data.button.icon}
          className={scss.itemBtn}
          label={data.button.text}
          mode={data.button.mode}
          style={data.button.style}
          tag="button"
          type={data.button.type}
          onClick={openVideoOverlayHandler}
        />
      );
    }

    return (
      <Button
        outline={data.button.outline}
        className={scss.itemBtn}
        icon={data.button.icon}
        href={data.button.link}
        label={data.button.text}
        mode={data.button.mode}
        style={data.button.style}
        tag="a"
        type={data.button.type}
        variant="primary"
      />
    );
  };

  return (
    <>
      <CTA />

      {data.button.type === types.ButtonTypes.overlay && (
        <VideoOverlay
          title={data.button.overlayTitle ?? ''}
          url={data.button.link}
          open={videoOverlayVisible}
          setOpen={setVideoOverlayVisible}
        />
      )}
    </>
  );
};

interface Props {
  data: {
    button: types.ButtonFields;
  };
}

export default CTAButton;
