import React from 'react';

import { Button } from 'src/components/ui/button/button';
import { gameDataType } from '../Game';
import { dispatchType, CONFIRM_ANSWER, CONTINUE } from '../GameContext';

import * as scss from '../Game.module.scss';

type Props = {
  data: gameDataType;
  dispatch: dispatchType;
  confirmed: boolean;
  setConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
};

const GameButtons = ({ data, dispatch, confirmed, setConfirmed }: Props) => {
  const handleOnSubmit = () => {
    dispatch({ type: CONFIRM_ANSWER });
    setConfirmed(true);
  };

  return (
    <ul className={scss.controlList} aria-label="Action Buttons">
      <li>
        <Button
          tag="button"
          aria-label={`${data.confirmButtonText} Action button`}
          label={data.confirmButtonText}
          disabled={confirmed}
          aria-disabled={confirmed}
          onClick={handleOnSubmit}
        />
      </li>
      <li>
        <Button
          tag="button"
          aria-label={`${data.continueButtonText} Action button`}
          label={data.continueButtonText}
          disabled={!confirmed}
          aria-disabled={!confirmed}
          onClick={() => dispatch({ type: CONTINUE })}
        />
      </li>
    </ul>
  );
};

export default GameButtons;
