import * as React from 'react';
import clsx from 'clsx';
import useView from 'src/hooks/useView';
import * as types from 'src/types';
import { Drawer, DrawerType } from '../ui/drawer';
import * as scss from './SellHeader.module.scss';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

export type Props = {
  backgroundImage?: types.ImageFields;
  branding?: 'generic' | 'branded';
  className?: string;
  description?: types.TextFields;
  drawer?: Partial<DrawerType>;
  featuredImage?: types.ImageFields;
  headline?: types.TextFields;
  id?: string;
  style?: React.CSSProperties;
  subheadline?: types.TextFields;
};

export const SellHeader = ({
  id,
  backgroundImage,
  branding,
  className,
  description,
  drawer,
  featuredImage,
  headline,
  style,
  subheadline,
}: Props) => {
  // states
  const [drawerState, setDrawerState] = React.useState<types.ToggleState>(
    types.ToggleStates.close
  );

  // refs
  const drawerRef = React.useRef<HTMLDivElement>(null);
  const view = useView();

  /**
   * Toggle drawer state
   */
  const handleDrawer = () => {
    setDrawerState((prevState) => {
      return types.ToggleStates.open === prevState
        ? types.ToggleStates.close
        : types.ToggleStates.open;
    });
  };

  /**
   * To have a smooth accordion expand transition, each item height should be
   * dynamically calculated based on item scrollable content height then apply
   * the it on the selected item.
   */
  React.useEffect(() => {
    if (!drawerRef.current) return;

    /**
     * Get selected item scroll content height.
     */
    const scrollHeight = drawerRef.current?.scrollHeight.toString();

    /**
     * Set height to expand selected item.
     */
    if (types.ToggleStates.open === drawerState) {
      drawerRef.current.style.setProperty('height', scrollHeight + 'px');
    }

    /**
     * Remove dynamically added height on element.
     */
    if (types.ToggleStates.close === drawerState) {
      drawerRef.current.style.removeProperty('height');
    }
  }, [drawerState]);

  const renderDrawer = () => {
    if (!drawer) return null;

    return (
      <Drawer.Wrapper isActive={types.ToggleStates.open === drawerState}>
        <Drawer.Button onClick={handleDrawer}>
          <span>Click for full promo details</span>
        </Drawer.Button>

        <Drawer.Drawer ref={drawerRef}>
          <Drawer.Content>
            <h2>{drawer?.headline?.text}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: drawer?.content?.text ?? '',
              }}
            />
          </Drawer.Content>
        </Drawer.Drawer>
      </Drawer.Wrapper>
    );
  };

  return (
    <section
      id={id}
      className={clsx(scss.wrapper, 'text-light', className)}
      style={style}>
      <div
        className={clsx(scss.innerWrapper, {
          'is-generic': branding === 'generic',
        })}
        style={{
          backgroundImage:
            backgroundImage?.src[view] &&
            `url(${getStrapiImageSource(
              backgroundImage?.src[view] as types.ImageSource
            )})`,
        }}>
        <div className={clsx(scss.container, 'container')}>
          <div className={clsx(scss.col, scss.colFeatImg)}>
            <img
              src={getStrapiImageSource(
                featuredImage?.src[view] as types.ImageSource
              )}
              alt={featuredImage?.alt}
              loading="lazy"
            />
          </div>

          <div className={clsx(scss.col)}>
            <div className={clsx(scss.promoContent)}>
              <h2 className={clsx(scss.headline)} style={headline?.style}>
                {headline?.text}
              </h2>

              <h3 className={clsx(scss.subheadline)} style={subheadline?.style}>
                {subheadline?.text}
              </h3>

              <p className={clsx(scss.description)} style={description?.style}>
                {description?.text}
              </p>
            </div>
          </div>
        </div>
      </div>

      {renderDrawer()}
    </section>
  );
};
