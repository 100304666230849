import * as React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as scss from './drawer.module.scss';

export type DrawerButtonProps = React.HTMLAttributes<HTMLButtonElement>;

export const DrawerButton = ({
  children,
  className,
  onClick,
}: DrawerButtonProps) => {
  return (
    <button
      className={clsx(scss.btnToggle, 'ring', className)}
      type="button"
      onClick={onClick}>
      {children}
      <span className={clsx(scss.btnToggleIcon)}>
        <FontAwesomeIcon className="i" icon="plus" />
      </span>
    </button>
  );
};
