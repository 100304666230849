import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type ControlProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const Control = ({ children, className, style }: ControlProps) => {
  return (
    <ul className={clsx(scss.controls, className)} style={style}>
      {children}
    </ul>
  );
};
