import * as React from 'react';
import { gameAnswer, gameDataType, gamePersonalityType } from './Game';

const isBrowser = typeof window !== 'undefined';

// types
type gamePersonalityTypeWithScore = gamePersonalityType & { score: number };

type StateType = {
  questions: Array<gameDataType>;
  typeScores: Array<gamePersonalityTypeWithScore>;
  gameOver: boolean;
  currentQuestionNumber: number;
  currentAnswer: gameAnswer | null;
  highestTypeScore: gamePersonalityTypeWithScore | null;
};

type ActionType = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

type DispatchType = (action: ActionType) => void;

// default state
const defaultState = {
  questions: [],
  typeScores: [],
  gameOver: false,
  currentQuestionNumber: 0,
  currentAnswer: null,
  highestTypeScore: null,
};

/** CONTEXT **/
export const GameContext = React.createContext<{
  state: StateType;
  dispatch: DispatchType;
}>({
  state: defaultState,
  dispatch: () => {
    throw new Error('Game Provider not found.');
  },
});

// actions
const LOAD_DATA = 'LOAD_DATA';
const GAME_OVER_TRUE = 'GAME_OVER_TRUE';
const SET_ANSWER = 'SET_ANSWER';
const NEXT_QUESTION = 'NEXT_QUESTION';
const RESET_GAME = 'RESET_GAME';

/** REDUCERS **/
const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case LOAD_DATA:
      return {
        ...state,
        questions: action.payload.questions,
        typeScores: action.payload.types.map((type: gamePersonalityType) => ({
          ...type,
          score: 0,
        })),
      };

    case GAME_OVER_TRUE:
      return {
        ...state,
        gameOver: true,
      };

    case NEXT_QUESTION:
      if (state.currentAnswer == null)
        return {
          ...state,
        };

      const getTypeScore = (typeId: string) =>
        state.currentAnswer?.typeScores.find(
          (typeScore) => typeScore.typeId === typeId
        )?.score || 0;

      const newTypeScores: Array<gamePersonalityTypeWithScore> = state.typeScores.map(
        (typeScore) => ({
          ...typeScore,
          score: typeScore.score + getTypeScore(typeScore.typeId),
        })
      );

      // When not on the last question
      if (state.currentQuestionNumber !== state.questions.length - 1) {
        // GA_TAG_EVENT
        if (isBrowser)
          window?.dataLayer?.push({
            event: 'Personality Quiz Game',
            action: 'CONTINUE',
          });

        return {
          ...state,
          typeScores: newTypeScores,
          currentQuestionNumber: state.currentQuestionNumber + 1,
          currentAnswer: null,
        };
      }

      const getHighestScoreType = () => {
        let highestScore = newTypeScores[0];

        newTypeScores.forEach((typeScore) => {
          if (typeScore.score > highestScore.score) highestScore = typeScore;
        });

        return highestScore;
      };

      // GA_TAG_EVENT
      if (isBrowser)
        window?.dataLayer?.push({
          event: 'Personality Quiz Game',
          action: 'GAME_OVER',
          highestTypeScore: getHighestScoreType(),
        });

      return {
        ...state,
        typeScores: newTypeScores,
        currentAnswer: null,
        gameOver: true,
        highestTypeScore: getHighestScoreType(),
      };

    case SET_ANSWER:
      // GA_TAG_EVENT
      if (isBrowser)
        window?.dataLayer?.push({
          event: 'Personality Quiz Game',
          action: 'SET_ANSWER',
          choice: action.payload.text,
          typeScores: action.payload.typeScores,
        });

      return {
        ...state,
        currentAnswer: action.payload,
      };

    case RESET_GAME:
      // GA_TAG_EVENT
      if (isBrowser)
        window?.dataLayer?.push({
          event: 'Personality Quiz Game',
          action: 'RESET_GAME',
        });

      return {
        ...state,
        gameOver: false,
        typeScores: state.typeScores.map((typeScore) => ({
          ...typeScore,
          score: 0,
        })),
        currentQuestionNumber: 0,
        currentAnswer: null,
        highestTypeScore: null,
      };

    default:
      return state;
  }
};

/** PROVIDER **/
export const GameProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  return (
    <GameContext.Provider value={{ state, dispatch }}>
      {children}
    </GameContext.Provider>
  );
};
