import React from 'react';
import clsx from 'clsx';

import { Button } from 'src/components/ui/button/button';
import { gameDataType } from '../Game';
import { dispatchType, RESET_GAME } from '../GameContext';

import * as scss from '../Game.module.scss';

type Props = {
  data: gameDataType;
  dispatch: dispatchType;
};

const PlayAgain = ({ data, dispatch }: Props) => {
  return (
    <div className={clsx(scss.gameGameOver)}>
      <Button
        label={data.tryAgainButtonText}
        tag={`button`}
        onClick={() => dispatch({ type: RESET_GAME })}
      />
    </div>
  );
};

export default PlayAgain;
