import * as React from 'react';
import { optionType } from './Game';

const isBrowser = typeof window !== 'undefined';
const gTagEventName = 'Match Game';

// Actions
export const NEW_QUESTION = 'NEW_QUESTION';
export const MOVE_MATCHEE = 'MOVE_MATCHEE';
export const CONFIRM_ANSWER = 'CONFIRM_ANSWER';
export const CONTINUE = 'CONTINUE';
export const GAME_OVER = 'GAME_OVER';
export const RESET_GAME = 'RESET_GAME';

// Types
type currentOptionType = Omit<optionType, 'optionId'>;

export type actionType =
  | {
      type: 'NEW_QUESTION';
      payload: {
        question: string;
        options: optionType[];
      };
    }
  | {
      type: 'MOVE_MATCHEE';
      payload: {
        sourceIndex: number;
        destinationIndex: number;
      };
    }
  | {
      type: 'CONFIRM_ANSWER';
    }
  | {
      type: 'CONTINUE';
    }
  | {
      type: 'GAME_OVER';
    }
  | {
      type: 'RESET_GAME';
    };

export type dispatchType = (action: actionType) => void;

export type stateType = {
  currentQuestion: string;
  currentQuestionNumber: number;
  currentOptions: currentOptionType[];
  currentMatchees: string[];
  score: number;
  gameOver: boolean;
};

// Default state
const defaultState = {
  currentQuestion: '',
  currentQuestionNumber: 0,
  currentOptions: [],
  currentMatchees: [],
  score: 0,
  gameOver: false,
};

/** CONTEXT **/
export const GameContext = React.createContext<{
  state: stateType;
  dispatch: dispatchType;
}>({
  state: defaultState,
  dispatch: () => {
    throw new Error('Game Provider not found.');
  },
});

// Helper Functions
const hasMisplacedMatchee = (matchees: string[], options: optionType[]) =>
  matchees.some(
    (_, matcheeIndex) =>
      matchees[matcheeIndex] !== options[matcheeIndex].matchee
  );

const randomizeMatchees = (options: optionType[]): string[] => {
  const randomizedMatchees = options
    .map((option) => option.matchee)
    .sort(() => 0.5 - Math.random());

  if (options.length <= 2 || hasMisplacedMatchee(randomizedMatchees, options))
    return randomizedMatchees;

  return randomizeMatchees(options);
};

const moveMatchee = (
  sourceIndex: number,
  destinationIndex: number,
  matchees: string[]
) => {
  const reorderedMatchees = [...matchees];
  const [reorderedMatchee] = reorderedMatchees.splice(sourceIndex, 1);
  reorderedMatchees.splice(destinationIndex, 0, reorderedMatchee);

  return reorderedMatchees;
};

const calculateScore = (
  options: currentOptionType[],
  matchees: string[]
): number =>
  options.reduce(
    (score, { matchee: correctMatchee }, optionIndex) =>
      score + Number(correctMatchee === matchees[optionIndex]),
    0
  );

// Reducers
const reducer = (state: stateType, action: actionType) => {
  const {
    currentQuestion,
    currentQuestionNumber,
    currentOptions,
    currentMatchees,
    score,
  } = state;

  switch (action.type) {
    case NEW_QUESTION:
      const { question, options } = action.payload;

      return {
        ...state,
        currentQuestion: question,
        currentOptions: options.map(({ matcher, matchee }: optionType) => ({
          matcher,
          matchee,
        })),
        currentMatchees: randomizeMatchees(options),
      };

    case MOVE_MATCHEE:
      const { sourceIndex, destinationIndex } = action.payload;

      // GA_TAG_EVENT
      if (isBrowser)
        window?.dataLayer?.push({
          event: gTagEventName,
          action: action.type,
          movedMatchee: {
            matchee: currentMatchees[sourceIndex],
            from: sourceIndex,
            to: destinationIndex,
          },
          swappedMatchee: {
            matchee: currentMatchees[destinationIndex],
            from: destinationIndex,
            to: sourceIndex,
          },
          options: currentMatchees.map((matchee, matcheeIndex) => {
            const { matcher, matchee: correctMatchee } = currentOptions[
              matcheeIndex
            ];

            return {
              matcher,
              currentMatchee: matchee,
              correctMatchee,
            };
          }),
        });

      return {
        ...state,
        currentMatchees: moveMatchee(
          sourceIndex,
          destinationIndex,
          currentMatchees
        ),
      };

    case CONFIRM_ANSWER:
      return {
        ...state,
        score: score + calculateScore(currentOptions, currentMatchees),
      };

    case CONTINUE:
      // GA_TAG_EVENT
      if (isBrowser)
        window?.dataLayer?.push({
          event: gTagEventName,
          action: action.type,
          question: currentQuestion,
          correctOptions: currentOptions,
          selectedOptions: currentMatchees.map((matchee, matcheeIndex) => {
            const { matcher, matchee: correctMatchee } = currentOptions[
              matcheeIndex
            ];

            return {
              matcher,
              matchee,
              correctMatchee,
              isCorrectAnswer: correctMatchee === matchee,
            };
          }),
          correctAnswers: calculateScore(currentOptions, currentMatchees),
          currentScore: score,
        });

      return {
        ...state,
        currentQuestion: defaultState.currentQuestion,
        currentOptions: defaultState.currentOptions,
        currentMatchees: defaultState.currentMatchees,
        currentQuestionNumber: currentQuestionNumber + 1,
      };

    case GAME_OVER:
      // GA_TAG_EVENT
      if (isBrowser)
        window?.dataLayer?.push({
          event: gTagEventName,
          action: action.type,
          finalScore: score,
          completedQuestions: currentQuestionNumber,
        });

      return {
        ...state,
        gameOver: true,
      };

    case RESET_GAME:
      // GA_TAG_EVENT
      if (isBrowser)
        window?.dataLayer?.push({
          event: gTagEventName,
          action: action.type,
        });

      return {
        ...defaultState,
      };

    default:
      return state;
  }
};

// Provider
export const GameProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  return (
    <GameContext.Provider value={{ state, dispatch }}>
      {children}
    </GameContext.Provider>
  );
};
