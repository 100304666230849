/**
 * Item components
 */
import { Content } from './carouselItemContent';
import { Excerpt } from './carouselItemExcerpt';
import { Header } from './carouselItemHeader';
import { Item as ItemRoot } from './carouselItem';
import { OverlayContent } from './carouselItemOverlayContent';
import { OverlayToggle } from './carouselItemOverlayToggle';
import { Thumbnail } from './carouselItemThumbnail';
import { Title } from './carouselItemTitle';
import { Genre } from './carouselItemGenre';
import { Wrapper } from './carouselItemWrapper';

/**
 * Control related components
 */
import { Control as ControlRoot } from './carouselControl';
import { Next } from './carouselControlNext';
import { Prev } from './carouselControlPrev';

/**
 * Nav buttons
 */
import { Nav as NavRoot } from './carouselNav';
import { NavItem } from './carouselNavItem';

/**
 * Export item components using `Item` namespace
 */
export const Item = {
  Content,
  Excerpt,
  Header,
  Item: ItemRoot,
  OverlayContent,
  OverlayToggle,
  Thumbnail,
  Title,
  Genre,
  Wrapper,
};

/**
 * Export carousel list wrapper
 */
export { List } from './carouselList';

/**
 * Group control components
 */

export const Control = {
  Control: ControlRoot,
  Next,
  Prev,
};

export const Nav = {
  Nav: NavRoot,
  NavItem,
};
