import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type OverlayContentProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const OverlayContent = ({
  children,
  className,
  style,
}: OverlayContentProps) => {
  return (
    <div className={clsx(scss.itemOverlayContent, className)} style={style}>
      {children}
    </div>
  );
};
