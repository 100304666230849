import * as React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import * as types from 'src/types';

SwiperCore.use([Navigation]);

export type SwiperCallback = (swiper: SwiperCore) => void;

export type UseCarouselHookResponse = [
  number,
  number,
  React.MutableRefObject<HTMLDivElement | null>,
  boolean,
  types.SlidePosition,
  React.MutableRefObject<SwiperCore | undefined>,
  (e: React.MouseEvent<HTMLButtonElement>) => boolean,
  (e: React.MouseEvent) => void,
  SwiperCallback,
  SwiperCallback,
  SwiperCallback,
  (index: number) => (ref: HTMLDivElement | null) => void
] & {
  activeItemOverlay: number;
  currentItem: number;
  currentTitleRef: React.MutableRefObject<HTMLDivElement | null>;
  isOverflowing: boolean;
  position: types.SlidePosition;
  swiperRef: React.MutableRefObject<SwiperCore | undefined>;
  handleControl: (e: React.MouseEvent<HTMLButtonElement>) => boolean;
  handleItemBullet: (e: React.MouseEvent) => void;
  onResize: SwiperCallback;
  onReachEnd: SwiperCallback;
  onSlideChange: SwiperCallback;
  onSwiper: SwiperCallback;
  onOverlayToggle: (index: number) => (ref: HTMLDivElement | null) => void;
};

export const useCarousel = () => {
  const [position, setPosition] = React.useState<types.SlidePosition>(
    types.SlidePositions.start
  );
  const [activeItemOverlay, setActiveItemOverlay] = React.useState(-1);
  const [currentItem, setCurrentItem] = React.useState(0);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  const swiperRef = React.useRef<SwiperCore>();
  const currentTitleRef = React.useRef<HTMLDivElement | null>(null);

  /**
   * Show or hide card description overlay when clicking the card.
   *
   * @param {number} index - Item index
   */
  const onOverlayToggle = (index: number) => (ref: HTMLDivElement | null) => {
    currentTitleRef.current = ref;

    setActiveItemOverlay((currentItem) => {
      if (currentItem !== index) return index;
      return -1;
    });
  };

  /**
   * Set swiper instance
   *
   * @param {SwiperCore} swiper - Swiper instance
   * @returns {void}
   */
  const onSwiper = (swiper: SwiperCore) => {
    swiperRef.current = swiper;

    const canTouch = matchMedia('(hover: none)').matches;
    swiper.allowTouchMove = canTouch;

    /**
     * Check if swiper is overflowing for showing arrow navigation.
     */
    setIsOverflowing(
      swiper.wrapperEl.offsetWidth < swiper.wrapperEl.scrollWidth
    );
  };

  /**
   * Handle slide change events
   *
   * @param {SwiperCore} swiper - Swiper instance
   * @returns {void}
   */
  const onReachEnd = (swiper: SwiperCore) => {
    setPosition('end');
  };

  /**
   * Handle slide change events
   *
   * @param {SwiperCore} swiper - Swiper instance
   * @returns {void}
   */
  const onSlideChange = (swiper: SwiperCore) => {
    setPosition(() => {
      if (swiper.isEnd) return 'end';
      if (swiper.isBeginning) return 'start';
      return 'between';
    });

    setCurrentItem(swiper.activeIndex);
  };

  /**
   * Enable touch navigation when certain breakpoint is met.
   *
   * @param {SwiperCore} swiper - Swiper instance
   * @returns {void}
   */
  const onResize = (swiper: SwiperCore) => {
    const canTouch = matchMedia('(hover: none)').matches;
    swiper.allowTouchMove = canTouch;

    // check if swiper is overflowing
    setIsOverflowing(
      swiper.wrapperEl.offsetWidth < swiper.wrapperEl.scrollWidth
    );
  };

  /**
   * Swiper cleanup
   */
  React.useEffect(() => {
    return () => swiperRef.current?.destroy();
  }, []);

  /**
   * Handle previous/next click controls
   *
   * @param {MouseEvent} e - Event object
   * @returns {void}
   */
  const handleControl = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;

    if (!target) return false;
    if (!swiperRef.current) return false;

    if ('prev' === target.dataset.action) {
      swiperRef.current.slidePrev();
    }

    if ('next' === target.dataset.action) {
      swiperRef.current.slideNext();
    }

    e.preventDefault();
  };

  /**
   * Handle current item page bullet indicator or navigation.
   *
   * @param e - Mouse event object
   * @returns {void}
   */
  const handleItemBullet = (e: React.MouseEvent) => {
    const index = e.currentTarget.getAttribute('data-slide');

    if (!index) return;

    swiperRef.current?.slideTo(Number(index));
  };

  /**
   * Remove card description overlay when tapped or clicked
   * outside the card.
   *
   * @param {MouseEvent | TouchEvent} e - Event object
   * @returns {void}
   */
  const handleOutsideClick = React.useCallback((e: MouseEvent | TouchEvent) => {
    if (
      currentTitleRef.current &&
      !currentTitleRef.current.contains(e.target as Node)
    ) {
      setActiveItemOverlay(-1);
      currentTitleRef.current = null;
    }
  }, []);

  /**
   * Bind `handleOutsideClick` callback to document event `click` and `touch`.
   * Then do clean up when component unmounts.
   */
  React.useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  /**
   * Support both object and array destructuring on hook usage.
   */
  const hook = {
    activeItemOverlay,
    currentItem,
    currentTitleRef,
    isOverflowing,
    position,
    swiperRef,
    handleControl,
    handleItemBullet,
    onResize,
    onReachEnd,
    onSlideChange,
    onSwiper,
    onOverlayToggle,
  } as UseCarouselHookResponse;

  hook[0] = hook.activeItemOverlay;
  hook[1] = hook.currentItem;
  hook[2] = hook.currentTitleRef;
  hook[3] = hook.isOverflowing;
  hook[4] = hook.position;
  hook[5] = hook.swiperRef;
  hook[6] = hook.handleControl;
  hook[7] = hook.handleItemBullet;
  hook[8] = hook.onResize;
  hook[9] = hook.onReachEnd;
  hook[10] = hook.onSlideChange;
  hook[11] = hook.onSwiper;
  hook[12] = hook.onOverlayToggle;

  return hook;
};

export default useCarousel;
