import * as React from 'react';
import clsx from 'clsx';
import { AccordionItemType, AccordionItem } from './accordionItem';
import * as scss from './accordion.module.scss';

export type AccordionProps = {
  className?: string;
  items?: AccordionItemType[];
  light?: boolean;
};

export const Accordion = ({
  className,
  items = [],
  light = false,
}: AccordionProps) => {
  const [currentItem, setCurrentItem] = React.useState(-1);
  const currentItemRef = React.useRef<HTMLDivElement | null>(null);
  const prevItemRef = React.useRef<HTMLDivElement | null>(null);

  const handleClick = React.useCallback(
    (index: number) => (itemRef: HTMLDivElement | null) => {
      currentItemRef.current = itemRef;
      setCurrentItem((prevItem) => {
        if (prevItem === index) return -1;
        return index;
      });
    },
    []
  );

  React.useEffect(() => {
    if (prevItemRef.current) {
      prevItemRef.current.style.removeProperty('height');
    }

    if (!currentItemRef.current) {
      return;
    }

    if (prevItemRef.current === currentItemRef.current) {
      prevItemRef.current.style.removeProperty('height');
      prevItemRef.current = null;
      return;
    }

    currentItemRef.current.style.setProperty(
      'height',
      currentItemRef.current.scrollHeight.toString() + 'px'
    );
    prevItemRef.current = currentItemRef.current;
  }, [currentItem]);

  const renderItems = items.map((item, index) => (
    <AccordionItem
      key={`accordion-${item.id}`}
      id={item.id}
      isOpen={currentItem === index}
      content={item.content}
      headline={item.headline}
      onClick={handleClick(index)}
    />
  ));

  return (
    <div className={clsx(scss.accordion, { 'is-light': light }, className)}>
      {renderItems}
    </div>
  );
};
