// extracted by mini-css-extract-plugin
export var col = "DeviceAvailability-module--col--MABSP";
export var container = "DeviceAvailability-module--container--k8R7u";
export var content = "DeviceAvailability-module--content--MCpl3";
export var contentHorizontalRule = "DeviceAvailability-module--content-horizontal-rule--jEQIs";
export var contentImage = "DeviceAvailability-module--content-image--seJa-";
export var contentPrint = "DeviceAvailability-module--content-print--wG04K";
export var contentTitle = "DeviceAvailability-module--content-title--RL6gd";
export var footer = "DeviceAvailability-module--footer--JuYd4";
export var header = "DeviceAvailability-module--header--5gJno";
export var row = "DeviceAvailability-module--row--clfiv";
export var section = "DeviceAvailability-module--section--TjzC1";
export var sectionBg = "DeviceAvailability-module--section-bg--wmuvK";
export var subheadline = "DeviceAvailability-module--subheadline--5nTJf";