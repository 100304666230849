import * as React from 'react';
import clsx from 'clsx';
import * as scss from './CategoryFilter.module.scss';
import * as types from 'src/types';
import { getStrapiImageSource } from './../../../utils/strapiDataHelpers';

export type Props = {
  id: string;
  title: string;
  icon: types.ImageFields;
  selected: boolean;
  setSelected: (id: string) => void;
};

export const CategoryFilterBlurb: React.FC<Props> = ({
  id,
  title,
  icon,
  selected,
  setSelected,
}) => {
  // event listeners
  function handleClick(evt: React.MouseEvent, id: string) {
    setSelected(id);
  }

  function handleKeyPress(evt: React.KeyboardEvent, id: string) {
    if (evt.key === 'Enter') {
      setSelected(id);
    }
  }

  return (
    <div
      className={clsx(scss.categoryFilterBlurb, { [`is-selected`]: selected })}
      tabIndex={0}
      title={`Category - ${title}`}
      role="button"
      onClick={(evt) => handleClick(evt, id)}
      onKeyPress={(evt) => handleKeyPress(evt, id)}>
      <div className={scss.categoryFilterBlurbIcon}>
        <img
          src={getStrapiImageSource(icon.src.lg as types.ImageSource)}
          alt={icon.alt}
        />
      </div>
      <span className={scss.categoryFilterBlurbTitle}>{title}</span>
    </div>
  );
};
