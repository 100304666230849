import React from 'react';
import qs from 'query-string';
import { Promo as PromoSeparated } from './Promo';
import { PromoCombined } from '../PromoCombined/PromoCombined';

type Props = {
  data: any;
};

const PromoLocales: React.FC<Props> = ({ data }) => {
  let params: { loc?: string } = {};
  let loc = '';

  // check if window exists so Gatsby won't complain in browserless builds
  if (typeof window !== 'undefined') {
    params = qs.parse(window?.location?.search || '');
    loc = params.loc || '';
  }

  // override loc with storyLocale if it's passed by Storybook
  if (
    data.hasOwnProperty('storyLocale') &&
    data.storyLocale != '' &&
    data.storyLocale != null
  ) {
    loc = data.storyLocale;
  }

  const locales = data.locales?.map((l: any) => l.locale);
  const locDebugStyle = {
    color: 'yellow',
    backgroundColor: 'black',
    padding: '10px',
  };

  const getPromo = (promoData: any) => {
    // if no images, use default images
    if (promoData.image == null) {
      promoData.image = data.default?.image;
    }
    // return the type of promo specified
    const ptype = data.promoType || 'separated';

    switch (ptype) {
      case 'separated':
        return <PromoSeparated data={promoData} />;
        break;
      case 'combined':
      default:
        return <PromoCombined data={promoData} />;
        break;
    }
  };

  // IF debugging, show default and all locales
  if (data.debug === true || loc === 'debug') {
    return (
      <>
        {data.default && data.default.headline && (
          <div>
            <p style={locDebugStyle}>DEBUG LOCALE: DEFAULT</p>
            {getPromo(data.default)}
          </div>
        )}
        {data.locales.map((l: any) => {
          return (
            <div>
              <p style={locDebugStyle}>DEBUG LOCALE: {l.locale}</p>
              {getPromo(l.data)}
            </div>
          );
        })}
      </>
    );
  }

  // If locale is set, show that locale only
  if (loc != '' && locales.includes(loc)) {
    const localeData = data.locales.filter((l: any) => l.locale == loc);
    return getPromo(localeData[0].data);
  }

  // Otherwise, show default promo
  return <>{data.default && data.default.headline && getPromo(data.default)}</>;
};

export default PromoLocales;
