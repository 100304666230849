import * as React from 'react';
import { GameProvider } from './GameContext';
import Game, { Props } from './Game';

const SceneIt: React.FC<Props> = ({ data, id }) => {
  return (
    <GameProvider>
      <Game id={id} data={data} />
    </GameProvider>
  );
};

export default SceneIt;
