import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import * as scss from './CoachsCornerItem.module.scss';
import backgroundPatternOdd from 'src/assets/media/stars_1.png';
import backgroundPatternEven from 'src/assets/media/stars_2.png';
import { getStrapiImageSource } from '../../utils/strapiDataHelpers';

export type CoachsCornerItemType = {
  id: string;
  title: types.TextFields;
  content: { text: string };
  image: types.ImageFields;
  imageGlow?: string;
};

export type Props = {
  data: CoachsCornerItemType;
  isOdd: boolean;
};

export const CoachsCornerItem: React.FC<Props> = ({ data, isOdd }) => {
  // render
  return (
    <div className={clsx(scss.content)}>
      <div className={clsx(scss.contentImage)}>
        <div className={clsx(scss.contentImageInner)}>
          <div
            className={clsx(scss.contentImageInnerPattern)}
            style={{
              backgroundImage: `url(${
                isOdd ? backgroundPatternOdd : backgroundPatternEven
              })`,
            }}
          />
          <div
            className={clsx(scss.contentImageInnerGlow)}
            style={{ color: data.imageGlow }}
          />
          <img
            src={getStrapiImageSource(data.image.src.lg as types.ImageSource)}
            alt={data.image.alt}
          />
        </div>
      </div>
      <div
        className={clsx(scss.contentTitle)}
        dangerouslySetInnerHTML={{ __html: data.title.text }}
      />
      <div className={clsx(scss.contentPrint)}>
        <p dangerouslySetInnerHTML={{ __html: data.content.text }} />
      </div>
    </div>
  );
};
