import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type TitleProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
};

export const Title = ({ children, className, style }: TitleProps) => {
  return (
    <h2 className={clsx(scss.itemTitle, className)} style={style}>
      {children}
    </h2>
  );
};
