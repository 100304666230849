import * as React from 'react';
import { VideoOverlay } from '../ui/videoOverlay/videoOverlay';
import { Button } from '../ui/button/button';
import * as types from 'src/types';

export type Props = {
  className?: string;
  buttonTitle: string;
  buttonStyle?: React.CSSProperties;
  videoTitle: string;
  videoURL: string;
  videoOverlayOpen: boolean;
  setVideoOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mode?: types.Theme;
  outline?: boolean;
  showIcon?: boolean;
};

const CarouselVideoOverlayButton: React.FC<Props> = ({
  className,
  buttonTitle,
  buttonStyle,
  videoTitle,
  videoURL,
  videoOverlayOpen,
  setVideoOverlayOpen,
  mode,
  outline,
  showIcon = true,
}) => {
  return (
    <>
      <Button
        tag="button"
        label={buttonTitle}
        icon={showIcon ? ['far', 'play-circle'] : undefined}
        style={buttonStyle}
        {...{ className, mode, outline }}
        onClick={() => setVideoOverlayOpen(true)}
      />

      <VideoOverlay
        title={videoTitle}
        url={videoURL}
        open={videoOverlayOpen}
        setOpen={setVideoOverlayOpen}
      />
    </>
  );
};

export default CarouselVideoOverlayButton;
