import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import useView from 'src/hooks/useView';
import { Button } from '../../ui/button/button';
import { VideoOverlay } from '../../ui/videoOverlay/videoOverlay';
import * as scss from './Promo.module.scss';
import { getStrapiImageSource } from '../../../utils/strapiDataHelpers';

export type PromoItemType = {
  backgroundColor?: { color: string };
  extendBackgroundColor?: boolean;
  transparentBackgroundColor?: boolean;
  margins?: types.MarginsFields;
  paddings?: types.PaddingsFields;
  headline: types.TextFields;
  subheadline: types.TextFields;
  image: types.ImageFields;
  button?: types.ButtonFields;
};

export type Props = {
  className?: string;
  id?: string;
  data: PromoItemType;
};

export const Promo = ({ className, id, data }: Props) => {
  const [viewport, setViewport] = React.useState<types.Size>('lg');
  const [modalOpen, setModalOpen] = React.useState(false);

  const view = useView();

  const handleVideoOverlay = () => {
    setModalOpen(true);
  };

  React.useEffect(() => {
    setViewport(view);
  }, [view]);

  return (
    <section
      id={id}
      className={clsx(
        scss.wrapper,
        className,
        data.extendBackgroundColor && scss.extendBackgroundColor
      )}
      style={{
        ...data.margins,
      }}>
      <div className="container">
        <div
          className={clsx(scss.grid)}
          style={{
            backgroundColor: data.transparentBackgroundColor
              ? 'transparent'
              : data.backgroundColor?.color,
            ...data.paddings,
          }}>
          <div className={clsx(scss.col)}>
            <img
              className={clsx(scss.hero)}
              src={getStrapiImageSource(
                data.image.src[viewport] as types.ImageSource
              )}
              alt={data.image.alt}
              loading="lazy"
            />
          </div>

          <div className={clsx(scss.col)}>
            <div className={clsx(scss.content)}>
              <h2
                className={clsx(scss.headline)}
                dangerouslySetInnerHTML={{
                  __html: data.headline.text,
                }}
              />
              <p
                className={clsx(scss.subheadline)}
                dangerouslySetInnerHTML={{
                  __html: data.subheadline.text,
                }}
              />

              {data.button && (
                <>
                  {data.button.type === types.ButtonTypes.overlay ? (
                    <>
                      {/* render overlay button */}
                      <Button
                        outline
                        icon={['far', 'play-circle']}
                        label={data.button.text}
                        mode={data.button.mode}
                        style={data.button.style}
                        tag="button"
                        type={data.button.type}
                        onClick={handleVideoOverlay}
                      />

                      <VideoOverlay
                        title={data.button.overlayTitle ?? ''}
                        url={data.button.link}
                        open={modalOpen}
                        type={'large'}
                        setOpen={setModalOpen}
                      />
                    </>
                  ) : (
                    // render normal button
                    <Button
                      tag="a"
                      href={data.button.link}
                      label={data.button.text}
                      mode={data.button.mode}
                      outline={data.button.outline}
                      type={data.button.type}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
