// extracted by mini-css-extract-plugin
export var btnLvlNav = "WordScrambleItem-module--btn-lvl-nav--dvMvX";
export var btnSubmit = "WordScrambleItem-module--btn-submit--fOyq2";
export var content = "WordScrambleItem-module--content--S+jBd";
export var form = "WordScrambleItem-module--form--caa9D";
export var formGroup = "WordScrambleItem-module--form-group--qCKox";
export var formInput = "WordScrambleItem-module--form-input--Vk0aS";
export var formLabel = "WordScrambleItem-module--form-label--A0SaW";
export var header = "WordScrambleItem-module--header--Cqlex";
export var heading = "WordScrambleItem-module--heading--cGoo7";
export var icon = "WordScrambleItem-module--icon--NeGKg";
export var item = "WordScrambleItem-module--item--IpxC7";
export var keyword = "WordScrambleItem-module--keyword--Xraut";
export var keywordLabel = "WordScrambleItem-module--keyword-label--1dfMH";
export var question = "WordScrambleItem-module--question--nG4X4";
export var skip = "WordScrambleItem-module--skip--56Qev";
export var theKeyword = "WordScrambleItem-module--the-keyword--WP8Ta";