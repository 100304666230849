import React from 'react';
import clsx from 'clsx';
import Xarrow from 'react-xarrows';
import Hyphenated from 'react-hyphen';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DragStart,
} from 'react-beautiful-dnd';

import { gameDataType, movedMatcheeType } from '../Game';
import { dispatchType, MOVE_MATCHEE, stateType } from '../GameContext';

import * as scss from '../Game.module.scss';

type incorrectAnswerType = {
  matcherId: string;
  matcheeId: string;
};

type Props = {
  data: gameDataType;
  state: stateType;
  dispatch: dispatchType;
  confirmed: boolean;
  setMovedMatchee: React.Dispatch<movedMatcheeType>;
};

const GameOptions = ({
  data,
  state,
  dispatch,
  confirmed,
  setMovedMatchee,
}: Props) => {
  const [dragging, setDragging] = React.useState<number | null>(null);
  const [incorrectAnswers, setIncorrectAnswers] = React.useState<
    incorrectAnswerType[] | null
  >(null);

  // Updating incorrectAnswers on confirmed
  React.useEffect(() => {
    if (confirmed && incorrectAnswers != null) return;
    if (!confirmed && incorrectAnswers == null) return;

    if (!confirmed) return setIncorrectAnswers(null);

    // confirmed and incorrectAnswers haven't been defined yet
    const tempIncorrectAnswers: incorrectAnswerType[] = [];
    for (let i = 0; i < state.currentOptions.length; i++) {
      if (state.currentOptions[i].matchee !== state.currentMatchees[i]) {
        const correctMatcheeLocation = state.currentMatchees.findIndex(
          (matchee) => matchee === state.currentOptions[i].matchee
        );

        tempIncorrectAnswers.push({
          matcherId: `${state.currentQuestionNumber}_matcher-${i}`,
          matcheeId: `${state.currentQuestionNumber}_matchee-${correctMatcheeLocation}`,
        });
      }
    }

    setIncorrectAnswers(tempIncorrectAnswers);
  }, [
    confirmed,
    incorrectAnswers,
    state.currentMatchees,
    state.currentOptions,
    state.currentQuestionNumber,
  ]);

  const handleOnDragStart = (result: DragStart) =>
    setDragging(result.source.index);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const payload = {
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
    };

    setDragging(null);

    setMovedMatchee({
      matchee: state.currentMatchees[result.source.index],
      sourceIndex: result.source.index,
      destinationIndex: result.destination.index,
    });

    dispatch({ type: MOVE_MATCHEE, payload });
  };

  return (
    <div className={clsx(scss.gameOptions)}>
      <ul className={clsx(scss.gameOptionContainer)}>
        {data.questions[state.currentQuestionNumber].options.map(
          ({ matcher, matchee }, optionIndex) => (
            <li
              key={`${state.currentQuestionNumber}_matcher-${optionIndex}`}
              id={`${state.currentQuestionNumber}_matcher-${optionIndex}`}
              aria-label={`Matcher ${
                optionIndex + 1
              } - ${matcher}. Currently matched with ${
                state.currentMatchees[optionIndex]
              }`}>
              <div
                className={clsx(scss.gameOptionItem, {
                  'is-success':
                    confirmed && state.currentMatchees[optionIndex] === matchee,
                  'is-error':
                    confirmed && state.currentMatchees[optionIndex] !== matchee,
                })}>
                <Hyphenated>
                  <p>{matcher}</p>
                </Hyphenated>
              </div>
            </li>
          )
        )}
      </ul>
      <div className={clsx(scss.gameOptionDirections)}>
        {confirmed &&
          incorrectAnswers?.map(({ matcheeId, matcherId }, lineIndex) => (
            <>
              <Xarrow
                start={matcheeId}
                color="rgba(0, 0, 0, 0.6)"
                strokeWidth={8}
                end={matcherId}
                showHead={false}
                curveness={0.5}
                startAnchor="left"
                endAnchor="right"
                {...{
                  _cpx1Offset: 2,
                  _cpy2Offset: -2,
                }}
              />
              <Xarrow
                start={matcheeId}
                color={`rgba(${Math.floor(
                  225 -
                    50 /
                      ((incorrectAnswers.length - lineIndex) /
                        incorrectAnswers.length)
                )}, 0, 0, 90%)`}
                strokeWidth={6}
                end={matcherId}
                showHead={false}
                curveness={0.5}
                startAnchor="left"
                endAnchor="right"
                {...{
                  _cpx1Offset: 2,
                  _cpy2Offset: -2,
                }}
              />
            </>
          ))}
      </div>
      <DragDropContext
        onDragEnd={handleOnDragEnd}
        onDragStart={handleOnDragStart}>
        <Droppable droppableId={`${data.idMatchGame}-matchees`}>
          {/* Needs to have a function with provided as a first child */}
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {(provided) => (
            <ul
              className={clsx(
                scss.gameOptionContainer,
                typeof dragging === 'number' && scss.dragging
              )}
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {state.currentMatchees?.map((matchee, matcheeIndex) => (
                <Draggable
                  key={`${state.currentQuestionNumber}_matchee-${matcheeIndex}`}
                  draggableId={`${state.currentQuestionNumber}_matchee-${matcheeIndex}`}
                  isDragDisabled={confirmed}
                  index={matcheeIndex}>
                  {/* Needs to have a function with provided as a first child */}
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {(provided) => (
                    <li
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className={clsx(
                        dragging === matcheeIndex && scss.dragging
                      )}
                      id={`${state.currentQuestionNumber}_matchee-${matcheeIndex}`}
                      aria-label={`You are currently on the matchee ${
                        matcheeIndex + 1
                      } - ${matchee}. Currently matched with ${
                        state.currentOptions[matcheeIndex].matcher
                      }.`}>
                      <div
                        className={clsx(
                          scss.gameOptionItem,
                          scss.gameOptionMatchee,
                          !confirmed && scss.gameOptionDraggable,
                          {
                            'is-success':
                              confirmed &&
                              state.currentOptions[matcheeIndex].matchee ===
                                matchee,
                            'is-error':
                              confirmed &&
                              state.currentOptions[matcheeIndex].matchee !==
                                matchee,
                          }
                        )}>
                        {!confirmed && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="32"
                            viewBox="0 0 20 32"
                            className={clsx(scss.gameOptionDragIcon)}>
                            <path
                              data-name="Drag Handle"
                              d="M16 20a4 4 0 114-4 4 4 0 01-4 4zm0-12a4 4 0 114-4 4 4 0 01-4 4zM4 32a4 4 0 114-4 4 4 0 01-4 4zm0-12a4 4 0 114-4 4 4 0 01-4 4zM4 8a4 4 0 114-4 4 4 0 01-4 4zm12 16a4 4 0 11-4 4 4 4 0 014-4z"
                              fillRule="evenodd"
                            />
                          </svg>
                        )}
                        <Hyphenated>
                          <p>{matchee}</p>
                        </Hyphenated>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default GameOptions;
