import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import {
  SubmitButton,
  Input,
  Select,
  Checkbox,
  Form as FormFormik,
} from 'formik-semantic-ui-react';
import { Form as FormSemantic } from 'semantic-ui-react';
import * as Yup from 'yup';
import axios from 'axios';
import clsx from 'clsx';

import './Form.scss';
import './SemanticUI.css';
import Rules from './Rules';

type ValuesType = {
  first_name: string;
  last_name: string;
  email: string;
  company: string;
  supervisor: string;
  city: string;
  state: string;
  legal: boolean;
};

type ErrorValuesType = {
  first_name?: string;
  last_name?: string;
  email?: string;
  company?: string;
  supervisor?: string;
  city?: string;
  state?: string;
};

type ValueNamesType = (
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'company'
  | 'supervisor'
  | 'city'
  | 'state'
)[];

type ResponseMessageContextType = {
  label: string;
  value: string;
  key: keyof ValuesType;
};
type ResponseMessageType = {
  message: string;
  path: ValueNamesType;
  type: string;
  context: ResponseMessageContextType;
}[];
type ResponseType =
  | {
      type: 'validation-error';
      message: ResponseMessageType;
    }
  | {
      type: 'over-limit-error' | 'system-error';
      message: string;
    };

const FORM_ID = 'wonka-sweet-stakes-2024-1pd';
const URL = 'https://api.experiencehbo.com/submit';
const isBrowser = typeof window !== 'undefined';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Required'),
  company: Yup.string().required('Required'),
  supervisor: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  city: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  state: Yup.string().required('Required'),
  legal: Yup.boolean().not([false]),
});

type Props = {
  scrollIntoView: boolean;
  setScrollIntoView: (scrollIntoView: boolean) => void;
};

const Form = ({ scrollIntoView, setScrollIntoView }: Props) => {
  const [isFormComplete, setFormComplete] = useState(false);
  const [responseError, setResponseError] = useState<string | null>(null);

  const initialValues: ValuesType = {
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    supervisor: '',
    city: '',
    state: '',
    legal: false,
  };

  const companyNames = [
    {
      key: 'altice',
      text: 'Altice/Optimum/Suddenlink',
      value: 'Altice/Optimum/Suddenlink',
    },
    { key: 'astound', text: 'Astound', value: 'Astound' },
    { key: 'att', text: 'AT&T', value: 'AT&T' },
    { key: 'cox', text: 'COX', value: 'COX' },
    { key: 'cricket', text: 'Cricket', value: 'Cricket' },
    { key: 'direcTV', text: 'DIRECTV', value: 'DIRECTV' },
    { key: 'dish', text: 'DISH', value: 'DISH' },
    { key: 'frontier', text: 'Frontier', value: 'Frontier' },
    { key: 'hulu', text: 'Hulu', value: 'Hulu' },
    { key: 'mediacom', text: 'Mediacom', value: 'Mediacom' },
    { key: 'nctc', text: 'NCTC', value: 'NCTC' },
    { key: 'spectrum', text: 'Spectrum', value: 'Spectrum' },
    { key: 'verizon', text: 'Verizon', value: 'Verizon' },
    { key: 'wow!', text: 'WOW!', value: 'WOW!' },
    { key: 'xfinity', text: 'Xfinity', value: 'Xfinity' },
    { key: 'other', text: 'Other', value: 'Other' },
  ];
  const states = [
    { key: 'Alabama (AL)', text: 'Alabama', value: 'AL' },
    { key: 'Alaska (AK)', text: 'Alaska', value: 'AK' },
    { key: 'Arizona (AZ)', text: 'Arizona', value: 'AZ' },
    { key: 'Arkansas (AR)', text: 'Arkansas', value: 'AR' },
    { key: 'California (CA)', text: 'California', value: 'CA' },
    { key: 'Colorado (CO)', text: 'Colorado', value: 'CO' },
    { key: 'Connecticut (CT)', text: 'Connecticut', value: 'CT' },
    { key: 'Delaware (DE)', text: 'Delaware', value: 'DE' },
    {
      key: 'District Of Columbia (DC)',
      text: 'District Of Columbia',
      value: 'DC',
    },
    { key: 'Florida (FL)', text: 'Florida', value: 'FL' },
    { key: 'Georgia (GA)', text: 'Georgia', value: 'GA' },
    { key: 'Hawaii (HI)', text: 'Hawaii', value: 'HI' },
    { key: 'Idaho (ID)', text: 'Idaho', value: 'ID' },
    { key: 'Illinois (IL)', text: 'Illinois', value: 'IL' },
    { key: 'Indiana (IN)', text: 'Indiana', value: 'IN' },
    { key: 'Iowa (IA)', text: 'Iowa', value: 'IA' },
    { key: 'Kansas (KS)', text: 'Kansas', value: 'KS' },
    { key: 'Kentucky (KY)', text: 'Kentucky', value: 'KY' },
    { key: 'Louisiana (LA)', text: 'Louisiana', value: 'LA' },
    { key: 'Maine (ME)', text: 'Maine', value: 'ME' },
    { key: 'Maryland (MD)', text: 'Maryland', value: 'MD' },
    { key: 'Massachusetts (MA)', text: 'Massachusetts', value: 'MA' },
    { key: 'Michigan (MI)', text: 'Michigan', value: 'MI' },
    { key: 'Minnesota (MN)', text: 'Minnesota', value: 'MN' },
    { key: 'Mississippi (MS)', text: 'Mississippi', value: 'MS' },
    { key: 'Missouri (MO)', text: 'Missouri', value: 'MO' },
    { key: 'Montana (MT)', text: 'Montana', value: 'MT' },
    { key: 'Nebraska (NE)', text: 'Nebraska', value: 'NE' },
    { key: 'Nevada (NV)', text: 'Nevada', value: 'NV' },
    { key: 'New Hampshire (NH)', text: 'New Hampshire', value: 'NH' },
    { key: 'New Jersey (NJ)', text: 'New Jersey', value: 'NJ' },
    { key: 'New Mexico (NM)', text: 'New Mexico', value: 'NM' },
    { key: 'New York (NY)', text: 'New York', value: 'NY' },
    { key: 'North Carolina (NC)', text: 'North Carolina', value: 'NC' },
    { key: 'North Dakota (ND)', text: 'North Dakota', value: 'ND' },
    { key: 'Ohio (OH)', text: 'Ohio', value: 'OH' },
    { key: 'Oklahoma (OK)', text: 'Oklahoma', value: 'OK' },
    { key: 'Oregon (OR)', text: 'Oregon', value: 'OR' },
    { key: 'Pennsylvania (PA)', text: 'Pennsylvania', value: 'PA' },
    { key: 'Rhode Island (RI)', text: 'Rhode Island', value: 'RI' },
    { key: 'South Carolina (SC)', text: 'South Carolina', value: 'SC' },
    { key: 'South Dakota (SD)', text: 'South Dakota', value: 'SD' },
    { key: 'Tennessee (TN)', text: 'Tennessee', value: 'TN' },
    { key: 'Texas (TX)', text: 'Texas', value: 'TX' },
    { key: 'Utah (UT)', text: 'Utah', value: 'UT' },
    { key: 'Vermont', text: 'Vermont', value: 'VT' },
    { key: 'Virginia', text: 'Virginia', value: 'VA' },
    { key: 'Washington', text: 'Washington', value: 'WA' },
    { key: 'West Virginia', text: 'West Virginia', value: 'WV' },
    { key: 'Wisconsin', text: 'Wisconsin', value: 'WI' },
    { key: 'Wyoming', text: 'Wyoming', value: 'WY' },
    { key: 'Other', text: 'Other', value: 'Other' },
  ];

  // ? GA_TAG_EVENT
  useEffect(() => {
    const event = new CustomEvent('sweepstakesEntryStart');
    document.dispatchEvent(event);

    if (isBrowser)
      window.dataLayer?.push({
        event: 'sweepstakesEntryStart',
      });
  }, []);
  useEffect(() => {
    if (!isFormComplete) return;

    const event = new CustomEvent('sweepstakesEntryComplete');
    document.dispatchEvent(event);

    if (isBrowser)
      window.dataLayer?.push({
        event: 'sweepstakesEntryComplete',
      });
  }, [isFormComplete]);

  const handleSubmit = (
    values: ValuesType,
    { setSubmitting, setErrors }: FormikHelpers<ValuesType>
  ) => {
    const { legal: _, ...formData } = {
      form_id: FORM_ID,
      ...values,
    };

    axios
      .post(URL, JSON.stringify(formData))
      .then((response) => {
        const errors: ErrorValuesType = {};
        let hasErrors = false;

        // Checking if the status of the response is ok
        if (response.data.status === 'ok') {
          setFormComplete(true);
          setResponseError('');
          setSubmitting(false);

          return;
        }
        response.data.errors.forEach(({ message, type }: ResponseType) => {
          switch (type) {
            case 'validation-error': {
              if (message.length) {
                errors[message[0].path[0]] = message[0].message;
                hasErrors = true;
              } else {
                setResponseError(
                  'Sorry, we are unable to process your submission at this time. Please try again later.'
                );
                hasErrors = true;
              }

              const event = new CustomEvent('sweepstakesEntryError', {
                detail: { type },
              });
              document.dispatchEvent(event);

              if (isBrowser)
                window.dataLayer?.push({
                  event: 'sweepstakesEntryError',
                  type,
                });
              break;
            }
            case 'over-limit-error':
            case 'system-error': {
              setResponseError(message);
              hasErrors = true;

              const event = new CustomEvent('sweepstakesEntryError', {
                detail: { type },
              });
              document.dispatchEvent(event);

              if (isBrowser)
                window.dataLayer?.push({
                  event: 'sweepstakesEntryError',
                  type,
                });
              break;
            }
          }
        });

        setErrors(errors);

        if (!Object.keys(errors).length && !hasErrors) {
          setFormComplete(true);
        }

        setResponseError((previous) => (previous?.length ? previous : ''));
        setSubmitting(false);
      })
      .catch(() => {
        setResponseError('Error submitting. Please check your connection');
        setSubmitting(false);
      });
  };

  const handleClick = () => {
    setResponseError(null);
  };

  return (
    <div id="form">
      {isFormComplete ? (
        <p className={clsx('form-complete')}>
          Thank you for entering!
          <br />
          Come back to enter the Wonka Sweet-Stakes once a day, every day
          through 4/8/2024, for a chance to win a Wonka Prize Pack.
        </p>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}>
          {({ errors, touched, isSubmitting }) => (
            <FormFormik noValidate>
              {/* <p className="form-heading">ENTER THE WONKA SWEET-STAKES</p> */}
              <FormSemantic.Group widths="equal">
                <Input
                  required
                  errorPrompt
                  id="form-input-control-first-name"
                  name="first_name"
                  label="First Name *"
                  disabled={isSubmitting}
                />
                <Input
                  required
                  errorPrompt
                  id="form-input-control-last-name"
                  name="last_name"
                  label="Last Name *"
                  disabled={isSubmitting}
                />
              </FormSemantic.Group>
              <FormSemantic.Group widths="equal">
                <Input
                  required
                  errorPrompt
                  id="form-input-control-city"
                  name="city"
                  label="City *"
                  disabled={isSubmitting}
                />
                <Select
                  required
                  fluid
                  errorPrompt
                  id="form-input-control-state"
                  name="state"
                  label="State"
                  options={states}
                  disabled={isSubmitting}
                />
              </FormSemantic.Group>
              <FormSemantic.Group widths="equal">
                <Input
                  required
                  errorPrompt
                  id="form-input-control-work-email"
                  name="email"
                  label="Work Email *"
                  type="email"
                  disabled={isSubmitting}
                />
                <Input
                  required
                  errorPrompt
                  id="form-input-control-supervisor-name"
                  name="supervisor"
                  label="Supervisor Name *"
                  disabled={isSubmitting}
                />
              </FormSemantic.Group>
              <Select
                required
                fluid
                errorPrompt
                id="form-input-control-company-name"
                name="company"
                label="Company Name"
                options={companyNames}
                disabled={isSubmitting}
              />
              <Checkbox
                // required
                id="form-input-control-checkbox"
                name="legal"
                disabled={isSubmitting}
              />
              <div
                className={`form-legal${
                  errors.legal && touched.legal ? ' error' : ''
                }`}>
                I agree to the{' '}
                <button
                  className="rules"
                  onClick={(e) => {
                    e.preventDefault();
                    setScrollIntoView(true);
                  }}>
                  Official Rules
                </button>{' '}
                below .*
              </div>
              {!!Object.keys(touched).length && responseError != null && (
                <p className={clsx('error-messages')}>
                  {responseError?.length
                    ? responseError
                    : 'There are items that require your attention'}
                </p>
              )}
              <SubmitButton onClick={() => handleClick()}>ENTER</SubmitButton>
              <p className="text">* Required field</p>
              <br />
              <br />
              <br />
              <Rules {...{ scrollIntoView, setScrollIntoView }} />
            </FormFormik>
          )}
        </Formik>
      )}
    </div>
  );
};

export default Form;
