import clsx from 'clsx';
import * as React from 'react';
import CTAButton from 'src/components/CTAButton/CTAButton';
import { StyledHeading } from 'src/components/ui/styledHeading/styledHeading';
import useView from 'src/hooks/useView';
import * as types from 'src/types';
import { getStrapiImageSource } from 'src/utils/strapiDataHelpers';
import * as scss from './VideoPromos.module.scss';

export type VideoPromoItemType = {
  id: string;
  headline?: types.TextFields;
  subheadline?: types.TextFields;
  previewImage?: types.ImageFields;
  button?: types.ButtonFields;
  footer?: types.TextFields;
};

export type VideoPromosType = {
  id: string;
  videoPromosTitle?: string;
  videoPromosSubtitle?: string;
  videoPromoItems?: VideoPromoItemType[];
};

export const VideoPromos = ({
  id,
  videoPromosTitle,
  videoPromosSubtitle,
  videoPromoItems,
}: VideoPromosType) => {
  const view = useView();

  return (
    <section id="video-promos" className={clsx(scss.section, 'container')}>
      {videoPromosTitle && (
        <StyledHeading>
          <h2>{videoPromosTitle}</h2>
        </StyledHeading>
      )}
      {videoPromosSubtitle && (
        <p className={clsx(scss.subtitle)}>{videoPromosSubtitle}</p>
      )}

      {!!videoPromoItems?.length && (
        <ul className={clsx(scss.container)}>
          {videoPromoItems?.map(
            (item) =>
              item && (
                <li key={`video-promos-${id}-item-${item.id}`}>
                  <article className={clsx(scss.article)}>
                    {item?.previewImage && (
                      <img
                        className={clsx(scss.image)}
                        alt={item.previewImage.alt}
                        src={getStrapiImageSource(
                          item.previewImage.src[view]
                            ? (item.previewImage.src[view] as types.ImageSource)
                            : item.previewImage.src.lg
                        )}
                      />
                    )}
                    <div className={clsx(scss.content)}>
                      <div>
                        {item?.headline?.text && (
                          <h3
                            className={clsx(scss.headline)}
                            dangerouslySetInnerHTML={{
                              __html: item.headline.text,
                            }}
                          />
                        )}
                        {item?.subheadline?.text && (
                          <p
                            className={clsx(scss.subheadline)}
                            dangerouslySetInnerHTML={{
                              __html: item.subheadline.text,
                            }}
                          />
                        )}
                      </div>

                      {item.button && (
                        <div className={clsx(scss.button)}>
                          <CTAButton
                            data={{
                              button: {
                                ...item.button,
                                icon:
                                  item.button.type === types.ButtonTypes.overlay
                                    ? ['far', 'play-circle']
                                    : undefined,
                              },
                            }}
                          />
                        </div>
                      )}

                      {item.footer && (
                        <div
                          className={clsx(scss.footer, 'container')}
                          dangerouslySetInnerHTML={{
                            __html: item.footer?.text ?? '',
                          }}></div>
                      )}
                    </div>
                  </article>
                </li>
              )
          )}
        </ul>
      )}
    </section>
  );
};
