import * as React from 'react';
import clsx from 'clsx';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as types from 'src/types';
import useView from 'src/hooks/useView';
import { Control } from '../../ui/carousel';
import { Title, TitlesType } from '../../Title/Title';
import { CategoryFilterItemType } from './CategoryFilter';
import * as scss from './Categories.module.scss';
import { getStrapiImageSource } from '../../../utils/strapiDataHelpers';

const staggerDelay = 100;

export type Props = {
  activeItemOverlay: number;
  categoryShowcase: React.ReactNode;
  currentFilter?: CategoryFilterItemType;
  isOverflowing: boolean;
  items: TitlesType[];
  position: types.SlidePosition;
  handleControl: (e: React.MouseEvent<HTMLButtonElement>) => boolean;
  onOverlayToggle: (index: number) => (ref: HTMLDivElement | null) => void;
  onResize: (swiper: SwiperCore) => void;
  onSlideChange: (swiper: SwiperCore) => void;
  onSwiper: (swiper: SwiperCore) => void;
};

export const FilteredItems = ({
  activeItemOverlay,
  categoryShowcase,
  currentFilter,
  isOverflowing,
  items = [],
  position,
  handleControl,
  onOverlayToggle,
  onResize,
  onSlideChange,
  onSwiper,
}: Props) => {
  const view = useView();

  if (!items.length) return null;

  const renderItems = items.map((item, index) => {
    return (
      <SwiperSlide
        key={item.id}
        tag="li"
        className={clsx(
          scss.item,
          index === items.length - 1 && scss.itemLast
        )}>
        <CSSTransition
          key={`anim-${item.id}`}
          {...{ in: true }}
          classNames="anim"
          timeout={1000}>
          <div
            className={scss.itemInner}
            style={{ transitionDelay: `${staggerDelay * index}ms` }}>
            <Title
              headerClassName={clsx(scss.itemHeader)}
              data={item}
              toggle={
                activeItemOverlay === index
                  ? types.ToggleStates.open
                  : types.ToggleStates.close
              }
              onToggle={onOverlayToggle(index)}
            />
          </div>
        </CSSTransition>
      </SwiperSlide>
    );
  });

  return (
    <div
      id={`cat-${currentFilter?.idCategoryFilterItem}`}
      className={clsx(scss.results, 'container container--fhd')}>
      {types.Sizes.lg === view && (
        <TransitionGroup>
          <CSSTransition
            key={currentFilter?.id}
            classNames="anim"
            timeout={1000}>
            <div
              className={clsx(scss.resultsBg)}
              style={{
                backgroundImage: `url(${getStrapiImageSource(
                  currentFilter?.backgroundImage?.src?.lg as types.ImageSource
                )})`,
              }}></div>
          </CSSTransition>
        </TransitionGroup>
      )}

      <TransitionGroup>
        <Swiper
          watchSlidesVisibility
          allowTouchMove={true}
          className={clsx(scss.list)}
          slidesPerView="auto"
          spaceBetween={12}
          speed={500}
          wrapperTag="ul"
          preventClicks={false}
          onInit={onSwiper}
          onResize={onResize}
          onSlideChange={onSlideChange}>
          {types.Sizes.lg === view && (
            <SwiperSlide tag="li" className={clsx(scss.showcase)}>
              {categoryShowcase}
            </SwiperSlide>
          )}

          {renderItems}
        </Swiper>
      </TransitionGroup>

      {isOverflowing && (
        <Control.Control>
          <Control.Prev
            className={clsx({
              'is-disabled': 'start' === position,
            })}
            disabled={'start' === position}
            onClick={handleControl}
          />
          <Control.Next
            className={clsx({
              'is-disabled': 'end' === position,
            })}
            disabled={'end' === position}
            onClick={handleControl}
          />
        </Control.Control>
      )}
    </div>
  );
};
