// extracted by mini-css-extract-plugin
export var game = "Game-module--game--2egXm";
export var gameChoices = "Game-module--game-choices--ZcIL+";
export var gameClue = "Game-module--game-clue--kD3ti";
export var gameClueCta = "Game-module--game-clue-cta--y0zU+";
export var gameClueDescription = "Game-module--game-clue-description--SGvo-";
export var gameClueProgress = "Game-module--game-clue-progress--mZDos";
export var gameContainer = "Game-module--game-container--fcnik";
export var gameContinue = "Game-module--game-continue--46C30";
export var gameFeaturedImage = "Game-module--game-featured-image--Xuu3S";
export var gameFeaturedImageBg = "Game-module--game-featured-image-bg--m8w5N";
export var gameFeaturedImageInner = "Game-module--game-featured-image-inner--jcdkK";
export var gameFeaturedImageWrapper = "Game-module--game-featured-image-wrapper--unXwY";
export var gameGameOver = "Game-module--game-game-over--0aGUq";
export var gameGameOverMessage = "Game-module--game-game-over-message--UEqBW";
export var gameGrid = "Game-module--game-grid--FSgFu";
export var gameGridItem = "Game-module--game-grid-item--cHWxG";
export var gameHeader = "Game-module--game-header--moR1H";
export var gameProgress = "Game-module--game-progress--LI0xi";
export var gameQuestion = "Game-module--game-question--rvt4C";
export var wrapper = "Game-module--wrapper--gka+a";