import * as React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as types from 'src/types';
import { Control, Nav } from '../ui/carousel';
import { CarouselItem, CarouselItemType } from './CarouselItem';
import * as scss from './Carousel.module.scss';

export type Props = {
  id?: string;
  activeSlide?: number;
  autoplay?: boolean;
  duration?: number;
  items?: CarouselItemType[];
  videoStatus?: types.MediaState;
};

export const Carousel: React.FC<Props> = ({
  id,
  activeSlide: activeSlideProps = 0,
  autoplay: autoplayProps = true,
  duration = 10000,
  items = [],
  videoStatus: videoStatusProps = types.MediaStates.play,
}) => {
  /**
   * Item index that determines which slide should be shown.
   */
  const [activeSlide, setActiveSlide] = React.useState(activeSlideProps);

  /**
   * Video playing or stopped status.
   */
  const [videoStatus, setVideoStatus] = React.useState<types.MediaState>(
    videoStatusProps
  );

  const [pausedByButton, setPausedByButton] = React.useState(false);

  /**
   * Carousel autoplaying cycle on or off.
   */
  const [autoplay, setAutoplay] = React.useState(autoplayProps);

  // refs
  const timerId = React.useRef<number>();
  const touchStart = React.useRef(0);
  const touchEnd = React.useRef(0);

  // hooks
  const isPlaying = React.useMemo(
    () => types.MediaStates.play === videoStatus,
    [videoStatus]
  );

  /**
   * Set selected item state.
   *
   * @param e - Mouse event object
   * @returns {void}
   */
  const handleSetActive = (e: React.MouseEvent<HTMLButtonElement>) => {
    const index = e.currentTarget.dataset.slide || 0;
    if (!index) return;
    setActiveSlide(Number(index));
  };

  /**
   * Handle action for clicking previous or next arrow navigation.
   *
   * @param e - Mouse event object
   * @returns {void}
   */
  const handleControls = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget;

    if (!target) return;

    setActiveSlide((prevState) => {
      if ('prev' === target.dataset.action) {
        return prevState === 0 ? items.length - 1 : prevState - 1;
      }

      if ('next' === target.dataset.action) {
        return prevState === items.length - 1 ? 0 : prevState + 1;
      }

      return prevState;
    });

    e.preventDefault();
  };

  /**
   * Sets video playing status when clicking the media controls or
   * toggling video overlay
   *
   * @param e - Mouse event object
   * @returns {void}
   */
  const handlePlayStatus = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;

    if (!target) return;

    setVideoStatus((prevStatus) => {
      return types.MediaStates.play === prevStatus
        ? types.MediaStates.stop
        : types.MediaStates.play;
    });

    setPausedByButton(() => {
      return types.MediaStates.play === videoStatus ? true : false;
    });

    e.preventDefault();
  };

  /**
   * Update carousel autoplay cycle when popup overlay is shown/hidden.
   */
  React.useEffect(() => {
    if (videoStatus === types.MediaStates.play) {
      setAutoplay(true);
    }

    if (videoStatus === types.MediaStates.stop) {
      setAutoplay(false);
    }
  }, [videoStatus]);

  /**
   * Enable cycling through items.
   */
  const handlePlayNext = React.useCallback(() => {
    setActiveSlide((prevState) => {
      return prevState === items.length - 1 ? 0 : prevState + 1;
    });
  }, [items.length]);

  /**
   * Control video from playing or stop state.
   *
   * @param index - Item index
   * @returns {boolean}
   */
  const handlePlaying = (index: number) => {
    if (types.MediaStates.stop === videoStatus) return false;
    if (activeSlide !== index) return false;
    return true;
  };

  /**
   * Capture user touch start position.
   *
   * @param e - Touch event object
   * @returns {void}
   */
  const handleTouchStart = (e: React.TouchEvent<HTMLUListElement>) => {
    touchStart.current = e.targetTouches[0].clientX;
    touchEnd.current = e.targetTouches[0].clientX;
  };

  /**
   * Capture user touch move pointer position.
   *
   * @param e - Touch event object
   * @returns {void}
   */
  const handleTouchMove = (e: React.TouchEvent<HTMLUListElement>) => {
    touchEnd.current = e.targetTouches[0].clientX;
  };

  /**
   * Compute and determine whether user touch swipe movement if left or right
   * base on `swipeDistance` should trigger next item or go to previous item.
   */
  const handleTouchEnd = () => {
    const swipeDistance = 150;
    setActiveSlide((prevState) => {
      // go next
      if (touchStart.current - touchEnd.current > swipeDistance) {
        return prevState === items.length - 1 ? 0 : prevState + 1;
      }

      // go back
      if (touchStart.current - touchEnd.current < -swipeDistance) {
        return prevState === 0 ? items.length - 1 : prevState - 1;
      }

      return prevState;
    });
  };

  React.useEffect(() => {
    setActiveSlide(activeSlideProps);
  }, [activeSlideProps]);

  React.useEffect(() => {
    setVideoStatus(videoStatusProps);
  }, [videoStatusProps]);

  React.useEffect(() => {
    setAutoplay(autoplayProps);
  }, [autoplayProps]);

  /**
   * Handle carousel cycling through items base on certain duration.
   * Clears timeout when user interacts with the navigation and set new
   * timeout instance each click and clears old timeout instance to avoid
   * events from stacking.
   */
  React.useEffect(() => {
    if (timerId.current) {
      window.clearTimeout(timerId.current);
    }

    if (!autoplay) return;

    timerId.current = window.setTimeout(handlePlayNext, duration);

    return () => window.clearTimeout(timerId.current);
  }, [handlePlayNext, activeSlide, autoplay, duration]);

  const renderItems = items.map((item, index) => (
    <CarouselItem
      key={`jumbotron-item-${item.id}`}
      index={index}
      data={item}
      activeSlide={activeSlide}
      pausedByButton={pausedByButton}
      handlePlayNext={handlePlayNext}
      handlePlaying={handlePlaying}
      setAutoplay={setAutoplay}
      setVideoStatus={setVideoStatus}
    />
  ));

  return (
    <section id={id} className={clsx(scss.wrapper)}>
      <div className={clsx(scss.container, 'container container--fhd')}>
        <ul
          className={clsx(scss.items)}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}>
          {renderItems}
        </ul>

        {items.length > 1 && (
          <>
            <Control.Control className={clsx(scss.controls)}>
              <Control.Prev
                className={clsx(scss.control, scss.controlPrev, 'ring')}
                onClick={handleControls}
              />
              <Control.Next
                className={clsx(scss.control, scss.controlNext, 'ring')}
                onClick={handleControls}
              />
            </Control.Control>

            <Nav.Nav className={clsx(scss.navWrapper)}>
              <li className={clsx(scss.navItem)}>
                <button
                  className={clsx(scss.navButton, scss.navControl, 'ring')}
                  data-action={
                    isPlaying ? types.MediaStates.stop : types.MediaStates.play
                  }
                  aria-label="Enable or disable automatic carousel cycle"
                  onClick={handlePlayStatus}>
                  <span className="visually-hidden">
                    {isPlaying ? 'Play' : 'Stop'}
                  </span>
                  <span className={clsx(scss.navIcon)}>
                    {isPlaying ? (
                      <FontAwesomeIcon
                        className="i"
                        icon={['far', 'stop-circle']}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="i"
                        icon={['far', 'play-circle']}
                      />
                    )}
                  </span>
                </button>
              </li>

              {items.map((item, index) => (
                <Nav.NavItem
                  key={`jumbotron-nav-${item.id}`}
                  isActive={activeSlide === index}
                  itemIndex={index}
                  label={index + 1}
                  title={item.headline.text}
                  onClick={handleSetActive}
                />
              ))}
            </Nav.Nav>
          </>
        )}
      </div>
    </section>
  );
};
