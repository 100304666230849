import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type HeaderProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
};

export const Header = ({ children, className, style }: HeaderProps) => {
  return (
    <header className={clsx(scss.itemHeader, className)} style={style}>
      {children}
    </header>
  );
};
