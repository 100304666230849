import * as React from 'react';
import clsx from 'clsx';
import * as scss from './drawer.module.scss';

export type DrawerWrapperProps = {
  isActive?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const DrawerWrapper = ({
  className,
  children,
  isActive = false,
}: DrawerWrapperProps) => {
  return (
    <div className={clsx(scss.wrapper, className, { 'is-active': isActive })}>
      {children}
    </div>
  );
};
