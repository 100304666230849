// extracted by mini-css-extract-plugin
export var controlList = "Game-module--control-list--JdOmI";
export var dragging = "Game-module--dragging--RIEqe";
export var game = "Game-module--game--4-5jz";
export var gameContainer = "Game-module--game-container--Y-qCT";
export var gameGameOver = "Game-module--game-game-over--3OcNE";
export var gameGameOverMessage = "Game-module--game-game-over-message--xSMp7";
export var gameHeader = "Game-module--game-header--j+41Z";
export var gameOptionContainer = "Game-module--game-option-container--dJbUd";
export var gameOptionDirections = "Game-module--game-option-directions--kV7Y9";
export var gameOptionDragIcon = "Game-module--game-option-drag-icon--jUDZK";
export var gameOptionDraggable = "Game-module--game-option-draggable--0gEDU";
export var gameOptionItem = "Game-module--game-option-item--9E24Q";
export var gameOptionMatchee = "Game-module--game-option-matchee--4+NlT";
export var gameOptions = "Game-module--game-options--tFz2v";
export var gameProgress = "Game-module--game-progress--8nMdm";
export var gameQuestion = "Game-module--game-question--sfl2S";
export var gameSubheader = "Game-module--game-subheader--1-SCY";
export var wrapper = "Game-module--wrapper--+Dfma";