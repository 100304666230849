import * as React from 'react';
import clsx from 'clsx';
import * as types from 'src/types';
import { GradientLinesContainer } from '../../ui/styledTextBox/styledTextBox';
import { Button } from 'src/components/ui/button/button';
import * as scss from './WordScrambleFinal.module.scss';

export type Props = {
  headline: types.TextFields;
  subheadline: types.TextFields;
  onReset: () => void;
};

export const WordScrambleFinal = ({
  headline,
  subheadline,
  onReset,
}: Props) => {
  return (
    <article className={clsx(scss.wrapper)}>
      <GradientLinesContainer>
        <h3 className={clsx(scss.title)}>{headline.text}</h3>
      </GradientLinesContainer>

      <section className={clsx(scss.promo)}>
        <h4 className={clsx(scss.promoTitle)}>{subheadline.text}</h4>

        <Button
          outline
          className={clsx(scss.btnPlay, 'ring')}
          tag="button"
          inputType="submit"
          mode="light"
          variant="primary"
          label="Play Again"
          title="Word Scramble - Play Again"
          onClick={onReset}
        />
      </section>
    </article>
  );
};
