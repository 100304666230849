import * as React from 'react';
import clsx from 'clsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as types from 'src/types';
import { Button } from '../../ui/button/button';
import * as scss from './WordScrambleItem.module.scss';

export const Statuses = {
  ask: 'ask',
  correct: 'correct',
  incorrect: 'incorrect',
  skip: 'skip',
  final: 'final',
} as const;

export type Status = typeof Statuses[keyof typeof Statuses];

export type Where = 'next' | 'prev' | 'skip';

export type WordScrambleItemType = {
  id?: string;
  idWordScrambleItem: string;
  keyword: types.TextFields;
  jumbledKeyword: string;
  question: types.TextFields;
  featuredImg: types.ImageFields;
};

export type FormData = {
  answer: string;
};

const formSchema = {
  answer: (keyword: string) => ({
    validate: (value: string) =>
      value.replace(/\s/g, '').toUpperCase() ===
        keyword.replace(/\s/g, '').toUpperCase() || 'Incorrect answer',
  }),
};

export type Props = {
  className?: string;
  status?: Status;
  title?: string;
  setAriaLive: React.Dispatch<React.SetStateAction<string>>;
  onNavigate: React.Dispatch<Where>;
  onSubmit: SubmitHandler<FormData>;
} & WordScrambleItemType;

export const WordScrambleItem = ({
  className,
  keyword,
  jumbledKeyword,
  question,
  status,
  title,
  setAriaLive,
  onNavigate,
  onSubmit,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormData>({
    mode: 'onSubmit',
  });

  const formRef = React.useRef<HTMLFormElement | null>(null);

  const handleClickNav = React.useCallback(
    (where: Where) => () => {
      onNavigate(where);
    },
    [onNavigate]
  );

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.reset();
    }

    if ('incorrect' === status) {
      setError('answer', { type: 'validate', message: 'Incorrect answer' });
    }
  }, [errors, setError, status]);

  React.useEffect(() => {
    if (!!errors.answer) {
      setAriaLive('Incorrect! Please try again, or click Skip');
    }
  }, [errors.answer, setAriaLive]);

  // const Form = () => {
  //   if (Statuses.skip === status) return null;
  //   if (Statuses.correct === status) return null;
  //   if (Statuses.final === status) return null;

  //   return (
  //     <form
  //       className={clsx(scss.form)}
  //       autoComplete="off"
  //       onSubmit={handleSubmit(onSubmit)}>
  //       <input hidden type="hidden" name="hidden" autoComplete="false" />

  //       <div
  //         className={clsx(scss.formGroup, {
  //           'is-invalid': errors.answer?.type === 'validate',
  //         })}>
  //         <label htmlFor="answer" className={clsx(scss.formLabel)}>
  //           {errors.answer?.message ?? 'Answer'}
  //         </label>

  //         <input
  //           id="answer"
  //           type="text"
  //           className={clsx(scss.formInput)}
  //           placeholder=""
  //           aria-invalid={!!errors.answer ? 'true' : 'false'}
  //           {...register('answer', formSchema.answer(keyword.text))}
  //         />
  //       </div>

  //       <Button
  //         outline
  //         className={clsx(scss.btnSubmit)}
  //         tag="button"
  //         inputType="submit"
  //         mode="light"
  //         variant="primary"
  //         label="Submit"
  //       />
  //     </form>
  //   );
  // };

  const renderKeyword = () => {
    if (Statuses.correct === status || Statuses.skip === status)
      return keyword.text;

    return jumbledKeyword;
  };

  const renderKeywordLabel = () => {
    if (Statuses.correct === status) return 'CORRECT!';
    if (Statuses.skip === status) return 'THE CORRECT ANSWER IS';
    return 'HINT:';
  };

  const SkipButton = () => {
    let label = 'Skip';
    let where: Where = 'skip';
    let title = 'Word Scramble - Skip Question';

    if (Statuses.correct === status || Statuses.skip === status) {
      label = 'Next';
      where = 'next';
      title = 'Word Scramble - Next Question';
    }

    return (
      <Button
        outline
        className={clsx(scss.btnLvlNav)}
        iconClass={clsx(scss.icon)}
        tag="button"
        inputType="submit"
        mode="light"
        variant="primary"
        label={label}
        icon="chevron-right"
        title={title}
        onClick={handleClickNav(where)}
      />
    );
  };

  return (
    <article
      className={clsx(scss.item, { [`is-${status}`]: !!status }, className)}>
      <header className={clsx(scss.header)}>
        <h3 className={clsx(scss.heading)}>{title}</h3>
        <h4 className={clsx(scss.question)} style={question.style}>
          {question.text}
        </h4>
      </header>

      <section className={clsx(scss.theKeyword)}>
        <div className={clsx(scss.keywordLabel)}>{renderKeywordLabel()}</div>
        <div className={clsx(scss.keyword)}>
          <span>{renderKeyword()}</span>
        </div>
      </section>

      <section className={clsx(scss.content)}>
        {Statuses.skip === status ||
        Statuses.correct === status ||
        Statuses.final === status ? null : (
          <form
            ref={(el) => (formRef.current = el)}
            className={clsx(scss.form)}
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}>
            <input hidden type="hidden" name="hidden" autoComplete="false" />

            <div
              className={clsx(scss.formGroup, {
                'is-invalid': errors.answer?.type === 'validate',
              })}>
              <label htmlFor="answer" className={clsx(scss.formLabel)}>
                {errors.answer?.message ?? 'Answer'}
              </label>

              <input
                id="answer"
                type="text"
                className={clsx(scss.formInput)}
                placeholder=""
                aria-label="Enter question answer here using the letters from the hint"
                aria-invalid={!!errors.answer ? 'true' : 'false'}
                {...register('answer', formSchema.answer(keyword.text))}
              />
            </div>

            <Button
              outline
              className={clsx(scss.btnSubmit)}
              tag="button"
              inputType="submit"
              mode="light"
              variant="primary"
              label="Submit"
              title="Word Scramble - Submit Answer"
            />
          </form>
        )}

        <div className={clsx(scss.skip)}>
          <SkipButton />
        </div>
      </section>
    </article>
  );
};
