import * as React from 'react';
import clsx from 'clsx';
import * as scss from './carousel.module.scss';

export type NavProps = React.HTMLAttributes<HTMLElement>;

export const Nav = ({ children, className, style }: NavProps) => {
  return (
    <ul className={clsx(scss.nav, className)} style={style}>
      {children}
    </ul>
  );
};
